import React,{useState,useEffect}  from 'react';
import SideBar from '../Components/SideBar';
import api from '../api';
import { Button, Form, Row, Col,FloatingLabel } from 'react-bootstrap';
import {confirmAlert} from 'react-confirm-alert'
import useAuth from '../Context/hooks/UseAuth';


export default function TextoPedido(){

    const [textos, setTextos] = useState({});

    const { online, sincronizando } = useAuth();

    const updateTextos = async (e) => {
        e.preventDefault();
        try{
            await api.patch("/pedido/textospedido",
              JSON.stringify(textos),
              {
                  headers: { 'Content-Type': 'application/json' }
              }
            );
        }catch(err){
            confirmAlert({
                title: "Erro:",
                message: err.response.data ?err.response.data.error :err.message,
                buttons: [
                    {
                      label: 'Ok',
                    }
                  ]
            })
        }
    }

    function onChanged(e){
        const {value,name} = e.target;
        setTextos({
            ...textos,
            [name]: value
            })
    }

    const loadData = async () =>{
        const response = await api.get("/pedido/textospedido",
        {
            headers: { 'Content-Type': 'application/json' }
        }
        );
        setTextos(response.data);
    }

    useEffect(()=>{
        loadData();
    },[]);

    return (
        <>
        <SideBar/>
        <main className="content">
            {
                online ?null :<div className="alert alert-warning" role="alert">Você está offline</div>
            }
            {
                sincronizando ?<div className="alert alert-warning" role="alert">Sincronizando</div> :null
            }
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block">
                    <h4>Textos Pedido</h4>
                </div>
            </div>
            <Form onSubmit={updateTextos}>
                <Row>
                    <Col className="mb-3">
                        <FloatingLabel label="Título">
                            <Form.Control type="text" autoComplete="off" placeholder="" name="titulo" onChange={onChanged} value={textos.titulo}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <FloatingLabel label="Complemento Título">
                            <Form.Control type="text" autoComplete="off" placeholder="" name="comp_titulo" onChange={onChanged} value={textos.comp_titulo}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <FloatingLabel label="Complemento Texto">
                            <Form.Control style={{ height: '100px' }} as="textarea" autoComplete="off" placeholder="" name="comp_texto" onChange={onChanged} value={textos.comp_texto}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <FloatingLabel label="Prazo de entrega">
                            <Form.Control type="text" autoComplete="off" placeholder="" name="infoads_prazoentrega" onChange={onChanged} value={textos.infoads_prazoentrega}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <FloatingLabel label="Impostos">
                            <Form.Control type="text" autoComplete="off" placeholder="" name="infoads_impostos" onChange={onChanged} value={textos.infoads_impostos}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <FloatingLabel label="Frete">
                            <Form.Control type="text" autoComplete="off" placeholder="" name="infoads_frete" onChange={onChanged} value={textos.infoads_frete}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <FloatingLabel label="Pagamento">
                            <Form.Control type="text" autoComplete="off" placeholder="" name="infoads_pagamento" onChange={onChanged} value={textos.infoads_pagamento}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <FloatingLabel label="Industrialização">
                            <Form.Control type="text" autoComplete="off" placeholder="" name="infoads_industrializacao" onChange={onChanged} value={textos.infoads_industrializacao}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <FloatingLabel label="Validade">
                            <Form.Control type="text" autoComplete="off" placeholder="" name="infoads_validade" onChange={onChanged} value={textos.infoads_validade}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <FloatingLabel label="Lavagem">
                            <Form.Control style={{ height: '200px' }} as="textarea" autoComplete="off" placeholder="" name="infoads_lavagem" onChange={onChanged} value={textos.infoads_lavagem}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <FloatingLabel label="Agradecimento">
                            <Form.Control style={{ height: '200px' }} as="textarea" autoComplete="off" placeholder="" name="infoads_agradecimento" onChange={onChanged} value={textos.infoads_agradecimento}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <FloatingLabel label="Telefone">
                            <Form.Control type="text" autoComplete="off" placeholder="" name="infoads_telefone" onChange={onChanged} value={textos.infoads_telefone}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <FloatingLabel label="Email">
                            <Form.Control type="text" autoComplete="off" placeholder="" name="infoads_email" onChange={onChanged} value={textos.infoads_email}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <FloatingLabel label="Razão Social">
                            <Form.Control type="text" autoComplete="off" placeholder="" name="rodape_razaosocial" onChange={onChanged} value={textos.rodape_razaosocial}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <FloatingLabel label="Endereço">
                            <Form.Control type="text" autoComplete="off" placeholder="" name="rodape_endereco" onChange={onChanged} value={textos.rodape_endereco}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <FloatingLabel label="Contato">
                            <Form.Control style={{ height: '200px' }} as="textarea" type="text" autoComplete="off" placeholder="" name="rodape_contato" onChange={onChanged} value={textos.rodape_contato}/>
                        </FloatingLabel>
                    </Col>
                </Row>


                <Row>
                    <Col md={12} className="mb-3 d-flex justify-content-center">
                        <Button type="submit" className="col-md-3 col-12" >Salvar</Button>
                    </Col>
                </Row>
            </Form>
        </main>
        </>
    )
}
