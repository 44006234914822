import React,{useState,useEffect,useRef,forwardRef, useImperativeHandle} from 'react';
import { Button, Card,  Modal, Form, Row, Col,FloatingLabel, ToggleButton } from 'react-bootstrap';

var decimals = 2;

const SelecaoValoresFormacaoPreco = forwardRef((props, ref) => {
    let callback = props.callback;
    let precos = props.precos;

    const [showModal,setShowModal] = useState(false);
    const refShowModal = useRef(showModal);

    useImperativeHandle(ref, () => {
        return {
            open() {
                openModal();
            }
        };
    }, []);

    function openModal(){
        setShowModal(true);
    }

    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.key === 'Escape') {
              event.preventDefault();
              if(refShowModal.current===true){
                handleCloseModal();
              }
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
          document.removeEventListener('keydown', keyDownHandler);
        };
    },[]);

    useEffect(() => {
        refShowModal.current = showModal;
      }, [showModal]);

    const handleCloseModal = () => {
        setShowModal(false);
    }

    function valorSelecionado(e){
        setShowModal(false);
        callback({value:e.target.value,text:e.target.getAttribute('text')});
    }

    return (
        showModal
        ?
        <React.Fragment>
        <Modal as={Modal.Dialog} show scrollable={true}>
            <Modal.Header>
            <Modal.Title className="h6">Seleção de ICMS</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    precos.map(p =>{
                        return <Button className="w-100 mb-2" value={p.id} text={p.descricao} onClick={valorSelecionado}>{p.descricao}</Button>
                    })
                }
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={handleCloseModal}>Sair</Button>
            </Modal.Footer>
        </Modal>
        </React.Fragment>
        :""
    )
});

export default SelecaoValoresFormacaoPreco;
