import React, { useEffect, useState } from "react";
import { faUser, faSignOutAlt, faTimes, faUpload, faCalculator, faAlignJustify, faHandshake, faDollyBox,faFileInvoiceDollar,faMagnifyingGlassDollar, faVial } from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Button, Navbar } from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';
import LogoPalagi from "../assets/img/brand/palagi_logo192.png";
import LogoPrismma from "../assets/img/brand/prismma_logo192.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAuth from '../Context/hooks/UseAuth';
import { setupInterceptors } from "../api";

const { REACT_APP_APP_ENV } = process.env;

export default function SideBar(){

    const location = useLocation();
    const { pathname } = location;
    const [show, setShow] = useState(false);
    const { auth,logout } = useAuth();

    const showClass = show ? "show" : "";
  
    const onCollapse = () => setShow(!show);

    /*const logout = () => {
      setAuth({});
      localStorage.removeItem('token');
      //<Navigate to="/" state={{ from: location }} replace />
    }*/

      useEffect(() => {
        // Configura o interceptor ao montar o componente
        setupInterceptors(logout);
      }, [logout]);
   
    const NavItem = (props) => {
      const { title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary" } = props;
      const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
      const navItemClassName = link === pathname ? "active" : "";
      const linkProps = external ? { href: link } : { as: Link, to: link };
  
      return (
        <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
          <Nav.Link {...linkProps} target={target} className={classNames}>
            <span>
              {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
              {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}
  
              <span className="sidebar-text">{title}</span>
            </span>
            {badgeText ? (
              <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
            ) : null}
          </Nav.Link>
        </Nav.Item>
      );
    };

    return(
        <>
        <Navbar expand={false} collapseOnSelect variant="dark" className="px-4 d-md-none">
          <Navbar.Brand className="me-lg-5" as={Link} to="/">
            <Image src={REACT_APP_APP_ENV==="palagi"?LogoPalagi:LogoPrismma} className="navbar-brand-light" />
          </Navbar.Brand>
          <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
            <span className="navbar-toggler-icon" />
          </Navbar.Toggle>
        </Navbar>
        <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
          <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
            <div className="sidebar-inner px-4 pt-3">
                <div className={"d-flex "+(show?"justify-content-between":"justify-content-center pb-4")+" align-items-center"}>
                    <Button /*as={Link}*/ variant="secondary" size="xs" /*to="/logout"*/ onClick={logout} className="text-dark">
                        <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Sair
                    </Button>
                    <Nav.Link onClick={onCollapse} className="collapse-close d-md-none">
                        <FontAwesomeIcon icon={faTimes} />
                    </Nav.Link>
                </div>
                <Nav className="flex-column pt-3 pt-md-0">
                  <NavItem title="Consulta Preço" link="/" icon={faMagnifyingGlassDollar} />
                  <NavItem title="Formação Preço" link="/formacaopreco" icon={faCalculator} />
                  <NavItem title="Consulta Vendas" link="/consultavendas" icon={faHandshake} />
                  <NavItem title="Pedido" link="/cadastropedido" icon={faFileInvoiceDollar} />
                  <NavItem title="PDP" link="/cadastroamostra" icon={faVial} />
                  <NavItem title="Consulta Benef." link="/consultabeneficiamentos" icon={faDollyBox} />
                  {auth.nivel
                  ?<>
                    <NavItem title="Usuários" link="/user" icon={faUser} />
                    <NavItem title="Upload" link="/upload" icon={faUpload} />
                    <NavItem title="Textos Proposta" link="/textoproposta" icon={faAlignJustify} />
                    <NavItem title="Textos Pedido" link="/textopedido" icon={faAlignJustify} />
                  </>
                  :<></>}
                </Nav>
            </div>
          </SimpleBar>
        </CSSTransition>
      </>
    )
    /*return (
        <div>
            <Navbar bg="primary" variant="dark">
            <Container>
                <Navbar.Brand href="/">Navbar</Navbar.Brand>
                <Nav className="me-auto">
                <Nav.Link href="/">Home</Nav.Link>
                </Nav>
            </Container>
            </Navbar>
            <h1>Home Component</h1>
        </div>
    )*/
}
