import React,{useState,useEffect,useRef} from 'react';
import SideBar from '../Components/SideBar';
import FiltroCliente from '../Components/FiltroCliente'; 
import { Button, Modal, Form, Row, Col,FloatingLabel, ToggleButton, ButtonGroup, Card } from 'react-bootstrap';
import api from '../api';
import useAuth from '../Context/hooks/UseAuth';
import {confirmAlert} from 'react-confirm-alert'
import Enumerable from 'linq'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
import { orderBy } from 'lodash';



const optFaturado = [
    { name: 'Todos', value: '1' },
    { name: 'Faturados', value: '2' },
    { name: 'Não faturados', value: '3' },
];

export default function ConsultaVendas(){
    const [filtroFaturado, setFiltroFaturado] = useState('1');
    const { online,setOnline, auth, loadOfflineData, sincronizando, ceilValue,decimals } = useAuth();
    const [vendas, setVendas] = useState([]);
    const [itemSelected, setItemSelected] = useState(0);
    const [dadoFilterData,setDadoFilterData] = useState('');
    const [zoomFilter,setZoomFilter] = useState({});
    const [itemZoom, setItemZoom] = useState(0);

    const refVendas = useRef(vendas);
    const refItemZoom = useRef(itemZoom);
    const refZoomFilter = useRef(zoomFilter);
    const refFilterData = useRef();




    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.key === 'Escape') {
              event.preventDefault();
              if(refVendas.current.length!==0){
                voltar();
              }
            }
          };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
          document.removeEventListener('keydown', keyDownHandler);
        };
    },[]);

    useEffect(()=>{
        if(refFilterData.current)
            refFilterData.current.value=dadoFilterData;
    },[dadoFilterData])

    useEffect(() => {
        refVendas.current = vendas;
        refItemZoom.current = itemZoom;
        refZoomFilter.current = zoomFilter;
      }, [vendas,itemZoom,zoomFilter]);


    async function clienteSelected(cliente){
        let data;
        try{
            const response = await api.post("/consultavendas/vendas",
            JSON.stringify({cod_cliente: cliente.codigo,faturado: filtroFaturado, usuario:{codigo: auth.codigo,nivel: auth.nivel, cod_vendedor: auth.cod_vendedor}}),
            {
                headers: { 'Content-Type': 'application/json', "Cache-Control": "no-cache" },
            }
            );
            data=response.data.vendas;
            setOnline(true);
        }catch(err){
            if(!err.response || err.response.status===0){
                setOnline(false);
            }else{
                confirmAlert({
                    title: "Erro:",
                    message: err.response.data ?err.response.data.error :err.message,
                    buttons: [
                        {
                            label: 'Ok',
                        }
                        ]
                })
            }
        }
        if(data===undefined){
            let cacheInfos=await loadOfflineData();
            if(cacheInfos!==false){
                let query=Enumerable.from(cacheInfos.vendas);
                query=query.where(i=> i.cod_cliente==cliente.codigo);
                if(filtroFaturado==="2"){
                    query=query.where(i=> i.notafiscal>0);
                }else if(filtroFaturado==="3"){
                    query=query.where(i=> i.notafiscal===0);
                }

                data=query.toArray();
            }
        }
        if(data.length>0){
            const vendasOrdenadas = orderBy(data, ['faturamento_item'], ['asc']); // Ordem ascendente
            setVendas(vendasOrdenadas);
        }else{
            confirmAlert({
                title: "Erro:",
                message: "Nada encontrado.",
                buttons: [
                    {
                        label: 'Ok',
                    }
                ]
            })
        }
    }

    const CabecalhoCliente = () => {
        let cliente=vendas[0];
        return (
            <div style={{position: "-webkit-sticky" /* Safari & IE */,position: "sticky",top:"0",zIndex: "1"}}>
                <Card className="mb-1" >
                    <Card.Body>
                        <Row>
                            <Col>
                                <Form.Label style={{paddingRight: "5px"}} >{cliente.cod_cliente+" - "+cliente.razao_social}</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label >{cliente.cidade+" - "+cliente.uf}</Form.Label>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className="mb-1">
                    <Card.Body>
                        <Row>
                            <Col /*className="col-md-6 col-12"*/>
                                <FloatingLabel className="mb-2" label="Filtro">
                                    <Form.Control ref={refFilterData} type="text" autoComplete="off" placeholder="" name="texto" onKeyUp={(e)=>{if(e.key==="Enter") setDadoFilterData(refFilterData.current.value)}}/>
                                </FloatingLabel>
                            </Col>
                            <Button className="mb-2" onClick={()=>{setDadoFilterData(refFilterData.current.value);}} style={{width:"10px", heigth:"100%", marginRight: "11px", justifyContent: "center", display: "flex", alignItems: "center"}}><FontAwesomeIcon icon={faMagnifyingGlass} /></Button>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        )
    }

    function dateToString(date){
        let d=new Date(date);
        return d.getDate()+"/"+(d.getMonth()+1)+"/"+d.getFullYear();
    }

    const DadosVenda = (venda) => {
        return (
            <div className="btn-action " style={itemSelected===venda.id?{backgroundColor: "lightGray"}:{}} onClick={()=>{setItemSelected(venda.id)}}>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <Form.Label style={{fontWeight: "bold"}}>{venda.cod_produto+" - "+venda.descricao_completa}</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>UN:</Form.Label>
                                <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{venda.unidade}</Form.Label>
                            </Col>
                            <Col>
                                <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Quantidade:</Form.Label>
                                <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{ceilValue(venda.quantidade).toLocaleString(undefined, {maximumFractionDigits:decimals})}</Form.Label>
                            </Col>
                            <Col>
                                <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Valor Unit.: R$</Form.Label>
                                <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{ceilValue(venda.valor_unitario).toLocaleString(undefined, {maximumFractionDigits:decimals})}</Form.Label>
                            </Col>
                            <Col>
                                <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Valor Total: R$</Form.Label>
                                <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{ceilValue(venda.valor_total).toLocaleString(undefined, {maximumFractionDigits:decimals})}</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Data Fat:</Form.Label>
                                <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{moment(venda.faturamento_item).format('DD/MM/YYYY')}
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Nat. Oper.:</Form.Label>
                                <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{venda.natureza_operacao}</Form.Label>
                            </Col>
                            <Col>
                                <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Sr. NF.:</Form.Label>
                                <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{venda.sr_nf}</Form.Label>
                            </Col>
                            <Col>
                                <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Nota Fiscal:</Form.Label>
                                <Button variant="link" onClick={()=>{setZoomFilter({notafiscal:venda.notafiscal})}} style={{fontWeight: "bold",padding:"0px",paddingRight: "5px",border:"0px"}}>{venda.notafiscal}<FontAwesomeIcon style={{padding:"0px",paddingLeft: "5px", color:"var(--cinzaPalagi)"}} icon={faMagnifyingGlass}/></Button>
                            </Col>
                            <Col>
                                <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Pedido:</Form.Label>
                                <Button variant="link" onClick={()=>{setZoomFilter({pedido:venda.pedido})}} style={{fontWeight: "bold",padding:"0px",paddingRight: "5px",border:"0px"}}>{venda.pedido}<FontAwesomeIcon style={{padding:"0px",paddingLeft: "5px", color:"var(--cinzaPalagi)"}} icon={faMagnifyingGlass}/></Button>
                            </Col>
                        </Row>
                    </Col>
                    {
                        itemZoom===0
                        ?<Button onClick={()=>{setItemZoom(venda.id)}} style={{width:"10px", heigth:"100%", marginRight: "11px", justifyContent: "center", display: "flex", alignItems: "center"}}><FontAwesomeIcon icon={faMagnifyingGlass} /></Button>
                        :
                        <>
                            <Row>
                                <Col>
                                    <Form.Label style={{fontWeight: "bold"}}>{venda.descricao+" - "+venda.descricao_complementar}</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Data Ped:</Form.Label>
                                    <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{dateToString(venda.criacao_pedido)}</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>ICMS: R$</Form.Label>
                                    <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{ceilValue(venda.valor_icms).toLocaleString(undefined, {maximumFractionDigits:decimals})}</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>ICMS: %</Form.Label>
                                    <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{ceilValue(venda.perc_icms).toLocaleString(undefined, {maximumFractionDigits:decimals})}</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>IPI: R$</Form.Label>
                                    <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{ceilValue(venda.valor_ipi).toLocaleString(undefined, {maximumFractionDigits:decimals})}</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>IPI: %</Form.Label>
                                    <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{ceilValue(venda.perc_ipi).toLocaleString(undefined, {maximumFractionDigits:decimals})}</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Condição pgto:</Form.Label>
                                    <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{venda.condicao_pagamento}</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Cod. lista preços:</Form.Label>
                                    <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{venda.cod_lista}</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Representante:</Form.Label>
                                    <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{venda.cod_representante+" - "+venda.representante}</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Preposto:</Form.Label>
                                    <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{venda.preposto}</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Vendedor:</Form.Label>
                                    <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{venda.vendedor}</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Ordem compra:</Form.Label>
                                    <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{venda.ordem_compra}</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Desc. Transp.:</Form.Label>
                                    <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{venda.transporte}</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Tipo Frete:</Form.Label>
                                    <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{venda.tipo_frete}</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Obs NF:</Form.Label>
                                    <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{venda.observacao_notafiscal}</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Obs Pedido:</Form.Label>
                                    <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{venda.observacao_pedido}</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Obs Item:</Form.Label>
                                    <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{venda.observacao_item}</Form.Label>
                                </Col>
                            </Row>
                        </>
                    }
                </Row>
            </div>
        )
    }

    const Vendas = () => {
        let dvendas=[];
        let quantidade_total=0.0;
        let valor_total=0.0;
        let quantidade_registros=0;
        vendas.forEach(venda => {
            let show=true;
            if(itemZoom!==0){
                show=venda.id===itemZoom;
            }else if(Object.keys(zoomFilter).length!==0){
                for(const [key, value] of Object.entries(zoomFilter)){
                    if(key==="notafiscal"){
                        if(venda.notafiscal!==value)
                            show=false;
                    }else if(key==="pedido"){
                        if(venda.pedido!==value)
                            show=false;
                    }
                }
                if(show===true){
                    quantidade_total=quantidade_total+ceilValue(venda.quantidade);
                    valor_total=valor_total+ceilValue(venda.valor_total);
                    quantidade_registros=quantidade_registros+1;
                }
            }else{
                if(dadoFilterData!==undefined && dadoFilterData.length!==0){
                    let quantidade=ceilValue(venda.quantidade).toLocaleString(undefined, {maximumFractionDigits:decimals});
                    let valor_unitario=ceilValue(venda.valor_unitario).toLocaleString(undefined, {maximumFractionDigits:decimals});
                    let valor_total=ceilValue(venda.valor_total).toLocaleString(undefined, {maximumFractionDigits:decimals});
                    let faturamento_item= moment(venda.faturamento_item).format('DD/MM/YYYY')
                    let descVenda=venda.cod_produto+"|"+venda.descricao_completa+"|"+quantidade+"|"+valor_unitario+"|"+valor_total+"|"+faturamento_item+"|"+venda.notafiscal+"|"+venda.pedido+"|"+venda.natureza_operacao;
                    show=descVenda.includes(dadoFilterData.toUpperCase());
                }           
            }
            
            if(show)
                dvendas.push(<DadosVenda key={`${venda.id}`} {...venda}/>);
        })

        return (
            <>
                {dvendas}
                {
                    Object.keys(zoomFilter).length && itemZoom===0
                    ?<Card>
                        <Card.Body className="">
                            <Row>
                                <Col>
                                    <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Quantidade:</Form.Label>
                                    <Form.Label style={{fontWeight: "bold"}}>{quantidade_total.toLocaleString(undefined, {maximumFractionDigits:decimals})}</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Valor Total: R$</Form.Label>
                                    <Form.Label style={{fontWeight: "bold"}}>{valor_total.toLocaleString(undefined, {maximumFractionDigits:decimals})}</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Registros:</Form.Label>
                                    <Form.Label style={{fontWeight: "bold"}}>{quantidade_registros.toLocaleString(undefined, {maximumFractionDigits:decimals})}</Form.Label>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    :""
                }
            </>
        )
    }

    function voltar(){
        if(refItemZoom.current!==0){
            setItemZoom(0);
        }else if(Object.keys(refZoomFilter.current).length>0)
            setZoomFilter({});
        else{
            setDadoFilterData('')
            setVendas([])
        }
    }

    function onChangeFilterData(e){
        const {value,name} = e.target;
        setDadoFilterData(value.toUpperCase());
    }
    

    return (
    <>
    <SideBar/>
    <main className="content">
            {
                online ?null :<div className="alert alert-warning" role="alert">Você está offline</div>
            }
            {
                sincronizando ?<div className="alert alert-warning" role="alert">Sincronizando</div> :null
            }
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block">
                <h4>Consulta de Vendas</h4>
            </div>
            {
                vendas.length!==0
                ?
                <Button onClick={voltar}>
                    Voltar
                </Button>
                :""
            }
        </div>
        {
            vendas.length===0
            ?
            <>
                <Row className="mb-2">
                    <Col className="d-flex justify-content-center">
                        <ButtonGroup>
                            {optFaturado.map((radio, idx) => (
                                <ToggleButton
                                    key={idx}
                                    id={`radio-${idx}`}
                                    type="radio"
                                    variant=""
                                    name="radio"
                                    value={radio.value}
                                    checked={filtroFaturado === radio.value}
                                    onChange={(e) => setFiltroFaturado(e.currentTarget.value)}
                                >
                                    {radio.name}
                                </ToggleButton>
                            ))}
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col >
                        <FiltroCliente callback={clienteSelected} modeloBotao={2} />
                    </Col>
                </Row>
            </>
            :
            <>
                <CabecalhoCliente key={vendas[0].cod_cliente}/>
                <Card border="light">
                    <Card.Body className="">
                        <Vendas/>
                    </Card.Body>
                    <Card.Footer className="d-flex justify-content-end">
                        <Button onClick={voltar}>
                            Voltar
                        </Button>
                    </Card.Footer>
                </Card>
            </>
        }
        
    </main>
    </>
    )
}