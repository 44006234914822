import React,{useState,useEffect}  from 'react';
import SideBar from '../Components/SideBar';
import api from '../api';
import { Button, ButtonGroup, Dropdown, Card, Table, Modal, Form, Row, Col,FloatingLabel } from 'react-bootstrap';
import useAuth from '../Context/hooks/UseAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEllipsisH, faTrashAlt  } from '@fortawesome/free-solid-svg-icons';
import { TableHeader } from "../Components/DataTable";
import Swal from 'sweetalert2'

let initialState={precos:[]}

export default function TextoProposta(){

    const [textos, setTextos] = useState(initialState);

    const { online, sincronizando } = useAuth();

    const [showModal, setShowModal] = useState(false);
    const [precoEdicao,setPrecoEdicao] = useState({});

    const headers = [
        { name: "Ação", field: "", sortable: false },
        { name: "Descrição", field: "descricao", sortable: false },
    ];

    const updateTextos = async (e) => {
        e.preventDefault();
        try{
            await api.patch("/formacaopreco/textosproposta",
              JSON.stringify(textos),
              {
                  headers: { 'Content-Type': 'application/json' }
              }
            );
        }catch(err){
            Swal.fire({
                title: "Erro:",
                text: err.response.data ?err.response.data.error :err.message,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok',
            });
        }
    }

    function onChanged(e){
        const {value,name} = e.target;
        setTextos({
            ...textos,
            [name]: value
            })
    }

    const loadData = async () =>{
        const response = await api.get("/formacaopreco/textosproposta",
        {
            headers: { 'Content-Type': 'application/json' }
        }
        );
        setTextos(response.data);
    }

    useEffect(()=>{
        loadData();
    },[]);

    const editPreco = (preco) =>{
        if(preco!==undefined){
          setPrecoEdicao({...preco,descricao_original:preco.descricao});
        }
        setShowModal(true)
      }

    const removePreco = (preco) => {
        Swal.fire({
            title: 'Confirma excluir?',
            text: "Preço: "+preco.descricao,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.isConfirmed) {
                let precos_aux=[];
                textos.precos.forEach(p => {
                    if(p.id!==preco.id && p.descricao!==preco.descricao)
                        precos_aux.push(p);
                });
                setTextos({...textos,precos:precos_aux});
            }
        })
    }

    const handleSaveModal = (e) => {
        e.preventDefault();
        let textos_aux={...textos};
        if(precoEdicao.id===undefined){
            textos_aux.precos.push({...precoEdicao,id:null});
        }else{
            textos_aux.precos.forEach(p => {
                if(p.id===null ?p.descricao===precoEdicao.descricao_original :p.id===precoEdicao.id)
                    p.descricao=precoEdicao.descricao
            });            
        }
        setTextos(textos_aux);
        handleCloseModal();
    }


    const handleCloseModal = () => {
        setShowModal(false);
        setPrecoEdicao({})
      }

      const TableRow = (props) => {
        const preco=props;
        //const { id, codigo, nome, nivel } = props;
        return (
          <tr>
            <td>
              <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                  <span className="icon icon-sm">
                    <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => editPreco(preco)}>
                    <FontAwesomeIcon icon={faEdit} className="me-2" /> Editar
                  </Dropdown.Item>
                  <Dropdown.Item className="text-danger" onClick={() => removePreco(preco)}>
                    <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remover
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
            <td>
              <span className="fw-normal">
                {preco.descricao}
              </span>
            </td>
          </tr>
        );
      };

    return (
        <>
        <SideBar/>
        {
            showModal?
            <React.Fragment>
                <Modal as={Modal.Dialog} centered show>
                <Modal.Header>
                    <Modal.Title className="h6">Cadastro de Preços</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form id="form-user" onSubmit={handleSaveModal}>
                    <Row>
                        <Col>
                            <FloatingLabel label="Descrição">
                                <Form.Control autoFocus required type="text" name="descricao" placeholder="" onChange={e =>{
                                    const {value,name} = e.target;
                                
                                    setPrecoEdicao({
                                    ...precoEdicao,
                                    [name]: value
                                    })
                                }} value={precoEdicao.descricao} />
                            </FloatingLabel>
                        </Col>
                    </Row>
                </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseModal}>
                    Sair
                </Button>
                    <Button form="form-user" type="submit" className="ms-auto">
                    Salvar
                </Button>
                </Modal.Footer>
                </Modal>
            </React.Fragment>
            :""
        }
        <main className="content">
            {
                online ?null :<div className="alert alert-warning" role="alert">Você está offline</div>
            }
            {
                sincronizando ?<div className="alert alert-warning" role="alert">Sincronizando</div> :null
            }
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block">
                    <h4>Textos Proposta</h4>
                </div>
            </div>
            <Form onSubmit={updateTextos}>
                <Row>
                    <Col className="mb-3">
                        <FloatingLabel label="Título">
                            <Form.Control type="text" autoComplete="off" placeholder="" name="titulo" onChange={onChanged} value={textos.titulo}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <FloatingLabel label="Complemento Título">
                            <Form.Control type="text" autoComplete="off" placeholder="" name="comp_titulo" onChange={onChanged} value={textos.comp_titulo}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <FloatingLabel label="Complemento Texto">
                            <Form.Control style={{ height: '100px' }} as="textarea" autoComplete="off" placeholder="" name="comp_texto" onChange={onChanged} value={textos.comp_texto}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <FloatingLabel label="Prazo de entrega">
                            <Form.Control type="text" autoComplete="off" placeholder="" name="infoads_prazoentrega" onChange={onChanged} value={textos.infoads_prazoentrega}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <FloatingLabel label="Impostos">
                            <Form.Control type="text" autoComplete="off" placeholder="" name="infoads_impostos" onChange={onChanged} value={textos.infoads_impostos}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <FloatingLabel label="Frete">
                            <Form.Control type="text" autoComplete="off" placeholder="" name="infoads_frete" onChange={onChanged} value={textos.infoads_frete}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <FloatingLabel label="Pagamento">
                            <Form.Control type="text" autoComplete="off" placeholder="" name="infoads_pagamento" onChange={onChanged} value={textos.infoads_pagamento}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <FloatingLabel label="Industrialização">
                            <Form.Control type="text" autoComplete="off" placeholder="" name="infoads_industrializacao" onChange={onChanged} value={textos.infoads_industrializacao}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <FloatingLabel label="Validade">
                            <Form.Control type="text" autoComplete="off" placeholder="" name="infoads_validade" onChange={onChanged} value={textos.infoads_validade}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <FloatingLabel label="Lavagem">
                            <Form.Control style={{ height: '200px' }} as="textarea" autoComplete="off" placeholder="" name="infoads_lavagem" onChange={onChanged} value={textos.infoads_lavagem}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <FloatingLabel label="Agradecimento">
                            <Form.Control style={{ height: '200px' }} as="textarea" autoComplete="off" placeholder="" name="infoads_agradecimento" onChange={onChanged} value={textos.infoads_agradecimento}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <FloatingLabel label="Telefone">
                            <Form.Control type="text" autoComplete="off" placeholder="" name="infoads_telefone" onChange={onChanged} value={textos.infoads_telefone}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <FloatingLabel label="Email">
                            <Form.Control type="text" autoComplete="off" placeholder="" name="infoads_email" onChange={onChanged} value={textos.infoads_email}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <FloatingLabel label="Razão Social">
                            <Form.Control type="text" autoComplete="off" placeholder="" name="rodape_razaosocial" onChange={onChanged} value={textos.rodape_razaosocial}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <FloatingLabel label="Endereço">
                            <Form.Control type="text" autoComplete="off" placeholder="" name="rodape_endereco" onChange={onChanged} value={textos.rodape_endereco}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">
                        <FloatingLabel label="Contato">
                            <Form.Control style={{ height: '200px' }} as="textarea" type="text" autoComplete="off" placeholder="" name="rodape_contato" onChange={onChanged} value={textos.rodape_contato}/>
                        </FloatingLabel>
                    </Col>
                </Row>

                <div className="d-flex justify-content-between" style={{paddingBottom: "10px"}}>
                    <Button className="" onClick={() => editPreco()}>Novo</Button>
                </div>
                <Card border="light" className="table-wrapper table-responsive shadow-sm">
                    <Card.Body className="">
                        <Table hover striped className="user-table align-items-center">
                            <TableHeader
                                headers={headers}
                            />
                        <tbody>
                            {
                                textos.precos.map(t => <TableRow key={`${t.id}`} {...t} />)
                            }
                        </tbody>
                        </Table>
                    </Card.Body>
                </Card>


                <Row>
                    <Col md={12} className="mb-3 d-flex justify-content-center">
                        <Button type="submit" className="col-md-3 col-12" >Salvar</Button>
                    </Col>
                </Row>
            </Form>
        </main>
        </>
    )
}
