import { createContext, useState, useEffect } from "react";
import api from '../api';
import {tokenDecrypt} from '../Components/crypto'
import {deflateSync, inflateSync} from 'browserify-zlib';
import {confirmAlert} from 'react-confirm-alert'
import axios from 'axios';
import palagiDB from "../PalagiDB"

const AuthContext = createContext({});


export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});

    const [loading,setLoading] = useState(true);

    const [online,setOnline]=useState(true);
    const [cacheInfos,setCacheInfos]=useState(false);

    const [sincronizando,setSincronizando]=useState(false);
    const [sincronizou,setSincronizou]=useState(false);

    async function get(storeName){
        return await new Promise((resolve, reject) => {
            const tx = palagiDB.db.transaction(storeName, 'readonly');
            const store = tx.objectStore(storeName);
            const request = store.get(0);
            request.onsuccess = (event) => {
                resolve(event.target.result);
            };
            request.onerror = (event) => {
                reject(event.target.error);
            };
          });
    }

    function set(storeName,data){
        const trans=palagiDB.db.transaction(storeName,"readwrite");
        const os=trans.objectStore(storeName);

        os.put(data,0);
    }

    function remove(storeName){
        const trans=palagiDB.db.transaction(storeName,"readwrite");
        const os=trans.objectStore(storeName);
        os.delete(0);
    }

    const resetInfos = async () =>{
        setAuth({});
        localStorage.removeItem("token");
        localStorage.removeItem("infos");
        localStorage.removeItem("formacaopreco");
        localStorage.removeItem("pedido");

        remove("precos");
        remove("estoque");
        remove("orcamento");
        remove("tecido");
        remove("vacum");
        remove("mestico");
        remove("clientes");
        remove("textosproposta");
        remove("bases");
        remove("vendas");
        remove("reservas");
        remove("beneficiamentos");
        remove("textospedido");
        remove("listaspreco");

        setLoading(false);   
        setSincronizou(false);
        setSincronizando(false);                         
    }

    const logout = async (values) => {
        resetInfos();
    }

    const login = async (values) => {
        let currToken = localStorage.getItem('token');
        try{
            let response;
            if(values===undefined){
                if(currToken===undefined || currToken===""){
                    resetInfos();
                    return "";
                }
                response=await api.post("/login/verify",
                JSON.stringify({token: JSON.parse(currToken)}),
                {
                    headers: { 'Content-Type': 'application/json' }
                }
                );
            }else{
                response = await api.post("/login",
                JSON.stringify(values),
                {
                    headers: { 'Content-Type': 'application/json' }
                }
            );
            }

            let token = response.data.token;
            const tokenInfo=tokenDecrypt(token)
            
            const nivel = tokenInfo.nivel;
            const id = tokenInfo.id;
            const codigo = tokenInfo.codigo;
            const cod_vendedor = tokenInfo.cod_vendedor;

            setAuth({token,nivel,id,codigo,cod_vendedor});

            api.defaults.headers.Authorization = 'Bearer '+token;
            localStorage.setItem('token',JSON.stringify(token));
            setLoading(false);
            setOnline(true);
            sincronizaInfos(id,codigo,nivel,cod_vendedor,token);
            return "";
        }catch(err){
            let errMsg="";
            if(!err.response || err.response.status===0){
                if(currToken===undefined || currToken===""){
                    resetInfos();
                    return "";
                }
                setOnline(false);
                //OFFLINE
                const tokenInfo=tokenDecrypt(currToken)
                const nivel = tokenInfo.nivel;
                const id = tokenInfo.id;
                const codigo = tokenInfo.codigo;
                const cod_vendedor = tokenInfo.cod_vendedor;

                let ts=Date.now();
                let hoje = new Date(ts)
                let validade = new Date(tokenInfo.validade)
                if(validade>hoje){
                    api.defaults.headers.Authorization = 'Bearer '+JSON.parse(currToken);
                    setAuth({currToken,nivel,id,codigo,cod_vendedor});
                    setLoading(false);
                }else{
                    await resetInfos();
                }
                return "";
            }else if (err.response?.status === 400) {
                errMsg='Usuário ou senha inválida.';
            } else if (err.response.status === 401) {
                errMsg=err.response.data.error;
            } else {
                errMsg='Não foi possível validar os dados de login';
            }
            await resetInfos();
            return errMsg;
        }
    }

    useEffect(() => {
        let token = localStorage.getItem('token');

        if(token){
            login();
        }else{
            setLoading(false);
        }

    },[]);

    const processaLogin = async(values) =>{
        return await login(values);
    }

    const loadOfflineData = async (e) => {
        if(cacheInfos===false){
            let infos=localStorage.getItem("infos");
            if(infos!==undefined && infos!=="" && infos!==null){
                infos=JSON.parse(infos);

                let precos=await get("precos");
                if(precos.length)
                    infos.precos=JSON.parse(await inflateSync(Buffer.from(precos.split(','))));
                
                let estoque=await get("estoque");
                if(estoque.length)
                    infos.estoque=JSON.parse(await inflateSync(Buffer.from(estoque.split(','))));

                let orcamento=await get("orcamento");
                if(orcamento.length)
                    infos.orcamento=JSON.parse(await inflateSync(Buffer.from(orcamento.split(','))));
                
                let tecido=await get("tecido");
                if(tecido.length)
                    infos.tecido=JSON.parse(await inflateSync(Buffer.from(tecido.split(','))));
                
                let vacum=await get("vacum");
                if(vacum.length)
                    infos.vacum=JSON.parse(await inflateSync(Buffer.from(vacum.split(','))));
                
                let mestico=await get("mestico");
                if(mestico.length)
                    infos.mestico=JSON.parse(await inflateSync(Buffer.from(mestico.split(','))));
                
                let clientes=await get("clientes");
                if(clientes.length)
                    infos.clientes=JSON.parse(await inflateSync(Buffer.from(clientes.split(','))));
                
                let textosProposta=await get("textosproposta");
                if(textosProposta.length)
                    infos.textosProposta=JSON.parse(await inflateSync(Buffer.from(textosProposta.split(','))));
                
                let bases=await get("bases");
                if(bases.length)
                    infos.bases=JSON.parse(await inflateSync(Buffer.from(bases.split(','))));
                
                let vendas=await get("vendas");
                if(vendas.length)
                    infos.vendas=JSON.parse(await inflateSync(Buffer.from(vendas.split(','))));

                let reservas=await get("reservas");
                if(reservas.length)
                    infos.reservas=JSON.parse(await inflateSync(Buffer.from(reservas.split(','))));

                let beneficiamentos=await get("beneficiamentos");
                if(beneficiamentos.length)
                    infos.beneficiamentos=JSON.parse(await inflateSync(Buffer.from(beneficiamentos.split(','))));

                let textospedido=await get("textospedido");
                if(textospedido.length)
                    infos.textospedido=JSON.parse(await inflateSync(Buffer.from(textospedido.split(','))));

                let listaspreco=await get("listaspreco");
                if(listaspreco.length)
                    infos.listaspreco=JSON.parse(await inflateSync(Buffer.from(listaspreco.split(','))));

                setCacheInfos(infos);
                return infos;
            }
        }
        return cacheInfos;
    }
    
    const sincronizaInfos = async (id_usuario,cod_usuario,nivel_usuario,cod_vendedor,token) =>{
        if(sincronizou) return;

        try{
            setSincronizando(true);
            setOnline(true);

            let values={usuario: {id_usuario:id_usuario,codigo: cod_usuario,nivel: nivel_usuario,cod_vendedor: cod_vendedor}}

            let infos=localStorage.getItem("infos");
            if(infos){
                try{
                    infos=JSON.parse(infos);
                    values={...values,ultimaatualizacao: infos.data};
                }catch(err){
                    infos="";
                }
            }

            if(infos===undefined || infos==="" || infos===null){
                infos={
                    data:""
                }
            }

            {
                await api.post("/consultapreco/offline",JSON.stringify(values),
                {
                    headers: { 'Content-Type': 'application/json' },
                    responseType: 'stream'
                }).then(({ data }) => {

                    processaRetornoSincronismo(data,infos);
                })
            }

            {
                await api.post("/cliente/offline",JSON.stringify(values),
                {
                    headers: { 'Content-Type': 'application/json' },
                    responseType: 'stream'
                }).then(({ data }) => {

                    processaRetornoSincronismo(data,infos);
                })
            }

            {
                await api.post("/formacaopreco/offline",JSON.stringify(values),
                {
                    headers: { 'Content-Type': 'application/json' },
                    responseType: 'stream'
                }).then(({ data }) => {

                    processaRetornoSincronismo(data,infos);
                })
            }

            {
                await api.post("/estoque/offline",JSON.stringify(values),
                {
                    headers: { 'Content-Type': 'application/json' },
                    responseType: 'stream'
                }).then(({ data }) => {

                    processaRetornoSincronismo(data,infos);
                })
            }

            {
                await api.post("/pedido/offline",JSON.stringify(values),
                {
                    headers: { 'Content-Type': 'application/json' },
                    responseType: 'stream'
                }).then(({ data }) => {

                    processaRetornoSincronismo(data,infos);
                })
            }

            {
                await api.post("/consultavendas/offline",JSON.stringify(values),
                {
                    headers: { 'Content-Type': 'application/json' },
                    responseType: 'stream'
                }).then(({ data }) => {

                    processaRetornoSincronismo(data,infos);
                    
                    localStorage.setItem("infos",JSON.stringify(infos));

                    setSincronizando(false);
                })
            }
        }catch(err){
            setSincronizando(false);
            if(!err.response || err.response.status===0){
                setOnline(false)
            }else{
                confirmAlert({
                    title: "Erro:",
                    message: err.response.data ?err.response.data.error :err.message,
                    buttons: [
                        {
                        label: 'Ok',
                        }
                    ]
                })
            }
        }
        setSincronizou(true);
    }

    const processaRetornoSincronismo = async (responseData,infos) =>{
        infos.data=responseData.data;

        if(responseData.precos!==undefined && responseData.precos.length > 0)
            set("precos",responseData.precos);

        if(responseData.estoque!=undefined && responseData.estoque.length > 0)
            set("estoque",responseData.estoque)

        if(responseData.orcamento!=undefined && responseData.orcamento.length > 0)
            set("orcamento",responseData.orcamento)

        if(responseData.tecido!=undefined && responseData.tecido.length > 0)
            set("tecido",responseData.tecido)

        if(responseData.vacum!=undefined && responseData.vacum.length > 0)
            set("vacum",responseData.vacum)

        if(responseData.mestico!=undefined && responseData.mestico.length > 0)
            set("mestico",responseData.mestico)

        if(responseData.clientes!=undefined && responseData.clientes.length > 0)
            set("clientes",responseData.clientes)

        if(responseData.textosProposta!=undefined && Object.entries(responseData.textosProposta).length > 0)
            set("textosproposta",responseData.textosProposta)

        if(responseData.bases!=undefined && responseData.bases.length > 0)
            set("bases",responseData.bases);

        if(responseData.vendas!=undefined && responseData.vendas.length > 0)
            set("vendas",responseData.vendas);

        if(responseData.reservas!=undefined && responseData.reservas.length > 0)
            set("reservas",responseData.reservas);

        if(responseData.beneficiamentos!=undefined && responseData.beneficiamentos.length > 0)
            set("beneficiamentos",responseData.beneficiamentos);

        if(responseData.textosPedido!=undefined && responseData.textosPedido.length > 0)
            set("textospedido",responseData.textosPedido);

        if(responseData.listasPreco!=undefined && responseData.listasPreco.length > 0)
            set("listaspreco",responseData.listasPreco);
    }

    var decimals = 2;

    function ceilValue(val){
        return (Math.ceil(val*Math.pow(10,decimals))/Math.pow(10,decimals))
    }

    return (
        <AuthContext.Provider value={{ auth, loading, setOnline, online, processaLogin, loadOfflineData, sincronizando, logout, ceilValue, decimals}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;