import React,{useState,useEffect,useRef} from 'react';
import ReactDOM from 'react-dom';
import SideBar from '../Components/SideBar';
import api from '../api';
import useAuth from '../Context/hooks/UseAuth';
import { Button, Card,  Modal, Form, Row, Col,FloatingLabel, ToggleButton } from 'react-bootstrap';
import {confirmAlert} from 'react-confirm-alert'
import Enumerable from 'linq'
import { faCalculator, faTrash, faPencil, faMagnifyingGlass, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from 'sweetalert2'
import {Proposta,PropostaViewer} from './Proposta';
import {ReactPDF,BlobProvider,PDFDownloadLink,pdf} from '@react-pdf/renderer';
import moment from 'moment/min/moment-with-locales';
import FiltroCliente from '../Components/FiltroCliente'; 
import BtnModalCalculatorFormacaoPreco from '../Components/BtnModalCalculatorFormacaoPreco'; 
import SelecaoValoresFormacaoPreco from '../Components/SelecaoValoresFormacaoPreco'; 

const initialFilters={cod_produto:"",descricao:""}
const initialTipo={key:"", text:""}
const initialDadosProposta={cliente:{},cod_lista:"",/*tubete:{},*/icms:{},imprimirdesconto:false}
const initialTextos={precos:[]}

var initialIdx = -1;
moment.locale('pt-br');

export default function FormacaoPreco(){

    const { online,setOnline } = useAuth();
    const { loadOfflineData, sincronizando, ceilValue,decimals } = useAuth();


    const [tipoProduto,setTipoProduto]=useState(initialTipo);
    const [filters, setFilters] = useState(initialFilters);
    const [prods, setProds] = useState([]);
    const [showModalProds, setShowModalProds] = useState(false);
    const [itensProposta, setItensProposta] = useState([]);
    const [showItens, setShowItens] = useState(false);
    const [currentIdx, setCurrentIdx] = useState(initialIdx);
    const [carregou, setCarregou] = useState(false);
    const [codigosEspeciais] = useState({plg:"10291",blob:"9998",dublagem:"2894"});
    const [btnCodsEspeciaisVisible,setBtnCodsEspeciaisVisible] = useState({plg:true,blob:true,dublagem:true,beneficiamento:true});
    const [textosProposta, setTextosProposta] = useState(initialTextos);
    const [dadosProposta, setDadosProposta] = useState(initialDadosProposta);
    //const [tubetes,setTubetes] = useState([{id:0,codigo:"Não",descricao:"informado"},{id:1,codigo:"Tubete N1",descricao:"Papelão 16 x 2,5 x 1650mm"},{id:2,codigo:"Tubete N2",descricao:"Papelão 40 x 1,8 x 1700mm"},{id:3,codigo:"Tubete N3",descricao:"Papelão 77 x 2,5 x 1650mm"}]);
    const [bases, setBases] = useState([]);
    const [finalizou, setFinalizou] = useState(false);
    const [clicouProposta,setClicouProposta] = useState(false);
    const [itemSelected, setItemSelected] = useState(0);
    

    const refShowModalProds = useRef(showModalProds);

    const refItensProposta = useRef(itensProposta);
    const refCurrentIdx = useRef(currentIdx)

    const btnQuantidade = React.useRef();
    const refModalFinalizacao = React.useRef();

    useEffect(() => {
        if(carregou===true && showModalProds===false){
            localStorage.setItem('formacaopreco',JSON.stringify(
                {
                    showItens: showItens,
                    tipoProduto: tipoProduto,
                    currentIdx:currentIdx,
                    itensProposta: itensProposta,
                    dadosProposta: dadosProposta
                }
            ));
        }
    },[showItens,tipoProduto,currentIdx,itensProposta,showModalProds,dadosProposta]);

    async function loadTextosPropostas(){
        let data;
        try{
            const response = await api.get("/formacaopreco/textosproposta",
            {
                headers: { 'Content-Type': 'application/json' }
            }
            );
            data=response.data;
            setOnline(true);
        }catch(err){
            if(!err.response || err.response.status===0){
                setOnline(false);
            }else{
                confirmAlert({
                    title: "Erro:",
                    message: err.response.data ?err.response.data.error :err.message,
                    buttons: [
                        {
                            label: 'Ok',
                        }
                        ]
                })
            }
        }
        if(data===undefined){
            let cacheInfos=await loadOfflineData();
            if(cacheInfos!==false){
                data=cacheInfos.textosProposta;
            }
        }
        setTextosProposta(data);
    }

    async function loadBases(){
        let data;
        try{
            const response = await api.post("/formacaopreco/bases",
            {
                headers: { 'Content-Type': 'application/json' }
            }
            );
            data=response.data.bases;
            setOnline(true);
        }catch(err){
            if(!err.response || err.response.status===0){
                setOnline(false);
            }else{
                confirmAlert({
                    title: "Erro:",
                    message: err.response.data ?err.response.data.error :err.message,
                    buttons: [
                        {
                            label: 'Ok',
                        }
                        ]
                })
            }
        }
        if(data===undefined){
            let cacheInfos=await loadOfflineData();
            if(cacheInfos!==false){
                data=cacheInfos.bases;
            }
        }
        setBases(data);
    }

    function resetInfos(){
        setTipoProduto(initialTipo);
        setItensProposta([])
        setFilters(initialFilters);
        setCurrentIdx(initialIdx);
        setShowItens(false);
        setDadosProposta(initialDadosProposta);
        setFinalizou(false);
    }

    function voltar(){
        resetInfos();
    }

    function getValorDado(dado,preco){
        let valor=0.0;
        dado.precos.forEach(p => {
            if(p.id_preco===preco.id){
                valor=p.valor;
                return;
            }
        });
        return valor;
    }

    function calculaValor(dado,preco,considerarQuantidade){
        let valor=0.0;
        if(parseFloat(dado.percentualDesconto)>0){
            valor=ceilValue(getValorDado(dado,preco)-(getValorDado(dado,preco)*(parseFloat(dado.percentualDesconto.replaceAll('.','').replaceAll(',','.'))/100.0)))
        }else if(parseFloat(dado.percentualOverprice)>0){
            valor=ceilValue(getValorDado(dado,preco)+(getValorDado(dado,preco)*(parseFloat(dado.percentualOverprice.replaceAll('.','').replaceAll(',','.'))/100.0)))
        }else if(dado.precoManual!==undefined && dado.precoManual.icmsBase!==undefined){
            valor=ceilValue(getValorDado(dado,preco)-(getValorDado(dado,preco)*dado.precoManual.percentual));
        }else{
            valor=ceilValue(getValorDado(dado,preco));
        }
        if(considerarQuantidade===true)
            return ceilValue(valor*dado.quantidade);
        return valor;
    }

    const buscaProdutos = async (cod_prooduto) => {
        let fil={
            ...filters,
            descricao: filters.descricao==="" ?undefined :filters.descricao.split(' ')
        }

        if(cod_prooduto!==undefined){
            fil.cod_produto=cod_prooduto;
        }

        let existeFiltro=false;

        for(const [key, value] of Object.entries(fil)){
            if(value!==undefined && (value.length>0 || value===true)){
                existeFiltro=true;
                break;
            }
        }

        if(existeFiltro){

            let data;
            try{
                const response = await api.post("/formacaopreco/",
                JSON.stringify({...fil,tipoProduto: tipoProduto.key}),
                {
                    headers: { 'Content-Type': 'application/json' }
                }
                );
                data=response.data;
                setOnline(true);
            }catch(err){
                if(!err.response || err.response.status===0){
                    setOnline(false);
                }else{
                    confirmAlert({
                        title: "Erro:",
                        message: err.response.data ?err.response.data.error :err.message,
                        buttons: [
                            {
                                label: 'Ok',
                            }
                            ]
                    })
                }
            }

            if(data===undefined){
                let cacheInfos=await loadOfflineData();
                if(cacheInfos!==false){
                        /* ESSE CÓDIGO É BASEADO NO CÓDIGO IMPLEMENTADO NO BACKEND infos-controlles.js getFormacaoPreco, IMPORTANTE OS 2 MANTEREM A MESMA LÓGICA, ENTÃO SE ALTERAR UM REVISAR O OUTRO */
                        let query;
                        if(tipoProduto.key==="tecido"){
                            query=Enumerable.from(cacheInfos.tecido);
                        }else if(tipoProduto.key==="mestico"){
                            query=Enumerable.from(cacheInfos.mestico);
                        }else if(tipoProduto.key==="vacum"){
                            query=Enumerable.from(cacheInfos.vacum);
                        }
                        
                        if(fil.cod_produto!==undefined && fil.cod_produto.length>0){
                            query=query.where(i=> i.cod_produto==fil.cod_produto);
                        }

                        if(fil.descricao!==undefined && fil.descricao.length>0){
                            fil.descricao.forEach(desc =>{
                                if(desc.length>0){
                                    query=query.where(i => i.descricao.toUpperCase().indexOf(desc.toUpperCase())>=0);
                                }
                            });
                        }

                        let result=query.toArray();

                        let prods=[]
                        result.forEach(res => {
                            let queryProds=Enumerable.from(cacheInfos.estoque);
                            let arrayProd=queryProds.where(p => p.cod_produto===res.cod_produto).toArray();
                            let estoque=0.0;
                            arrayProd.forEach(p => {
                                estoque=estoque+p.livre;
                            });
                            prods.push({...res,estoque: estoque, percentualDesconto: ''});
                        })
                        data={produtos: prods}
                }
            }

            if(data.produtos.length > 1){
                setProds(data.produtos);
                setShowModalProds(true)
            }else if(data.produtos.length===1){
                clickedRowProd(data.produtos[0])
            }
        }
    };

    function btnPropostaClicked(){
        let qtaInformada=true;
        let itemPed=getCurrentItem();
        if(itemPed!=undefined){
            if(itemPed.dadosItem.length===0){
                removeItem(itemPed.idx);
            }else{
                if(itemPed.quantidade==='' || parseFloat(itemPed.quantidade)===0){
                    qtaInformada=false;
                }
                
                if(qtaInformada===false){
                    setClicouProposta(true);
                    setTimeout(function() {
                        btnQuantidade.current.click()
                    }, 0);
                    return;
                }
            }
        }

        setShowItens(true);
        setCurrentIdx(initialIdx);
        setFilters(initialFilters);
        setClicouProposta(false);
    }

    function onChanged(e){
        const {value,name} = e.target;

        const re = /^[0-9/\b]+$/;
        if (value === '' || re.test(value)) {
            setFilters({
                ...filters,
                [name]: value
              })
        }
    }

    function onChangedList(e){
        const {value,name} = e.target;
        setFilters({
            ...filters,
            [name]: value
        })
    }

    function removeItem(idx){
        let itensPropostaCopy=[];
        let pos=0;
        itensProposta.forEach(it =>{
            if(it.idx!==idx && it.dadosItem.length>0){
                it.idx=pos;
                pos=pos+1;
                itensPropostaCopy.push(it);
            }
        });
        setItensProposta(itensPropostaCopy)
    }

    const BtnActionProd = (props) => {
        const prod=props;
        return (
            <div className="btn-action d-flex" onClick={()=>{clickedRowProd(prod)}} style={{height: "40px", alignItems: "center"}}>
                <div>
                    <Form.Label style={{fontWeight: "bold"}}>{prod.cod_produto+" - "+prod.descricao}</Form.Label>
                </div>
            </div>
        );
    };

    function getCurrentItem(itensPropostaCopy){
        if(itensPropostaCopy===undefined)
            itensPropostaCopy=itensProposta;
        return itensPropostaCopy.find(function(item, i){
            if(item.idx === currentIdx){
                return item;
            }
        })
        
    }

    var clickedRowProd = async (prod) => {
        setFilters(initialFilters);

        let itensPropostaCopy=[...itensProposta];

        let itemProposta;
        if(currentIdx===initialIdx){
            itemProposta={idx: itensPropostaCopy.length, dadosItem: [], percentualDesconto:'', percentualOverprice:'',quantidade:''};
            setCurrentIdx(itemProposta.idx)
            itensPropostaCopy.push(itemProposta);
        }else{
            itemProposta=getCurrentItem(itensPropostaCopy);
        }
        if(prod.cod_produto>0)
            itemProposta.dadosItem.push(prod);
        else
            itemProposta.dadosItem.unshift(prod);
        itemProposta.percentualDesconto="";
        itemProposta.percentualOverprice="";
        itemProposta.quantidade="";
        itemProposta.precoManual={}

        itemProposta.dadosItem=updateBtnCodsEspeciais(itemProposta.dadosItem)
        
        if(showModalProds===true){
            setShowModalProds(false);
        }
        setItensProposta(itensPropostaCopy);
    }

    var updateBtnCodsEspeciais = (dadosItem) => {
        let plg=true;
        let dub=true;
        dadosItem.forEach(d =>{
            let base=
            bases.find(function(b, i){
                if(b.cod_produto === d.cod_produto){
                    return b;
                }
            })
            if(base!==undefined){
                if(base.plg===0)
                    plg=false;
                if(base.dublagem===0)
                    dub=false;
            }
            if(plg===false && dub===false)
                return;
        })
        setBtnCodsEspeciaisVisible({...btnCodsEspeciaisVisible,plg:plg,dublagem:dub});

        if(plg===false){
            let oldSize=dadosItem.length;
            dadosItem=dadosItem.filter(d => d.cod_produto.toString() !== codigosEspeciais.plg)
            if(oldSize!=dadosItem.length){
                confirmAlert({
                    title: "Aviso:",
                    message: "Existem itens que não aceitam PLG, o PLG será removido da lista.",
                    buttons: [
                        {
                            label: 'Ok',
                        }
                        ]
                })
            }
        }
        if(dub===false){
            let oldSize=dadosItem.length;
            dadosItem=dadosItem.filter(d=> d.cod_produto.toString() !== codigosEspeciais.dublagem)
            if(oldSize!=dadosItem.length){
                confirmAlert({
                    title: "Aviso:",
                    message: "Existem itens que não aceitam DUBLAGEM, a DUBLAGEM será removida da lista.",
                    buttons: [
                        {
                            label: 'Ok',
                        }
                        ]
                })
            }
        }
        return dadosItem;
    }

    function clienteSelected(cliente){
        setDadosProposta({...dadosProposta,cliente: cliente});
    }

    const handleCloseModalProds = () => {
        setShowModalProds(false);
    }


    useEffect(() => {
        loadBases();
        loadTextosPropostas();

        let cache=localStorage.getItem("formacaopreco");
        if(cache!==undefined && cache!=="" && cache!==null){
            cache=JSON.parse(cache);
            setShowItens(cache.showItens);
            setTipoProduto(cache.tipoProduto)
            setCurrentIdx(cache.currentIdx);
            setItensProposta(cache.itensProposta);
            setDadosProposta(cache.dadosProposta);
        }
        setCarregou(true)

        const keyDownHandler = (event) => {
            if (event.key === 'Escape') {
              event.preventDefault();

              if(refShowModalProds.current===true){
                handleCloseModalProds();
              }
            }/*else if (event.key === 'Enter') {

            }*/
          };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
          document.removeEventListener('keydown', keyDownHandler);
        };
    },[]);

    useEffect(() => {
        refShowModalProds.current = showModalProds;
      }, [showModalProds]);

      useEffect(() => {
        refItensProposta.current = itensProposta;
      }, [itensProposta]);

      useEffect(() => {
        refCurrentIdx.current = currentIdx;
      }, [currentIdx]);
      

    var removeItemClicked = (dado) => {
        let itensPropostaCopy=[...itensProposta];
        let dadosAux=[]
        let curItem=getCurrentItem(itensPropostaCopy);
        curItem.dadosItem.forEach(d =>{
            if(d.id!=dado.id){
                dadosAux.push(d);
            }
        });
        
        curItem.dadosItem=updateBtnCodsEspeciais(dadosAux);

        setItensProposta(itensPropostaCopy);
    }

    const DadosPreco = (props) => {
        let preco=props.preco;
        let dado=props.dado;

        return (
            <Col>
                <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>{preco.descricao+": R$"}</Form.Label>
                <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{calculaValor(dado,preco).toLocaleString(undefined, {minimumFractionDigits:decimals,maximumFractionDigits:decimals})}</Form.Label>
            </Col>
        );
    };

    const DadosProdutos = (dado) => {
        if(textosProposta.precos.length===0) return;
        if(dado.precos!==undefined){
            dado.precos.forEach(dp => {
                textosProposta.precos.forEach( p =>{
                    if(dp.id_preco===p.id){
                        dp.descricao=p.descricao
                        return;
                    }
                })
            });
        }
        function updateDescontoProduto(dadoCalculator,valor){
            let itensPropostaCopy=[...refItensProposta.current];
            let itemPed=itensPropostaCopy.find(function(item, i){
                if(item.idx === refCurrentIdx.current){
                    return item;
                }
            })
            let dadosItemCopy=[];
            itemPed.dadosItem.forEach(di => {
                let din={...di}
                if(din.id===dadoCalculator.id)
                    din.percentualDesconto=valor;
                dadosItemCopy.push(din)
            });
            itemPed.dadosItem=dadosItemCopy
            setItensProposta(itensPropostaCopy)
        }
    
        function getItemModalCalculatorDesconto(){
            let vlrAtual='';
            if(dado.percentualDesconto!==undefined && dado.percentualDesconto!=="" && parseFloat(dado.percentualDesconto)>0){
                vlrAtual=dado.percentualDesconto;
            }
            return {
                valorAtual:vlrAtual,
                ...dado
            };
        }
        return (
            <div className="btn-action" style={itemSelected===dado.id?{backgroundColor: "lightGray"}:{}} onClick={()=>{setItemSelected(dado.id)}}>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <Form.Label style={{fontWeight: "bold"}}>{(dado.cod_produto>0 ?dado.cod_produto+" - ":"")+dado.descricao}</Form.Label>
                            </Col>
                        </Row>
                        {
                        dado.cod_produto>0
                        ?<>
                        <Row>
                            <Col>
                                <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>UN:</Form.Label>
                                <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{dado.unidade}</Form.Label>
                            </Col>

                            <Col>
                                <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Estoque:</Form.Label>
                                <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{dado.estoque.toLocaleString()}</Form.Label>
                            </Col>

                            <Col>
                                <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Desc. Máx.:</Form.Label>
                                
                                <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{dado.desconto_maximo.toLocaleString(undefined, {maximumFractionDigits:decimals})}
                                <Form.Label style={{fontSize:"11px"}}>%</Form.Label>
                                    {
                                        dado.percentualDesconto!==undefined && dado.percentualDesconto!=="" && parseFloat(dado.percentualDesconto)>0
                                        ?<>
                                            <Form.Label style={{paddingLeft: "5px"}}>(</Form.Label>
                                            <Form.Label style={{fontWeight: "bold"}}>{dado.percentualDesconto.toLocaleString(undefined, {maximumFractionDigits:decimals})}</Form.Label>
                                            <Form.Label style={{fontSize:"11px"}}>%</Form.Label>
                                            <Form.Label >)</Form.Label>
                                        </>
                                        :""
                                    }
                                </Form.Label>
                                {
                                    dado.desconto_maximo>0.01 && getCurrentItem().percentualDesconto===''
                                    ?<BtnModalCalculatorFormacaoPreco callback={updateDescontoProduto} getItem={getItemModalCalculatorDesconto} tipo="Desconto"/>
                                    :""
                                }
                                    
                            </Col>
                        </Row>
                        <Row>
                            {
                                textosProposta.precos.map(p => <DadosPreco key={dado.id+"_"+p.id} preco={p} dado={dado}/>)
                            }
                        </Row>
                        </>
                        :""
                    }
                    </Col>
                    <Button onClick={()=>{removeItemClicked(dado)}} style={{width:"10px", heigth:"100%", marginRight: "11px", justifyContent: "center", display: "flex", alignItems: "center"}}><FontAwesomeIcon icon={faTrash} /></Button>
                </Row>
            </div>
        );
    };

    const DadosItensComposicao = (itemPed) => {
        if(textosProposta.precos.length===0) return;
        //let icmsvintedois=0.0,icmsdezessete=0.0,icmsdoze=0.0,icmssete=0.0,icmszero=0.0;
        let temItens=false;
        let descs=[]
        let descMaximo=0;
        let percMaximo=0;
        let total=0;
        let temDesconto=false;
        let quantidade=1.0;
        let precos=[]
        textosProposta.precos.forEach( p =>{
            precos.push({...p, id_preco:p.id, valor:0.0});
        })
        itemPed.dadosItem.forEach(dado =>{
            temItens=true;
            descs.push((dado.cod_produto>0 ?dado.cod_produto+" - ":"")+dado.descricao);
            if(dado.cod_produto>0){
                precos.forEach( p =>{
                    p.valor+=calculaValor(dado,p);
                })
                total+=calculaValor(dado,precos[0]);
                if(dado.desconto_maximo>0.01){
                    descMaximo=descMaximo+(calculaValor(dado,precos[0])*(dado.desconto_maximo/100.0));
                }
                if(dado.percentualDesconto!=='' && parseFloat(dado.percentualDesconto)>=0.01){
                    temDesconto=true;
                }
            }
        });

        if(descMaximo>0.01){
            percMaximo=(descMaximo/total)*100.0
        }

        if(itemPed.quantidade!=='' && parseFloat(itemPed.quantidade)>=0.01)
            quantidade=parseFloat(itemPed.quantidade.replaceAll('.','').replaceAll(',','.'));

        let tempDado={
            precos:precos,
            percentualDesconto: itemPed.percentualDesconto,
            percentualOverprice: itemPed.percentualOverprice,
            quantidade: quantidade,
            desconto_maximo:percMaximo,
            precoManual: itemPed.precoManual,
            idx: itemPed.idx
        }
        precos=[];
        tempDado.precos.forEach(p =>{
            precos.push({...p,valor: calculaValor(tempDado,p,true)});
        })
        let tempDadoCalc={
            precos:precos,
            idx: itemPed.idx
        }

        function updateQuantidadeProduto(dadoCalculator,valor){
            let itensPropostaCopy=[...refItensProposta.current];
            let itemPed=itensPropostaCopy.find(function(item, i){
                if(item.idx === dadoCalculator.idx){
                    return item;
                }
            })
            itemPed.quantidade=valor;
            setItensProposta(itensPropostaCopy)
            if(clicouProposta && valor!="" && parseFloat(valor)>0)
                btnPropostaClicked();
        }
    
        function getItemModalCalculatorQuantidade(){
            let vlrAtual='';
            if(itemPed.quantidade!==undefined && itemPed.quantidade!=="" && parseFloat(itemPed.quantidade)>0){
                vlrAtual=itemPed.quantidade;
            }
            return {
                valorAtual:vlrAtual,
                ...tempDadoCalc
            };
        }

        function updateOverpriceProduto(dadoCalculator,valor){
            let itensPropostaCopy=[...refItensProposta.current];
            let itemPed=itensPropostaCopy.find(function(item, i){
                if(item.idx === dadoCalculator.idx){
                    return item;
                }
            })
            itemPed.percentualOverprice=valor;
            setItensProposta(itensPropostaCopy)
        }
    
        function getItemModalCalculatorOverprice(){
            let vlrAtual='';
            if(itemPed.percentualOverprice!==undefined && itemPed.percentualOverprice!=="" && parseFloat(itemPed.percentualOverprice)>0){
                vlrAtual=itemPed.percentualOverprice;
            }
            return {
                valorAtual:vlrAtual,
                ...tempDado
            };
        }

        function updateDescontoProduto(dadoCalculator,valor){
            let itensPropostaCopy=[...refItensProposta.current];
            let itemPed=itensPropostaCopy.find(function(item, i){
                if(item.idx === dadoCalculator.idx){
                    return item;
                }
            })
            itemPed.percentualDesconto=valor;
            setItensProposta(itensPropostaCopy)
        }
    
        function getItemModalCalculatorDesconto(){
            let vlrAtual='';
            if(itemPed.percentualDesconto!==undefined && itemPed.percentualDesconto!=="" && parseFloat(itemPed.percentualDesconto)>0){
                vlrAtual=itemPed.percentualDesconto;
            }
            return {
                valorAtual:vlrAtual,
                ...tempDado
            };
        }

        function updatePrecoProduto(dadoCalculator,valor,icmsBase){
            let itensPropostaCopy=[...refItensProposta.current];
            let itemPed=itensPropostaCopy.find(function(item, i){
                if(item.idx === dadoCalculator.idx){
                    return item;
                }
            })
            if(valor!=0)
                itemPed.precoManual={percentual:valor,icmsBase:icmsBase};
            else
                itemPed.precoManual={};

            setItensProposta(itensPropostaCopy)
        }
    
        function getItemModalCalculatorPreco(){
            let vlrAtual='';
            if(itemPed.precoManual.icmsBase!==undefined){
                vlrAtual=itemPed.precoManual;
            }
            return {
                valorAtual:vlrAtual,
                ...tempDado
            };
        }
        
        if(temItens===false) return;
        return (
            <Card border="light" >
                <Card.Body className="">
                    <div className="btn-action" /*onClick={()=>{listaClicked(dado.id)}}*/>
                        <Row>
                            {
                                itemPed.editar===true
                                ?<Col className={"col-1 d-flex"} style={{justifyContent: "center", alignItems: "center"}}>
                                    <Form.Label style={{fontWeight: "bold"}}>{parseInt(itemPed.idx)+1}</Form.Label>
                                </Col>
                                :""
                            }
                            <Col>
                                {
                                    itemPed.editar===true
                                    ?<Row>
                                        <Col className={"col-9"}>
                                            {
                                                descs.map(desc =>{
                                                    return (
                                                    <Row>
                                                    <Col>
                                                    <Form.Label style={{fontWeight: "bold"}}>{desc}</Form.Label>
                                                    </Col>
                                                    </Row>
                                                    );
                                                })
                                            }
                                        </Col>
                                        <Col className={"col-2"}>
                                            <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Quantidade:</Form.Label>
                                            <Form.Label style={{fontWeight: "bold"}}>{itemPed.quantidade.toLocaleString(undefined, {maximumFractionDigits:decimals})}</Form.Label>
                                        </Col>
                                    </Row>
                                    :<Row style={{justifyContent: "center"}}>
                                        <Col className="col-3 col-md-2">
                                            <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Quantidade:</Form.Label>
                                            <Form.Label style={{fontWeight: "bold"}}>{itemPed.quantidade.toLocaleString(undefined, {maximumFractionDigits:decimals})}</Form.Label>
                                            <BtnModalCalculatorFormacaoPreco ref={btnQuantidade} callback={updateQuantidadeProduto} getItem={getItemModalCalculatorQuantidade} tipo="Quantidade"/>
                                        </Col>
                                        {
                                            temDesconto===false && itemPed.percentualDesconto==="" && itemPed.precoManual.icmsBase===undefined
                                            ?<Col className="col-3 col-md-2">
                                                <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Overprice:</Form.Label>
                                                {
                                                    itemPed.percentualOverprice!==undefined && itemPed.percentualOverprice!=="" && parseFloat(itemPed.percentualOverprice)>0
                                                    ?<>
                                                        <Form.Label style={{fontWeight: "bold"}}>{itemPed.percentualOverprice.toLocaleString(undefined, {maximumFractionDigits:decimals})}</Form.Label>
                                                        <Form.Label style={{fontSize:"11px"}}>%</Form.Label>
                                                    </>
                                                    :""
                                                }
                                                <BtnModalCalculatorFormacaoPreco callback={updateOverpriceProduto} getItem={getItemModalCalculatorOverprice} tipo="Overprice"/>
                                            </Col>
                                            :""
                                        }
                                        {
                                            temDesconto===false && itemPed.percentualOverprice==="" && itemPed.precoManual.icmsBase===undefined
                                            ?<Col className="col-4 col-md-3">
                                                <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Desc. Máx.:</Form.Label>
                                                
                                                <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{percMaximo.toLocaleString(undefined, {maximumFractionDigits:decimals})}
                                                    <Form.Label style={{fontSize:"11px"}}>%</Form.Label>
                                                    {
                                                        itemPed.percentualDesconto!==undefined && itemPed.percentualDesconto!=="" && parseFloat(itemPed.percentualDesconto)>0
                                                        ?<>
                                                            <Form.Label style={{paddingLeft: "5px"}}>(</Form.Label>
                                                            <Form.Label style={{fontWeight: "bold"}}>{itemPed.percentualDesconto.toLocaleString(undefined, {maximumFractionDigits:decimals})}</Form.Label>
                                                            <Form.Label style={{fontSize:"11px"}}>%</Form.Label>
                                                            <Form.Label >)</Form.Label>
                                                        </>
                                                        :""
                                                    }
                                                </Form.Label>
                                                {
                                                    descMaximo>0.01
                                                    ?<BtnModalCalculatorFormacaoPreco callback={updateDescontoProduto} getItem={getItemModalCalculatorDesconto} tipo="Desconto"/>
                                                    :""
                                                }
                                                    
                                            </Col>
                                            :""
                                        }
                                        {
                                            temDesconto===false && itemPed.percentualDesconto==="" && itemPed.percentualOverprice===""
                                            ?<Col className="col-4 col-md-3">
                                                <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Preço:</Form.Label>
                                                {
                                                    itemPed.precoManual.icmsBase!==undefined
                                                    ?<FontAwesomeIcon icon={faCheck}/>
                                                    :""
                                                }
                                                {
                                                    descMaximo>0.01
                                                    ?<BtnModalCalculatorFormacaoPreco callback={updatePrecoProduto} getItem={getItemModalCalculatorPreco} tipo="Manual"/>
                                                    :""
                                                }
                                                    
                                            </Col>
                                            :""
                                        }
                                    </Row>
                                }
                                {
                                    itemPed.editar===false
                                    ?<>
                                    <Row>
                                        {
                                            tempDado.precos.map(p => <DadosPreco key={tempDado.idx+"_"+p.id} preco={p} dado={tempDado}/>)
                                        }
                                    </Row>
                                    <hr/>
                                    </>
                                    :""
                                }
                                <Row>
                                {
                                    tempDadoCalc.precos.map(p => <DadosPreco key={tempDadoCalc.idx+"_"+p.id+"_"+p.id} preco={p} dado={tempDadoCalc}/>)
                                }
                                </Row>
                            </Col>
                            {
                                itemPed.editar===true 
                                ?<>
                                <Button onClick={()=>{
                                    setCurrentIdx(itemPed.idx);
                                    setShowItens(false);
                                }} style={{width:"10px", heigth:"100%", marginRight: "11px", justifyContent: "center", display: "flex", alignItems: "center"}}><FontAwesomeIcon icon={faPencil} /></Button>
                                <Button onClick={()=>{removeItem(itemPed.idx)}} style={{width:"10px", heigth:"100%", marginRight: "11px", justifyContent: "center", display: "flex", alignItems: "center"}}><FontAwesomeIcon icon={faTrash} /></Button>
                                </>
                                :""
                            }
                        </Row>
                    </div>
                </Card.Body>
            </Card>
        );
    };

    const BtnCodsEspeciais = () => {
        let plg=btnCodsEspeciaisVisible.plg;
        let blob=btnCodsEspeciaisVisible.blob;
        let dub=btnCodsEspeciaisVisible.dublagem;
        let ben=btnCodsEspeciaisVisible.beneficiamento;
        if(currentIdx!==initialIdx){
            let itemPed=getCurrentItem();
            itemPed.dadosItem.forEach(dado =>{
                if(blob===true && dado.cod_produto.toString()===codigosEspeciais.blob){
                    blob=false;
                }else if(dub===true && dado.cod_produto.toString()===codigosEspeciais.dublagem){
                    dub=false;
                }else if(plg===true && dado.cod_produto.toString()===codigosEspeciais.plg){
                    plg=false;
                }else if(ben===true && dado.cod_produto===0){
                    ben=false;
                }
            });
        }
        return (
            <Row >
                <Col className="mb-2 d-flex justify-content-center">
                    {
                        plg
                        ?
                            <Button onClick={() => 
                                {
                                    buscaProdutos(codigosEspeciais.plg);
                                }
                            }>
                                + PLG
                            </Button>
                        :""
                    }
                    {
                        blob
                        ?
                        <Button style={{marginLeft:'10px'}} onClick={() => 
                            {
                                buscaProdutos(codigosEspeciais.blob);
                            }
                        }>
                            + BLOB
                        </Button>
                        :""
                    }
                    {
                        dub
                        ?
                        <Button style={{marginLeft:'10px'}} onClick={() => 
                            {
                                buscaProdutos(codigosEspeciais.dublagem);
                            }
                        }>
                            + DUBLAGEM SEM TECIDO
                        </Button>
                        :""
                    }
                    {
                        ben
                        ?
                        <Button style={{marginLeft:'10px'}} onClick={() => 
                            {
                                clickedRowProd({cod_produto:0,
                                                descricao:'BENEFICIAMENTO '+tipoProduto.text
                                            })
                            }
                        }>
                            + BENEFICIAMENTO
                        </Button>
                        :""
                    }
                </Col>
            </Row>
        );
    }

    async function icmsFinalizacaoSelected (e) {
        //let tempDadosProposta={...dadosProposta,icms:{value:e.target.value,text:e.target.getAttribute('text')}};
        let tempDadosProposta={...dadosProposta,icms:{value:e.value,text:e.text}};
        setDadosProposta(tempDadosProposta);

        const file = await pdf(<Proposta itensProposta={itensProposta} textosProposta={textosProposta} dadosProposta={tempDadosProposta} tipoProduto={tipoProduto}/>).toBlob();

        var a = document.createElement("a");
        document.body.appendChild(a);

        const fileURL = URL.createObjectURL(file);
        /*const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;*/
        a.href = fileURL;
        a.download = moment().format('YYYYMMD')+"_"+dadosProposta.cliente.razao_social;
        a.click();
        a.remove()

        //getPdfBlob(itensProposta,textosProposta,tempDadosProposta,tipoProduto);

        //ReactDOM.render(<Proposta textosProposta={textosProposta}/>, document.getElementById('root'));
        /*const root=ReactDOM.createRoot(document.getElementById('root'));
        root.render(<PropostaViewer itensProposta={itensProposta} textosProposta={textosProposta} dadosProposta={tempDadosProposta} tipoProduto={tipoProduto}/>)*/
        /*<BlobProvider document={<Proposta itensProposta={itensProposta} textosProposta={textosProposta} dadosProposta={tempDadosProposta} tipoProduto={tipoProduto}/>}>
            {({ url}) => {
                console.log(url)
                const  link = document.createElement('a');
                link.href = url;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }}
        </BlobProvider>*/
        setFinalizou(true);
    }

    return (
        <>
        <SideBar/>
        {
            showModalProds?
            <React.Fragment>
            <Modal as={Modal.Dialog} show scrollable={true}>
                <Modal.Header>
                <Modal.Title className="h6">Seleção de produtos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        prods.map(d => <BtnActionProd key={`${d.id}`} {...d}/>)
                    }
                </Modal.Body>
                <Modal.Footer>
                <Button onClick={handleCloseModalProds}>Sair</Button>
                </Modal.Footer>
            </Modal>
            </React.Fragment>
            :""
        }
        <SelecaoValoresFormacaoPreco ref={refModalFinalizacao} precos={textosProposta.precos} callback={icmsFinalizacaoSelected}/>
        <main className="content">
            {
                online ?null :<div className="alert alert-warning" role="alert">Você está offline</div>
            }
            {
                sincronizando ?<div className="alert alert-warning" role="alert">Sincronizando</div> :null
            }
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block">
                    {
                        tipoProduto.key===""
                        ?<h4>Formação de preço</h4>
                        :""
                    }
                    {
                        tipoProduto.key!=="" && showItens
                        ?<h4>Proposta</h4>
                        :""
                    }
                    {
                        tipoProduto.key!=="" && showItens===false
                        ?<h4>{tipoProduto.text}</h4>
                        :""
                    }
                </div>
                {
                    tipoProduto.key!=="" && showItens===false ?
                    <Button onClick={btnPropostaClicked}>
                        Proposta
                    </Button>
                    :""
                }
                {
                    showItens ?
                        <>
                        {
                            /*currentIdx!==initialIdx && getCurrentItem().dadosItem.length
                            ?
                                <Button onClick={() => {
                                    setShowItens(false);
                                    setShowFilter(false);}}>
                                    Item
                                </Button>
                            :*/
                                <Button onClick={() => {
                                    setShowItens(false);
                                    }}>
                                    Adicionar Produto Formulado
                                </Button>
                        }
                        </>
                    :""
                }
            </div>
            {
                tipoProduto.key!=="" && showItens===false ?
                <BtnCodsEspeciais/>
                :""
            }
            {
                tipoProduto.key===""
                ?
                <>
                    <Row>
                        <Col md={12} className="mb-3 d-flex justify-content-center">
                            <Button className="col-md-3 col-12" onClick={() => setTipoProduto({key: "tecido", text: "TECIDO"})}>Tecido</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className="mb-3 d-flex justify-content-center">
                            <Button className="col-md-3 col-12" onClick={() => setTipoProduto({key: "mestico", text: "MESTIÇO"})}>Couro Mestiço</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className="mb-3 d-flex justify-content-center">
                            <Button className="col-md-3 col-12" onClick={() => setTipoProduto({key: "vacum", text: "VACUM"})}>Couro Vacum</Button>
                        </Col>
                    </Row>
                </>

                :
                <>
                {
                    showItens
                    ?
                    <>
                        {
                            itensProposta.map(d => <DadosItensComposicao key={`${d.idx}`} editar={true} {...d}/>)
                        }
                        <Row style={{marginTop: "10px"}}>
                            <Col className="d-flex justify-content-end">
                                <Button className="mb-2 col-md-3 col-12" onClick={() => {
                                    setShowItens(false);
                                    }}>
                                    Adicionar Produto Formulado
                                </Button>
                            </Col>
                        </Row>
                        <Card border="light">
                            <Card.Body className="">
                                {
                                    dadosProposta.cliente.razao_social===undefined?
                                    <FiltroCliente callback={clienteSelected} />
                                    :
                                    <Row className="mb-3">
                                        <Col md={11} className="col-10">
                                            <small>{dadosProposta.cliente.cpfcnpj+" - "}</small>
                                            <Form.Label style={{fontWeight: "bold"}}>{dadosProposta.cliente.codigo+" - "+dadosProposta.cliente.razao_social}</Form.Label>
                                        </Col>
                                        <Col md={1} className="col-2">
                                            <Button style={{width:"10px",  justifyContent: "center", display: "flex", alignItems: "center"}} onClick={()=>{setDadosProposta({...dadosProposta,cliente:{}})}} ><FontAwesomeIcon icon={faTrash} /></Button>
                                        </Col>
                                    </Row>
                                }
                                <Row>
                                    <Col>
                                        <ToggleButton
                                            id="btnImpDesconto"
                                            type="checkbox"
                                            variant=""
                                            name="impdesconto"
                                            checked={dadosProposta.imprimirdesconto}
                                            onChange={(e)=>{setDadosProposta({...dadosProposta,imprimirdesconto:e.target.checked})}}
                                        >
                                            Imprimir Desconto
                                        </ToggleButton>
                                    </Col>
                                </Row>
                                {/*
                                <Row >
                                    <Col md={3} className="mb-3">
                                        <FloatingLabel label="Código Lista">
                                            <Form.Control type="text" autoComplete="off" inputMode="numeric" placeholder="" name="cod_produto" onChange={(e)=>{
                                                                                                                                                                let value=e.target.value;
                                                                                                                                                                const re = /^[0-9/\b]+$/;
                                                                                                                                                                if (value === '' || re.test(value)) {
                                                                                                                                                                    setDadosProposta({...dadosProposta,cod_lista:e.target.value})
                                                                                                                                                                }
                                                                                                                                                                }} value={dadosProposta.cod_lista}/>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={3} className="mb-3">
                                        <FloatingLabel label="Tubete">
                                            <Form.Select value={dadosProposta.tubete.id} onChange={(e)=>{setDadosProposta({...dadosProposta,tubete:tubetes[e.target.value]})}}>
                                                {
                                                    tubetes.map(t =>{
                                                        return (
                                                            <option value={t.id}>{t.codigo+" "+t.descricao}</option>
                                                        )
                                                    })
                                                }
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                </Row>*/}
                            </Card.Body>
                        </Card>
                        <Row className="mb-2" style={{marginTop: "10px", justifyContent:'space-between'}} >
                            <Col className="d-flex justify-content-begin">
                                {finalizou
                                ?<Button onClick={() => {
                                    voltar();
                                }}>
                                    Sair
                                </Button>
                                :<Button onClick={() => {
                                    Swal.fire({
                                        title: 'Cancelar Proposta?',
                                        text: "Você perderá as informações preenchidas",
                                        icon: 'warning',
                                        showCancelButton: true,
                                        confirmButtonColor: '#3085d6',
                                        cancelButtonColor: '#d33',
                                        confirmButtonText: 'Sim',
                                        cancelButtonText: 'Não'
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            voltar();
                                        }
                                    })
                                }}>
                                    Cancelar Proposta
                                </Button>
                                }
                            </Col>
                            <Col className="d-flex justify-content-end">
                                <Button onClick={() => {
                                    let msgErro="";
                                    if(dadosProposta.cliente.razao_social===undefined)
                                        msgErro="Favor informar o cliente";
                                    /*else if(dadosProposta.cod_lista.length===0)
                                        msgErro="Favor informar o Código da Lista";*/
                                    /*else if(dadosProposta.tubete===0)
                                        msgErro="Favor informar o Tubete";*/

                                    if(msgErro.length>0){
                                        confirmAlert({
                                            title: "Aviso:",
                                            message: msgErro,
                                            buttons: [
                                                {
                                                    label: 'Ok',
                                                }
                                                ]
                                        })
                                        return;
                                    }
                                    refModalFinalizacao.current.open();
                                    }}>
                                        Finalizar Proposta
                                    </Button>
                            </Col>
                        </Row>
                    </>
                    :
                    <>
                        <Card className="mb-1">
                            <Card.Body style={{marginRight: "10px"}}>
                                <Row>
                                    <Col >
                                        <FloatingLabel label="Código produto">
                                            <Form.Control type="text" autoComplete="off" inputMode="numeric" placeholder="" name="cod_produto" onChange={onChanged} value={filters.cod_produto} onKeyUp={(e)=>{if(e.key==="Enter") buscaProdutos()}}/>
                                        </FloatingLabel>
                                    </Col>
                                    <Col >
                                        <FloatingLabel  label="Descrição Produto">
                                            <Form.Control type="text" autoComplete="off" placeholder="" name="descricao" onChange={onChangedList} value={filters.descricao} onKeyUp={(e)=>{if(e.key==="Enter") buscaProdutos()}}/>
                                        </FloatingLabel>
                                    </Col>
                                    <Button style={{width:"10px",  justifyContent: "center", display: "flex", alignItems: "center"}} onClick={(e)=>{buscaProdutos()}}><FontAwesomeIcon icon={faMagnifyingGlass} /></Button>
                                </Row>
                            </Card.Body>
                        </Card>
                        {
                            currentIdx!==initialIdx
                            ?
                                <>
                                    <DadosItensComposicao key={`${getCurrentItem().idx+"_"}`} editar={false} {...getCurrentItem()}/>
                                    <hr/>
                                </>
                            :""
                        }
                        <Card border="light">
                            <Card.Body className="">
                                {
                                    currentIdx!==initialIdx
                                    ?
                                    getCurrentItem().dadosItem.map(d => <DadosProdutos key={`${d.id}`} {...d}/>)
                                    :""
                                }
                            </Card.Body>
                        </Card>
                        <Row className="mb-2" style={{marginTop: "10px", justifyContent:'space-between'}} >
                            <Col>
                            <Button onClick={() => {
                                Swal.fire({
                                    title: 'Cancelar Proposta?',
                                    text: "Você perderá as informações preenchidas",
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Sim',
                                    cancelButtonText: 'Não'
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        voltar();
                                    }
                                })
                            }}>
                                Cancelar Proposta
                            </Button>
                            </Col>
                        </Row>

                    </>
                }
                </>
            }
        </main>
        </>
    );
}
