import React, { useState,useEffect,useMemo } from 'react';
import SideBar from '../Components/SideBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEllipsisH, faTrashAlt  } from '@fortawesome/free-solid-svg-icons';
import { Button, ButtonGroup,  Dropdown, Nav, Card, Table, Modal, Form, Row, Col, FloatingLabel } from 'react-bootstrap';
import api from '../api.js';
import { TableHeader, Pagination, Search } from "../Components/DataTable";
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css';
import useAuth from '../Context/hooks/UseAuth';
import { find } from "lodash";


function initialState(){
  return {id:0, codigo:'', senha:'', nivel:"0", ativo:1};
}

export default function User(){

    const [usuarios, setUsuarios] = useState([]);
    const [totalItems,setTotalItems] = useState(0);
    const [currentPage,setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [showDefault, setShowDefault] = useState(false);
    const [usuarioEdicao,setUsuarioEdicao] = useState(initialState);

    const { online, sincronizando } = useAuth();

    const ITEMS_PER_PAGE=50;

    const headers = [
        { name: "Ação", field: "", sortable: false },
        { name: "Nome", field: "nome", sortable: true },
        { name: "Status", field: "ativo", sortable: true },
        { name: "Nível", field: "nivel", sortable: false },
        { name: "Cód. Vend.", field: "cod_vendedor", sortable: false },
    ];

    
    const loadData = async () =>{
      const response = await api.get("/usuarios", {
        headers: {
          "Cache-Control": "no-cache",
          "Pragma": "no-cache"
        }
      });
      
        const usuarios = response.data.response.usuarios;
        setUsuarios(usuarios);
    }

    useEffect(()=>{
        loadData();
    },[]);

    const usuariosData = useMemo( ()=>{
        let computedUsuario=usuarios;

        if (search) {
            computedUsuario = computedUsuario.filter(
                usuario =>
                    usuario.codigo.toLowerCase().includes(search.toLowerCase()) ||
                    usuario.nome.toLowerCase().includes(search.toLowerCase())
            );
        }

        setTotalItems(computedUsuario.length);

        //Sorting usuarios
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedUsuario = computedUsuario.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        return computedUsuario.slice(
            (currentPage -1)*ITEMS_PER_PAGE,
            (currentPage -1)*ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    },[usuarios, currentPage, search, sorting])

    const handleRemoveUser = async (id) =>{
        try{
            await api.delete("/usuarios/"+id);
            loadData()
        }catch(err){
            confirmAlert({
                title: "Erro:",
                message: err.response.data ?err.response.data.error :err.message,
                buttons: [
                    {
                      label: 'Ok',
                    }
                  ]
            })
        }
    }

    const removeUser = (usuario) => {
        confirmAlert({
            title: "Confirma excluir?",
            message: "Usuário "+usuario.codigo+" - "+usuario.nome,
            buttons: [
                {
                  label: 'Sim',
                  onClick: () => handleRemoveUser(usuario.id)
                },
                {
                  label: 'Não'
                }
              ]
        })
    }

    const editUser = (usuario) =>{
      if(usuario!==undefined){
        setUsuarioEdicao(usuario);
      }
      setShowDefault(true)
    }

    const TableRow = (props) => {
        const usuario=props;
        //const { id, codigo, nome, nivel } = props;
        return (
          <tr>
            <td>
              <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                  <span className="icon icon-sm">
                    <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => editUser(usuario)}>
                    <FontAwesomeIcon icon={faEdit} className="me-2" /> Editar
                  </Dropdown.Item>
                  <Dropdown.Item className="text-danger" onClick={() => removeUser(usuario)}>
                    <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remover
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
            <td>
              <span className="fw-normal">
                {usuario.nome}
              </span>
            </td>
            <td>
              <span className="fw-normal">
                {usuario.ativo === 1 ? "Ativo" : "Inativo"}
              </span>
            </td>
            <td>
              <span className="fw-normal">
                {usuario.nivel === 1 ? "Admin" : "Usuário"}
              </span>
            </td>
            <td>
              <span className="fw-normal">
                {usuario.cod_vendedor}
              </span>
            </td>
          </tr>
        );
      };

      const handleCloseModal = () => {
        setShowDefault(false);
        setUsuarioEdicao(initialState)
      }

      const handleSaveModal = async (e) => {
        e.preventDefault();
        try{
          const loginExists = find(usuarios, usuario => usuario.codigo === usuarioEdicao.codigo)

          if(loginExists && !usuarioEdicao.id){
            confirmAlert({
                title: "Aviso:",
                message: "Login de Usuário já Existente",
                buttons: [
                    {
                        label: 'Ok',
                    }
                ]
            })
            return
        }

          const usuarioEdicaoModificado = {
            ...usuarioEdicao,
          }

          if(usuarioEdicao.id){

            await api.patch("/usuarios",
              JSON.stringify(usuarioEdicaoModificado),
              {
                headers: {
                  "Cache-Control": "no-cache",
                  "Pragma": "no-cache",
                  'Content-Type': 'application/json',
                }
              }
            );
          }else{
            await api.post("/usuarios",
              JSON.stringify(usuarioEdicaoModificado),
              {
                headers: {
                  "Cache-Control": "no-cache",
                  "Pragma": "no-cache",
                  'Content-Type': 'application/json',
                }
              }
            );
          }
          setUsuarioEdicao(initialState);
          setShowDefault(false);
          loadData();
        }catch(err){
          confirmAlert({
            title: "Erro:",
            message: err.response.data ?err.response.data.error :err.message,
            buttons: [
                {
                  label: 'Ok',
                }
              ]
        })
        }
      } 

      function onChanged(e){
        const {value,name} = e.target;
    
        setUsuarioEdicao({
          ...usuarioEdicao,
          [name]: value
        })
      }

    return (
        <>
        <SideBar />
      
        <React.Fragment>
          <Modal as={Modal.Dialog} centered show={showDefault}>
            <Modal.Header>
              <Modal.Title className="h6">Cadastro de Usuários</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form id="form-user" onSubmit={handleSaveModal}>
            <Row>
            <Col md={6} className="mb-3">
                  <Form.Group>
                  <FloatingLabel label="Nome do Usuário">
                      <Form.Control autoFocus required type="text" name="codigo" placeholder="" onChange={onChanged} value={usuarioEdicao.codigo} />
                    </FloatingLabel>
                </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group>
                    <FloatingLabel label="Código Vendedor">
                      <Form.Control type="text" name="cod_vendedor" placeholder="" onChange={onChanged} value={usuarioEdicao.cod_vendedor} />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col md={8} className="mb-3">
                  <Form.Group>
                  <FloatingLabel label="Nome">
                      <Form.Control required type="text" name="nome" placeholder="" onChange={onChanged} value={usuarioEdicao.nome}/>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col md={4} className="mb-3">
                  <Form.Group>
                      <FloatingLabel label="Status">
                        <Form.Select name="ativo" onChange={onChanged} value={usuarioEdicao.ativo}>
                          <option value="1">Ativo</option>
                          <option value="0">Inativo</option>
                        </Form.Select>
                      </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Group>
                  <FloatingLabel label="Senha">
                    {usuarioEdicao.id
                    ?<Form.Control type="password" name="senha" placeholder="" onChange={onChanged} value={usuarioEdicao.senha}/>
                    :<Form.Control required type="password" name="senha" placeholder="" onChange={onChanged} value={usuarioEdicao.senha}/>
                    }
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group>
                    <FloatingLabel label="Nível">
                      <Form.Select name="nivel" onChange={onChanged} value={usuarioEdicao.nivel}>
                        <option value="0">Usuário</option>
                        <option value="1">Administrador</option>
                      </Form.Select>
                    </FloatingLabel>
                </Form.Group>
                </Col>

              </Row>
            </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleCloseModal}>
                Sair
            </Button>
              <Button form="form-user" type="submit" className="ms-auto">
                Salvar
            </Button>
            </Modal.Footer>
          </Modal>
        </React.Fragment>

        <main className="content">
          {
                online ?null :<div className="alert alert-warning" role="alert">Você está offline</div>
            }
            {
                sincronizando ?<div className="alert alert-warning" role="alert">Sincronizando</div> :null
            }
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block">
                    <h4>Usuários</h4>
                </div>
            </div>
            <div className="d-flex justify-content-between" style={{paddingBottom: "10px"}}>
                <Search
                        onSearch={value => {
                            setSearch(value);
                            setCurrentPage(1);
                        }}
                    />
                <Button className="" onClick={() => editUser()}>Novo</Button>
            </div>
            <Card border="light" className="table-wrapper table-responsive shadow-sm">
                <Card.Body className="">
                    <Table hover striped className="user-table align-items-center">
                        <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
                    <tbody>
                        {
                            usuariosData.map(t => <TableRow key={`${t.id}`} {...t} />)
                        }
                    </tbody>
                    </Table>
                    <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
                    <Nav>
                        <Pagination className="mb-2 mb-lg-0"
                            total={totalItems}
                            itemsPerPage={ITEMS_PER_PAGE}
                            currentPage={currentPage}
                            onPageChanged= { page => setCurrentPage(page)}
                            />
                    </Nav>
                    </Card.Footer>
                </Card.Body>
            </Card>
        </main>
        </>
    )
}

