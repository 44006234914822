import React,{useState,useEffect,useRef,forwardRef, useImperativeHandle} from 'react';
import useAuth from '../Context/hooks/UseAuth';
import { Button, Card,  Modal, Form, Row, Col,FloatingLabel, ToggleButton } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator, faTrash, faPencil, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import {confirmAlert} from 'react-confirm-alert'

var decimals = 2;

const BtnModalCalculatorPedido = forwardRef((props, ref) => {
    let callback = props.callback;
    let getItem = props.getItem;
    let tipo = props.tipo;

    const [valor, setValor] = useState('');
    const [showModalCalculator,setShowModalCalculator] = useState(false);
    const [dadoCalculator, setDadoCalculator] = useState({});
    const { ceilValue,decimals } = useAuth();


    const refShowModalCalculator = useRef(showModalCalculator);
    const refDadoCalculator = useRef(dadoCalculator);
    const refValor = useRef(valor);


    useImperativeHandle(ref, () => {
        return {
            click() {
                openModal();
            }
        };
    }, []);

    useEffect(() => {
        refDadoCalculator.current = dadoCalculator;
    }, [dadoCalculator]);


    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.key === 'Escape') {
              event.preventDefault();
              if(refShowModalCalculator.current===true){
                handleCloseModalCalculator();
              }
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
          document.removeEventListener('keydown', keyDownHandler);
        };
    },[]);

    useEffect(() => {
        refShowModalCalculator.current = showModalCalculator;
      }, [showModalCalculator]);

    useEffect(() => {
        refValor.current = valor;
    }, [valor]);

    function onChangedCalculator(e){
        const {value} = e.target;
        const re = /^[0-9,/\b]+$/;
        if(value === ''){
            setValor('');
        }else if (re.test(value)) {
            if(tipo==="Desconto"){
                if(parseFloat(value.replaceAll('.','').replaceAll(',','.'))<=dadoCalculator.desconto_maximo){
                    setValor(value);
                }
            }else
                setValor(value);
        }
    }

    const handleCloseModalCalculator = () => {
        setValor('')
        setShowModalCalculator(false);
    }

    function roundValue(value, decimals = 2) {
        const factor = Math.pow(10, decimals);
        return Math.round(value * factor) / factor;
    }
    
    function getValorCalculado() {
        if (tipo === "Quantidade") {
            return valor !== '' 
                ? roundValue(dadoCalculator.preco * parseFloat(valor.replaceAll('.', '').replaceAll(',', '.')), decimals).toLocaleString(undefined, { minimumFractionDigits: decimals, maximumFractionDigits: decimals })
                : roundValue(dadoCalculator.preco, decimals).toLocaleString(undefined, { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
        } else if (tipo === "Overprice") {
            return valor !== '' 
                ? roundValue(dadoCalculator.preco + (dadoCalculator.preco * (parseFloat(valor.replaceAll('.', '').replaceAll(',', '.')) / 100.0)), decimals).toLocaleString(undefined, { minimumFractionDigits: decimals, maximumFractionDigits: decimals })
                : roundValue(dadoCalculator.preco, decimals).toLocaleString(undefined, { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
        } else if (tipo === "Desconto") {
            return valor !== '' 
                ? roundValue(dadoCalculator.preco - (dadoCalculator.preco * (parseFloat(valor.replaceAll('.', '').replaceAll(',', '.')) / 100.0)), decimals).toLocaleString(undefined, { minimumFractionDigits: decimals, maximumFractionDigits: decimals })
                : roundValue(dadoCalculator.preco, decimals).toLocaleString(undefined, { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
        } else if (tipo === "Manual") {
            return valor !== '' 
                ? parseFloat(valor.replaceAll('.', '').replaceAll(',', '.')).toLocaleString(undefined, { minimumFractionDigits: decimals, maximumFractionDigits: decimals })
                : roundValue(dadoCalculator.preco, decimals).toLocaleString(undefined, { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
        }
    }

    function confirmar(){
        let v=refValor.current;
        if(tipo==="Manual" && v!=="0"){
            let vf=parseFloat(valor.replaceAll('.','').replaceAll(',','.'));
            if(vf<dadoCalculator.preco){
                if((((dadoCalculator.preco-vf)/dadoCalculator.preco)*100)>dadoCalculator.desconto_maximo){
                    confirmAlert({
                        title: "Aviso:",
                        message: "O Preço informado é inferior ao desconto permitido, favor ajustar",
                        buttons: [
                            {
                                label: 'Ok',
                            }
                        ]
                    })
                    return
                }
            }else if(vf===dadoCalculator.preco){
                v='';
            }
        }
        if(v==="0")
            v='';
        setShowModalCalculator(false);
        callback(refDadoCalculator.current,v)
    }

    function openModal(){
        let item=getItem();
        setValor(item.valorAtual);
        setDadoCalculator(item);
        setShowModalCalculator(true);
    }

    return (
        <>
        {
            showModalCalculator?
            <React.Fragment>
            <Modal as={Modal.Dialog} show>
                <Modal.Header>
                <Modal.Title className="h6">Calcular {tipo}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mb-2">
                        <Col>
                            <Form.Label style={{paddingRight: "5px"}}>Preço Atual: R$ </Form.Label>
                            <Form.Label style={{paddingRight: "5px"}}>{dadoCalculator.preco.toLocaleString(undefined, {minimunFractionDigits:decimals,maximumFractionDigits:decimals})}</Form.Label>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Form.Label style={{paddingRight: "5px"}}>Preço {tipo}: R$ </Form.Label>
                            <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{getValorCalculado()}</Form.Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FloatingLabel label={tipo}>
                                <Form.Control autoFocus type="text" autoComplete="off" inputMode="numeric" placeholder="" onChange={onChangedCalculator} value={valor} onKeyUp={(e)=>{if(e.key==="Enter" && refShowModalCalculator.current===true) confirmar()}}/>
                            </FloatingLabel>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer style={{justifyContent:'space+between'}}>
                    <Button onClick={handleCloseModalCalculator}>Sair</Button>
                    <Button onClick={confirmar}>Confirmar</Button>
                </Modal.Footer>
            </Modal>
            </React.Fragment>
            :""
        }
        <Button variant="link" onClick={openModal} style={{padding:"0px",paddingLeft: "5px",color:"var(--cinzaPalagi)"}}><FontAwesomeIcon icon={faCalculator}/></Button>
        </>
    )
});

export default BtnModalCalculatorPedido;
