import React,{useState,useEffect}  from 'react';
import SideBar from '../Components/SideBar';
import { UploadProcess } from '../Components/UploadProcess';
import api from '../api';
import useAuth from '../Context/hooks/UseAuth';
import { orderBy, map } from "lodash";


export default function Upload(){

    const [ultimosUploads,setUltimosUploads]=useState([]);
    const [loaded,setLoaded]=useState(false);

    const { online, sincronizando } = useAuth();

    const buscaHistorico = async (e) => {
        try{
            const response = await api.get("/files/historico",
            {
                headers: {
                    'Content-Type': 'application/json',
                    "Cache-Control": "no-cache",
                    "Pragma": "no-cache"
                  }
            }
            );

            const uploadsOrdenados = orderBy(response?.data, ['ultimoenvio'], ['desc']);
            setUltimosUploads(uploadsOrdenados);
            setLoaded(true);
        }catch(err){
            setLoaded(true);
        }
    }

    useEffect(() => {
        buscaHistorico();
    },[]);


    function getDestinoAndName(tipo) {
        switch (tipo) {
            case 0:
                return { destino: "precos", name: "Preços" };
            case 1:
                return { destino: "estoque", name: "Estoque" };
            case 2:
                return { destino: "orcamento", name: "Orçamento" };
            case 3:
                return { destino: "tecido", name: "Tecido" };
            case 4:
                return { destino: "mestico", name: "Mestiço" };
            case 5:
                return { destino: "vacum", name: "Vacum" };
            case 6:
                return { destino: "clientes", name: "Clientes" };
            case 7:
                return { destino: "bases", name: "Bases" };
            case 8:
                return { destino: "vendas", name: "Vendas" };
            case 9:
                return { destino: "reservas", name: "Reservas" };
            case 10:
                return { destino: "beneficiamentos", name: "Beneficiamentos" };
            case 11:
                return { destino: "transito", name: "Em Trânsito" };
            default:
                return { destino: "", name: "" };
        }
    }

    


    return (
        <>
        <SideBar/>
        <main className="content">
            {
                online ?null :<div className="alert alert-warning" role="alert">Você está offline</div>
            }
            {
                sincronizando ?<div className="alert alert-warning" role="alert">Sincronizando</div> :null
            }
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block">
                    <h4>Upload de arquivos</h4>
                </div>
            </div>
                <>
                {ultimosUploads.map(upload => {
                    const { destino, name } = getDestinoAndName(upload.tipo);
                    loaded && (
                        <>
                            {map(ultimosUploads,upload => {
                                const { destino, name } = getDestinoAndName(upload.tipo);
                                return (
                                    <UploadProcess
                                        key={upload.tipo}
                                        destino={destino}
                                        name={name}
                                        tipo={upload.tipo}
                                        ultimosUploads={ultimosUploads}
                                    />
                                );
                            })}
                        </>
                    )
                    return (
                        <UploadProcess
                            key={upload.tipo}
                            destino={destino}
                            name={name}
                            tipo={upload.tipo}
                            ultimosUploads={ultimosUploads}
                        />
                    );
                })}
            </>
        </main>
        </>
    )
}
