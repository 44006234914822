import React,{useState,useEffect,useRef} from 'react';
import SideBar from '../Components/SideBar';
import FiltroCliente from '../Components/FiltroCliente'; 
import { Button, Modal, Form, Row, Col,FloatingLabel, ToggleButton, ButtonGroup, Card } from 'react-bootstrap';
import api from '../api';
import useAuth from '../Context/hooks/UseAuth';
import {confirmAlert} from 'react-confirm-alert'
import Enumerable from 'linq'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";



export default function ConsultaBeneficiamentos(){
    const { online,setOnline, auth, loadOfflineData, sincronizando, ceilValue,decimals } = useAuth();
    const [beneficiamentos, setBeneficiamentos] = useState([]);
    const [itemSelected, setItemSelected] = useState(0);
    const [dadoFilterData,setDadoFilterData] = useState('');
    const [zoomFilter,setZoomFilter] = useState({});

    const refBeneficiamentos = useRef(beneficiamentos);
    const refZoomFilter = useRef(zoomFilter);
    const refFilterData = useRef();




    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.key === 'Escape') {
              event.preventDefault();
              if(refBeneficiamentos.current.length!==0){
                voltar();
              }
            }
          };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
          document.removeEventListener('keydown', keyDownHandler);
        };
    },[]);

    useEffect(()=>{
        if(refFilterData.current)
            refFilterData.current.value=dadoFilterData;
    },[dadoFilterData])

    useEffect(() => {
        refBeneficiamentos.current = beneficiamentos;
        refZoomFilter.current = zoomFilter;
      }, [beneficiamentos,zoomFilter]);


    async function clienteSelected(cliente){
        let data;
        try{
            const response = await api.post("/pedido/beneficiamentos",
            JSON.stringify({cod_cliente: cliente.codigo}),
            {
                headers: { 'Content-Type': 'application/json' }
            }
            );
            data=response.data.beneficiamentos;
            setOnline(true);
        }catch(err){
            if(!err.response || err.response.status===0){
                setOnline(false);
            }else{
                confirmAlert({
                    title: "Erro:",
                    message: err.response.data ?err.response.data.error :err.message,
                    buttons: [
                        {
                            label: 'Ok',
                        }
                        ]
                })
            }
        }
        if(data===undefined){
            let cacheInfos=await loadOfflineData();
            if(cacheInfos!==false){
                let query=Enumerable.from(cacheInfos.beneficiamentos);
                query=query.where(i=> i.cod_cliente==cliente.codigo);

                data=query.toArray();
            }
        }
        if(data.length>0){
            setBeneficiamentos(data);
        }else{
            confirmAlert({
                title: "Erro:",
                message: "Nada encontrado.",
                buttons: [
                    {
                        label: 'Ok',
                    }
                ]
            })
        }
    }

    const CabecalhoCliente = () => {
        let cliente=beneficiamentos[0];
        return (
            <div style={{position: "-webkit-sticky" /* Safari & IE */,position: "sticky",top:"0",zIndex: "1"}}>
                <Card className="mb-1" >
                    <Card.Body>
                        <Row>
                            <Col>
                                <Form.Label style={{paddingRight: "5px"}} >{cliente.cod_cliente+" - "+cliente.razao_social}</Form.Label>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className="mb-1">
                    <Card.Body>
                        <Row>
                            <Col /*className="col-md-6 col-12"*/>
                                <FloatingLabel className="mb-2" label="Filtro">
                                    <Form.Control ref={refFilterData} type="text" autoComplete="off" placeholder="" name="texto" onKeyUp={(e)=>{if(e.key==="Enter") setDadoFilterData(refFilterData.current.value)}}/>
                                </FloatingLabel>
                            </Col>
                            <Button className="mb-2" onClick={()=>{setDadoFilterData(refFilterData.current.value);}} style={{width:"10px", heigth:"100%", marginRight: "11px", justifyContent: "center", display: "flex", alignItems: "center"}}><FontAwesomeIcon icon={faMagnifyingGlass} /></Button>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        )
    }

    function dateToString(date){
        let d=new Date(date);
        return d.getDate()+"/"+(d.getMonth()+1)+"/"+d.getFullYear();
    }

    const DadosBeneficiamento = (beneficiamento) => {
        return (
            <div className="btn-action " style={itemSelected===beneficiamento.id?{backgroundColor: "lightGray"}:{}} onClick={()=>{setItemSelected(beneficiamento.id)}}>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <Form.Label style={{fontWeight: "bold"}}>{beneficiamento.cod_produto+" - "+beneficiamento.descricao}</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>UN:</Form.Label>
                                <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{beneficiamento.unidade}</Form.Label>
                            </Col>
                            <Col>
                                <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Inicial:</Form.Label>
                                <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{ceilValue(beneficiamento.inicial).toLocaleString(undefined, {maximumFractionDigits:decimals})}</Form.Label>
                            </Col>
                            <Col>
                                <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Saldo:</Form.Label>
                                <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{ceilValue(beneficiamento.saldo).toLocaleString(undefined, {maximumFractionDigits:decimals})}</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Data Emissão:</Form.Label>
                                <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{dateToString(beneficiamento.emissao)}</Form.Label>
                            </Col>
                            <Col>
                                <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Nota Fiscal:</Form.Label>
                                <Button variant="link" onClick={()=>{setZoomFilter({notafiscal:beneficiamento.notafiscal})}} style={{fontWeight: "bold",padding:"0px",paddingRight: "5px",border:"0px"}}>{beneficiamento.notafiscal}<FontAwesomeIcon style={{padding:"0px",paddingLeft: "5px", color:"var(--cinzaPalagi)"}} icon={faMagnifyingGlass}/></Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }

    const Beneficiamentos = () => {
        let dbeneficiamentos=[];
        let inicial_total=0.0;
        let saldo_total=0.0;
        let quantidade_registros=0;
        beneficiamentos.forEach(beneficiamento => {
            let show=true;
            if(Object.keys(zoomFilter).length!==0){
                for(const [key, value] of Object.entries(zoomFilter)){
                    if(key==="notafiscal"){
                        if(beneficiamento.notafiscal!==value)
                            show=false;
                    }
                }
                if(show===true){
                    inicial_total=inicial_total+ceilValue(beneficiamento.inicial);
                    saldo_total=saldo_total+ceilValue(beneficiamento.saldo);
                    quantidade_registros=quantidade_registros+1;
                }
            }else{
                if(dadoFilterData!==undefined && dadoFilterData.length!==0){
                    let inicial=ceilValue(beneficiamento.quantidade).toLocaleString(undefined, {maximumFractionDigits:decimals});
                    let saldo=ceilValue(beneficiamento.valor_unitario).toLocaleString(undefined, {maximumFractionDigits:decimals});
                    let emissao=dateToString(beneficiamento.emissao);
                    let descBeneficiamento=beneficiamento.cod_produto+"|"+beneficiamento.descricao+"|"+inicial+"|"+saldo+"|"+emissao+"|"+beneficiamento.notafiscal;
                    show=descBeneficiamento.includes(dadoFilterData.toUpperCase());
                }           
            }
            
            if(show)
                dbeneficiamentos.push(<DadosBeneficiamento key={`${beneficiamento.id}`} {...beneficiamento}/>);
        })

        return (
            <>
                {dbeneficiamentos}
                {
                    Object.keys(zoomFilter).length
                    ?<Card>
                        <Card.Body className="">
                            <Row>
                                <Col>
                                    <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Inicial:</Form.Label>
                                    <Form.Label style={{fontWeight: "bold"}}>{inicial_total.toLocaleString(undefined, {maximumFractionDigits:decimals})}</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Saldo:</Form.Label>
                                    <Form.Label style={{fontWeight: "bold"}}>{saldo_total.toLocaleString(undefined, {maximumFractionDigits:decimals})}</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Registros:</Form.Label>
                                    <Form.Label style={{fontWeight: "bold"}}>{quantidade_registros.toLocaleString(undefined, {maximumFractionDigits:decimals})}</Form.Label>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    :""
                }
            </>
        )
    }

    function voltar(){
        if(Object.keys(refZoomFilter.current).length>0)
            setZoomFilter({});
        else{
            setDadoFilterData('')
            setBeneficiamentos([])
        }
    }   

    return (
    <>
    <SideBar/>
    <main className="content">
            {
                online ?null :<div className="alert alert-warning" role="alert">Você está offline</div>
            }
            {
                sincronizando ?<div className="alert alert-warning" role="alert">Sincronizando</div> :null
            }
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block">
                <h4>Consulta de Beneficiamentos</h4>
            </div>
            {
                beneficiamentos.length!==0
                ?
                <Button onClick={voltar}>
                    Voltar
                </Button>
                :""
            }
        </div>
        {
            beneficiamentos.length===0
            ?
            <Row className="mb-2">
                <Col >
                    <FiltroCliente callback={clienteSelected} modeloBotao={2} />
                </Col>
            </Row>
            :
            <>
                <CabecalhoCliente key={beneficiamentos[0].cod_cliente}/>
                <Card border="light">
                    <Card.Body className="">
                        <Beneficiamentos/>
                    </Card.Body>
                    <Card.Footer className="d-flex justify-content-end">
                        <Button onClick={voltar}>
                            Voltar
                        </Button>
                    </Card.Footer>
                </Card>
            </>
        }
        
    </main>
    </>
    )
}