import React, { useState,useEffect } from 'react';
import { Col, Row, Form, Button, Container, InputGroup,Alert,Image } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import useAuth from '../Context/hooks/UseAuth';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoPalagi from "../assets/img/brand/palagi_design_que_transforma_login.png";
import LogoPrismma from "../assets/img/brand/prismma_textil.png";

const { REACT_APP_APP_ENV } = process.env;

function initialState(){
  return {usuario:'', senha:''};
}

export default function Login() {
  const [values, setValues] = useState(initialState);
  const { processaLogin } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const [errMsg, setErrMsg] = useState('');
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [btnInstall,setBtnInstall] = useState(false);
  const [prompt,setPrompt] = useState(false);

  function onChanged(e){
    const {value,name} = e.target;

    setValues({
      ...values,
      [name]: value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    let error = await processaLogin(values);
    if(error.length > 0){
      setErrMsg(error);
      setVisibleAlert(true)
      setTimeout(() => {
        setVisibleAlert(false)
        setErrMsg('');
      },2000)
    }else{
      setValues(initialState)
      navigate(from, { replace: true });
    }
}

useEffect(() => {
  console.log(REACT_APP_APP_ENV)
  function listenToUserAction(e) {
    e.preventDefault();
    /*const installBtn = document.getElementById("pwa_install");
    if(!!installBtn){
      installBtn.style.display = 'inline-block';
      installBtn.addEventListener("click", presentAddToHome);
    }*/
    setBtnInstall(true)
    setPrompt(e);
  }

  window.addEventListener("beforeinstallprompt", listenToUserAction);
  return () => {
    window.removeEventListener("beforeinstallprompt", listenToUserAction);
  };
},[])

const handlePrompt = () => {
  prompt.prompt();  // Wait for the user to respond to the prompt
  prompt.userChoice.then(choice => {
                          if (choice.outcome === 'accepted') {
                            setBtnInstall(false)
                          }
                        })
}

  
  return (
    <main>
    <section className="d-flex align-items-center my-2">
      <Container>
        <Row className="justify-content-center my-2">
          <Col xs={12} className="d-flex align-items-center justify-content-center">
              <Image src={REACT_APP_APP_ENV==="palagi"?LogoPalagi:LogoPrismma}/>
          </Col>
        </Row>
        <Row className="justify-content-center" >
          <Col xs={12} className="d-flex align-items-center justify-content-center">
            <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
              <Form className="mt-4" onSubmit={handleSubmit}>
                <Form.Group className="mb-4">
                  <Form.Label>Usuário</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faUser} />
                    </InputGroup.Text>
                    <Form.Control autoFocus required type="use" placeholder="Usuário" name="usuario" onChange={onChanged} value={values.usuario} />
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Label>Senha</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>  
                      <Form.Control required type="password" placeholder="Senha" name="senha" onChange={onChanged} value={values.senha} />
                    </InputGroup>
                  </Form.Group>
                  {/*
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <Form.Check type="checkbox">
                      <FormCheck.Input id="defaultCheck5" className="me-2" />
                      <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Lembrar-me</FormCheck.Label>
                    </Form.Check>
                  </div>*/}
                </Form.Group>
                {visibleAlert ? <Alert show={visibleAlert} variant="danger">{errMsg}</Alert>: ""}
                <Button variant="primary" type="submit" className="w-100" style={{backgroundColor: "var(--cinzaPalagi)", color: "white"}}>
                  Entrar
                </Button>
                {btnInstall?
                  <Button id="pwa_install" variant="primary" className="w-100" style={{backgroundColor: "var(--cinzaPalagi)", color: "white", marginTop: "10px"}} onClick={handlePrompt}>
                    Instalar App
                  </Button>
                  :""
                }
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  </main>
  );
}
