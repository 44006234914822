import React,{useState,useEffect,useRef} from 'react';
import api from '../api';
import useAuth from '../Context/hooks/UseAuth';
import Enumerable from 'linq'
import { Button, Modal, Form, Row, Col,FloatingLabel, ToggleButton } from 'react-bootstrap';
import {confirmAlert} from 'react-confirm-alert'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

const initialFiltersCliente={codigo:"",razao_social:""}

export default function FiltroCliente({callback,modeloBotao}){
    const { online,setOnline } = useAuth();
    const { loadOfflineData, sincronizando } = useAuth();

    const [filtersCliente, setFiltersCliente] = useState(initialFiltersCliente);
    const [showModalClientes, setShowModalClientes] = useState(false);
    const [clientes, setClientes] = useState([]);


    const refShowModalClientes = useRef(showModalClientes);


    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.key === 'Escape') {
                event.preventDefault();
                if(refShowModalClientes.current===true){
                    handleCloseModalClientes();
                }
            }
        }
        document.addEventListener('keydown', keyDownHandler);

        return () => {
          document.removeEventListener('keydown', keyDownHandler);
        };
    },[]);

    useEffect(() => {
        refShowModalClientes.current = showModalClientes;
      }, [showModalClientes]);


    function onChangedCli(e){
        const {value,name} = e.target;

        const re = /^[0-9/\b]+$/;
        if (value === '' || re.test(value)) {
            setFiltersCliente({
                ...filtersCliente,
                [name]: value
              })
        }
    }

    function onChangedListCli(e){
        const {value,name} = e.target;
        setFiltersCliente({
            ...filtersCliente,
            [name]: value
        })
    }

    const handleCloseModalClientes = () => {
        setShowModalClientes(false);
    }

    const BtnActionCliente = (props) => {
        const cli=props;
        return (
            <div className="btn-action d-flex" onClick={()=>{clickedRowCliente(cli)}} style={{height: "40px", alignItems: "center"}}>
                <div>
                    <Form.Label style={{fontWeight: "bold"}}>{cli.codigo+" - "+cli.razao_social}</Form.Label>
                    <br/>
                    <small>{cli.cpfcnpj}</small>
                </div>
            </div>
        );
    };
    


    const buscaCliente = async (e) => {
        //SE TIVER O e VEIO DOS FILTROS, CASO CONTRÁRIO PELOS BOTÕES DOS CÓDIGOS ESPECIAIS
        if(e!==undefined){
            e.preventDefault();
        }

        let fil={
            ...filtersCliente,
            razao_social: filtersCliente.razao_social==="" ?undefined :filtersCliente.razao_social.split(' ')
        }

        let existeFiltro=false;

        for(const [key, value] of Object.entries(fil)){
            if(value!==undefined && (value.length>0 || value===true)){
                existeFiltro=true;
                break;
            }
        }

        if(existeFiltro){

            let data;
            try{
                const response = await api.post("/cliente/",
                JSON.stringify(fil),
                {
                    headers: { 'Content-Type': 'application/json' }
                }
                );
                data=response.data;
                setOnline(true);
            }catch(err){
                if(!err.response || err.response.status===0){
                    setOnline(false);
                }else{
                    confirmAlert({
                        title: "Erro:",
                        message: err.response.data ?err.response.data.error :err.message,
                        buttons: [
                            {
                                label: 'Ok',
                            }
                            ]
                    })
                }
            }

            if(data===undefined){
                let cacheInfos=await loadOfflineData();
                if(cacheInfos!==false){
                        /* ESSE CÓDIGO É BASEADO NO CÓDIGO IMPLEMENTADO NO BACKEND infos-controlles.js getFormacaoPreco, IMPORTANTE OS 2 MANTEREM A MESMA LÓGICA, ENTÃO SE ALTERAR UM REVISAR O OUTRO */
                        let query=Enumerable.from(cacheInfos.clientes);
                        
                        if(fil.codigo!==undefined && fil.codigo.length>0){
                            query=query.where(i=> i.codigo==fil.codigo);
                        }

                        if(fil.razao_social!==undefined && fil.razao_social.length>0){
                            fil.razao_social.forEach(desc =>{
                                if(desc.length>0){
                                    query=query.where(i => i.razao_social.toUpperCase().indexOf(desc.toUpperCase())>=0);
                                }
                            });
                        }

                        let result=query.toArray();

                        /*let prods=[]
                        result.forEach(res => {
                            let queryProds=Enumerable.from(cacheInfos.estoque);
                            let arrayProd=queryProds.where(p => p.cod_produto===res.cod_produto).toArray();
                            let estoque=0.0;
                            arrayProd.forEach(p => {
                                estoque=estoque+p.livre;
                            });
                            prods.push({...res,estoque: estoque, percentualDesconto: ''});
                        })*/
                        data={clientes: result}
                }
            }

            if(data.clientes.length > 1){
                setClientes(data.clientes);
                setShowModalClientes(true)
            }else if(data.clientes.length===1){
                clickedRowCliente(data.clientes[0])
            }
        }
    }

    var clickedRowCliente = async (cliente) => {
        setFiltersCliente(initialFiltersCliente);
        setShowModalClientes(false)
        callback(cliente);
    }

    return (
        <>
            {
                showModalClientes?
                <React.Fragment>
                <Modal as={Modal.Dialog} show scrollable={true}>
                    <Modal.Header>
                    <Modal.Title className="h6">Seleção de clientes</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            clientes.map(d => <BtnActionCliente key={`${d.id}`} {...d}/>)
                        }
                    </Modal.Body>
                    <Modal.Footer>
                    <Button onClick={handleCloseModalClientes}>Sair</Button>
                    </Modal.Footer>
                </Modal>
                </React.Fragment>
                :""
            }

            <Form onSubmit={buscaCliente} className="mb-3">
                <Row className="mb-2">
                    <Col>
                        <Row>
                            {/* Código cliente */}
                            <Col xs={12} md={3} className="mb-3 mb-md-0">
                                <FloatingLabel label="Código cliente">
                                    <Form.Control 
                                        type="text" 
                                        autoComplete="off" 
                                        inputMode="numeric" 
                                        placeholder="" 
                                        name="codigo" 
                                        onChange={onChangedCli} 
                                        value={filtersCliente.codigo}
                                    />
                                </FloatingLabel>
                            </Col>

                            {/* Razão Social */}
                            <Col xs={12} md={9}>
                                <FloatingLabel label="Razão Social">
                                    <Form.Control 
                                        type="text" 
                                        autoComplete="off" 
                                        placeholder="" 
                                        name="razao_social" 
                                        onChange={onChangedListCli} 
                                        value={filtersCliente.razao_social}
                                    />
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Col>
                    {
                    modeloBotao===undefined || modeloBotao===1
                        ?<Button type="submit" style={{width:"60px",  justifyContent: "center", display: "flex", alignItems: "center"}}><FontAwesomeIcon icon={faMagnifyingGlass} /></Button>
                        :""
                    }
                </Row>
                {
                    modeloBotao===2
                    ?
                    <Row>
                        <Col md={12} className="mb-3 d-flex justify-content-center">
                            <Button type="submit" className="col-md-3 col-12" >Buscar</Button>
                        </Col>
                    </Row>
                    :""
                }
            </Form>
        </>
    )

}