import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import LogoPalagi from "../assets/img/brand/palagi_design_que_transforma_login.png";
import LogoPrismma from "../assets/img/brand/prismma_textil.png";
import gota from "../assets/img/gota.jpg";
import moment from 'moment/min/moment-with-locales';




const { REACT_APP_APP_ENV } = process.env;
moment.locale('pt-br');
var decimals = 2;

const borderColor = 'black'

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    fontFamily: 'Helvetica',
    fontSize: 10,
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    lineHeight: 1.5,
  },
  section: {
    padding: 5,
    //flexGrow: 1
    flexDirection: 'row',
  },
  sectionTitle: {
    padding: 2,
    //flexGrow: 1
    flexDirection: 'row',
    fontSize: 12,
    borderWidth: 1,
    borderColor: 'black',
    backgroundColor: '#c0c0c0',
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderColor: 'black',
  },
  tableTitleContainer: {
    flexDirection: 'row',
    borderBottomColor: 'black',
    backgroundColor: '#c0c0c0',
    color: '#800000',
    borderBottomWidth: 1,
    alignItems: 'center',
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1
  },
  tableGroupContainter:{
    flexDirection: 'column',
    borderBottomColor: 'black',
    borderBottomWidth: 1,
  },
  tableRowContainter:{
    flexDirection: 'row',
    alignItems: 'center',
    fontStyle: 'bold',
    textAlign: 'center',
    marginTop: "2px"
  },
  tableRowObsContainter:{
    paddingLeft: "5px",
    paddingTop: "2px",
  },
  tableFooterContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 12,
    fontStyle: 'bold',
  }
});

function getTexto(val){
  return val!==undefined && val.length?val:" ";
}



  const PedidoAmostraViewer = ({itensPedido,textosPedido,dadosPedido}) => (
    <PDFViewer style={styles.viewer}>
      <PedidoAmostra itensPedido={itensPedido} textosPedido={textosPedido} dadosPedido={dadosPedido}/>
    </PDFViewer>
  )

  // Create Document Component
  const PedidoAmostra = ({itensPedido,textosPedido,dadosPedido}) => (
      <Document>
        <Page size="A4" style={styles.page}>



          {/*CABEÇALHO*/}
          <View style={{...styles.section,justifyContent:'space-between', alignItems: "center"}}>
            <div style={{wordWrap: "break-word",display:"flex",alignItems: "left"}}>
                <Text style={{fontWeight: "bold"}}>{"Data de Emissão: "+moment().format('dddd, D MMMM YYYY')}</Text>
            </div>
            <Image style={{height: 50,width: "20%"}} src={REACT_APP_APP_ENV==="palagi"?LogoPalagi:LogoPrismma} />
          </View>
          
          {/*CLIENTE*/}
          <View style={{...styles.sectionTitle}}>
            <Text style={{fontWeight: "bold"}}>CLIENTE</Text>
          </View>
          <View style={{...styles.section, borderWidth: 1,borderColor: 'black', paddingRight: '0px'}}>
            <div style={{width: '11%',alignItems: "left"}}>
              <Text style={{fontWeight: "bold"}}>Cód/Cliente:</Text>
              <Text style={{fontWeight: "bold"}}>CNPJ:</Text>
              <Text style={{fontWeight: "bold"}}>Observação:</Text>
            </div>
            <div style={{width: '55%'}}>
                <Text>{dadosPedido.cliente.codigo+" - "+getTexto(dadosPedido.cliente.razao_social)}</Text>
                <Text>{getTexto(dadosPedido.cliente.cpfcnpj)+"       I.E:"+getTexto(dadosPedido.cliente.inscricaoestadual)}</Text>
                <Text>{getTexto(dadosPedido.observacao)}</Text>
            </div>

            <div style={{width: '12%',alignItems: "left"}}>
              <Text style={{fontWeight: "bold"}}>Vendedor:</Text>
            </div>
            <div style={{width: '22%'}}>
                <Text>{getTexto(dadosPedido.cliente.representante)}</Text>
            </div>
          </View>

          {/* COMPLEMENTO */}
          <View style={{...styles.section, flexDirection:"column"}}>
            <Text style={{fontWeight: "bold", fontSize: 12}}>{getTexto(textosPedido.comp_titulo)}</Text>
            <Text >{getTexto(textosPedido.comp_texto)}</Text>
          </View>


          {/*ITENS*/}
          <View style={styles.tableContainer}>
            <View style={styles.tableTitleContainer}>
              <Text style={{borderColor: borderColor,borderRightWidth: 1,width:"5%"}}>Item</Text>
              <Text style={{borderColor: borderColor,borderRightWidth: 1,width:"37%"}}>Descrição</Text>
              <Text style={{borderColor: borderColor,borderRightWidth: 1,width:"8%"}}>Quant</Text>
              <Text style={{borderColor: borderColor,borderRightWidth: 1,width:"20%"}}>Unid.</Text>
              <Text style={{borderColor: borderColor,borderRightWidth: 1,width:"30%"}}>Observação</Text>
            </View>
            {
              itensPedido.map(item => {   
                return (
                  <View style={styles.tableGroupContainter}>
                    <View style={styles.tableRowContainter} key={parseInt(item.idx)+1}>
                      <Text style={{borderColor: borderColor,borderRightWidth: 1,width:"5%"}}>{parseInt(item.idx)+1}</Text>
                      <Text style={{borderColor: borderColor,borderRightWidth: 1,width:"37%", paddingLeft: "2.5px",paddingRight: "2.5px", fontSize: "9 "}}>{getTexto(item.produto.descricao)}</Text>
                      <Text style={{borderColor: borderColor,borderRightWidth: 1,width:"8%"}}>{getTexto(item.produto.quantidade.toLocaleString(undefined, {maximumFractionDigits:decimals}))}</Text>
                      <Text style={{borderColor: borderColor,borderRightWidth: 1,width:"20%"}}>{getTexto(item.produto.tamanhoAmostra !== "Digitar um tamanho" 
                        ? item.produto.tamanhoAmostra.toLocaleString(undefined, {maximumFractionDigits:decimals})
                        : item.produto.tamanhoEditado .toLocaleString(undefined, {maximumFractionDigits:decimals})
                      )}</Text>
                      <Text style={{borderColor: borderColor,borderRightWidth: 1,width:"30%"}}>{getTexto(item.produto.observacao.toLocaleString(undefined, {maximumFractionDigits:decimals}))}</Text>
                    </View>
                  </View>
                )
              })
            }
          </View>
        </Page>
      </Document>
  );

export {PedidoAmostra,PedidoAmostraViewer};
