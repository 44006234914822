import axios from 'axios';
import AuthContext from './Context/AuthProvider';
import { useContext } from 'react';

// Cria a instância do Axios
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Função para configurar interceptores
export const setupInterceptors = (logout) => {
  api.interceptors.response.use(
    (response) => {
      // Se a resposta for bem-sucedida, retorna a resposta normalmente
      return response;
    },
    (error) => {
      // Se a resposta tiver erro, verifica se é um erro de autorização
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        // Chama a função de logout se o usuário não estiver autorizado ou inativo
        logout();
      }
      // Rejeita a promessa com o erro para que possa ser tratado onde for necessário
      return Promise.reject(error);
    }
  );
};

// Exporta a instância do Axios
export default api;
