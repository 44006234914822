import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import LogoPalagi from "../assets/img/brand/palagi_design_que_transforma_login.png";
import LogoPrismma from "../assets/img/brand/prismma_textil.png";
import gota from "../assets/img/gota.jpg";
import moment from 'moment/min/moment-with-locales';




const { REACT_APP_APP_ENV } = process.env;
moment.locale('pt-br');
var decimals = 2;

const borderColor = 'black'

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    fontFamily: 'Helvetica',
    fontSize: 10,
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    lineHeight: 1.5,
  },
  section: {
    padding: 5,
    //flexGrow: 1
    flexDirection: 'row',
  },
  sectionTitle: {
    padding: 2,
    //flexGrow: 1
    flexDirection: 'row',
    fontSize: 12,
    borderWidth: 1,
    borderColor: 'black',
    backgroundColor: '#c0c0c0',
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderColor: 'black',
  },
  tableTitleContainer: {
    flexDirection: 'row',
    borderBottomColor: 'black',
    backgroundColor: '#c0c0c0',
    color: '#800000',
    borderBottomWidth: 1,
    alignItems: 'center',
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1
  },
  tableRowContainter:{
    flexDirection: 'row',
    borderBottomColor: 'black',
    borderBottomWidth: 1,
    alignItems: 'center',
    fontStyle: 'bold',
    textAlign: 'center',
    marginTop: "2px"
  },
  tableFooterContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 12,
    fontStyle: 'bold',
  }
});

function ceilValue(val){
  return (Math.ceil(val*Math.pow(10,decimals))/Math.pow(10,decimals))
}

function getValorDado(dado,icmscolumn){
  let valor=0.0;
  if(dado.precos===undefined){
    valor=dado[icmscolumn];
  }else{
    dado.precos.forEach(p => {
        if(p.id_preco===parseInt(icmscolumn)){
            valor=p.valor;
            return;
        }
    });
  }
  return valor;
}

function calculaValor(dado,icmscolumn){
  if(parseFloat(dado.percentualDesconto)>0){
    return ceilValue(getValorDado(dado,icmscolumn)-(getValorDado(dado,icmscolumn)*(parseFloat(dado.percentualDesconto.replaceAll('.','').replaceAll(',','.'))/100.0)))
  }else if(parseFloat(dado.percentualOverprice)>0){
      return ceilValue(getValorDado(dado,icmscolumn)+(getValorDado(dado,icmscolumn)*(parseFloat(dado.percentualOverprice.replaceAll('.','').replaceAll(',','.'))/100.0)))
  }else if(dado.precoManual!==undefined && dado.precoManual.icmsBase!==undefined){
      return ceilValue(getValorDado(dado,icmscolumn)-(getValorDado(dado,icmscolumn)*dado.precoManual.percentual));
  }else{
      return ceilValue(getValorDado(dado,icmscolumn));
  }
}

function calculaDesconto(dado,icmscolumn){
  if(parseFloat(dado.percentualDesconto)>0){
      return ceilValue((getValorDado(dado,icmscolumn)*(parseFloat(dado.percentualDesconto.replaceAll('.','').replaceAll(',','.'))/100.0)))
  }else{
      return 0.0
  }
}


function getTotal(itensProposta,textosProposta,dadosProposta,tipoProduto){
  let tot=0.0;
  itensProposta.forEach(item =>{
    let valor=0.0
    item.dadosItem.forEach(dado =>{
      if(dado.cod_produto>0)
        valor=valor+calculaValor(dado,dadosProposta.icms.value)
    });

    let tempDado={
      valor: valor,
      percentualDesconto: item.percentualDesconto,
      percentualOverprice: item.percentualOverprice,
      precoManual: item.precoManual
    }
    tot=tot+ceilValue(parseFloat(item.quantidade.replaceAll('.','').replaceAll(',','.'))*calculaValor(tempDado,"valor"));
  });
  return tot;
}

function getTexto(val){
  return val.length?val:" ";
}

/*export default function Proposta({itensProposta,textosProposta}){
    return (
      <PDFViewer style={styles.viewer}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.section}>
              <Text>{textosProposta.cabecalho}</Text>
            </View>
            <View style={styles.section}>
              <Text>{textosProposta.teste}</Text>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    );
  }*/

  const PropostaViewer = ({itensProposta,textosProposta,dadosProposta,tipoProduto}) => (
    <PDFViewer style={styles.viewer}>
      <Proposta itensProposta={itensProposta} textosProposta={textosProposta} dadosProposta={dadosProposta} tipoProduto={tipoProduto}/>
    </PDFViewer>
  )
  
  // Create Document Component
  const Proposta = ({itensProposta,textosProposta,dadosProposta,tipoProduto}) => (
      <Document>
        <Page size="A4" style={styles.page}>



          {/*CABEÇALHO*/}
          <View style={{...styles.section,justifyContent:'space-between'}}>
            <Image style={{height: 50,width: "20%"}} src={REACT_APP_APP_ENV==="palagi"?LogoPalagi:LogoPrismma} />
            <div style={{fontWeight: "bold", fontSize: "15",wordWrap: "break-word",width: "52%",display:"flex",alignItems: "center"}}>
              <Text >{textosProposta.titulo}</Text>
            </div>
            <div style={{width: "25%"}}>
              <Text style={{fontWeight: "bold"}}>{moment().format('dddd, D MMMM YYYY')}</Text>
              <div style={{fontWeight: "bold", borderWidth: 1,borderColor: 'black'}}>
                <Text style={{fontWeight: "bold"}}>Representante</Text>
                <Text >{dadosProposta.cliente.representante}</Text>
              </div>
            </div>
          </View>



          {/*CLIENTE*/}
          <View style={{...styles.sectionTitle}}>
            <Text style={{fontWeight: "bold"}}>CLIENTE</Text>
          </View>
          <View style={{...styles.section, borderWidth: 1,borderColor: 'black', paddingRight: '0px'}}>
            <div style={{width: '12%',alignItems: "left"}}>
              <Text style={{fontWeight: "bold"}}>Empresa:</Text>
              {/*<Text style={{fontWeight: "bold"}}>Contato:</Text>
              <Text style={{fontWeight: "bold"}}>E-mail:</Text>*/}
              <Text style={{fontWeight: "bold"}}>Cidade:</Text>
              <Text style={{fontWeight: "bold"}}>Estado:</Text>
              <Text style={{fontWeight: "bold"}}>CNPJ / CPF:</Text>
            </div>
            <div style={{width: '60%'}}>
                <Text>{getTexto(dadosProposta.cliente.razao_social)}</Text>
                {/*<Text>{getTexto(dadosProposta.cliente.contato)}</Text>
                <Text>{getTexto(dadosProposta.cliente.email)}</Text>*/}
                <Text>{getTexto(dadosProposta.cliente.cidade)}</Text>
                <Text>{getTexto(dadosProposta.cliente.uf)}</Text>
                <Text>{getTexto(dadosProposta.cliente.cpfcnpj)}</Text>
            </div>
            {/*
            <div style={{width: '28%'}}>
              <div style={{flexDirection: 'row'}}>
                <Text style={{width: '30%'}}>Telefone:</Text>
                <Text>{getTexto(dadosProposta.cliente.telefone)}</Text>
              </div>
              <div style={{flexDirection: 'row',}}>
                  <Text style={{width: '50%'}}>Lista Padrão:</Text>
                  <Text>{getTexto(dadosProposta.cod_lista)}</Text>
                </div>
              <div style={{borderWidth: 1,borderColor: 'black', marginTop: "10px"}}>
                <div style={{flexDirection: 'row',}}>
                  <Text style={{width: '50%'}}>Padrão Tubete:</Text>
                  <Text>{dadosProposta.tubete.codigo}</Text>
                </div>
                <Text style={{fontSize: 8, textAlign: "center"}}>{dadosProposta.tubete.descricao}</Text>
                <div style={{flexDirection: 'row',}}>
                  <Text style={{width: '50%'}}>Lista Padrão:</Text>
                  <Text>{dadosProposta.cod_lista}</Text>
                </div>
              </div>
            </div>
            */}
          </View>





          {/* COMPLEMENTO */}
          <View style={{...styles.section, flexDirection:"column"}}>
            <Text style={{fontWeight: "bold", fontSize: 12}}>{getTexto(textosProposta.comp_titulo)}</Text>
            <Text >{getTexto(textosProposta.comp_texto)}</Text>
          </View>




          {/*ITENS*/}
          <View style={styles.tableContainer}>
            <View style={styles.tableTitleContainer}>
              <Text style={{borderColor: borderColor,borderRightWidth: 1,width:"8%"}}>Item</Text>
              {
                dadosProposta.imprimirdesconto?
                <>
                  <Text style={{width:"48%"}}>Descrição</Text>
                  <Text style={{borderColor: borderColor,borderLeftWidth: 1,width:"9%",paddingLeft: "2.5px"}}>Desconto</Text>
                </>
                :
                  <Text style={{width:"57%"}}>Descrição</Text>
              }
              <Text style={{borderColor: borderColor,borderLeftWidth: 1,borderRightWidth: 1,width:"9%",paddingLeft: "2.5px"}}>Preço {tipoProduto.key==="tecido" ?"ML" :"M²"}</Text>
              <Text style={{borderColor: borderColor,borderRightWidth: 1,width:"8%"}}>ICMS</Text>
              <Text style={{borderColor: borderColor,borderRightWidth: 1,width:"8%"}}>Quant</Text>
              <Text style={{width:"10%"}}>Sub. Total</Text>
            </View>
            {
              itensProposta.map(item =>{
                if(item.dadosItem.length===0) return;
                let descricao="";
                let valor=0.0;
                let desconto=0.0;
                item.dadosItem.forEach(dado =>{
                  if(descricao.length>0)
                    descricao=descricao+" + ";
                  descricao=descricao+dado.descricao;
                  if(dado.cod_produto>0)
                    valor=valor+calculaValor(dado,dadosProposta.icms.value)
                  desconto=desconto+calculaDesconto(dado,dadosProposta.icms.value)
                });

                let tempDado={
                  valor: valor,
                  percentualDesconto: item.percentualDesconto,
                  percentualOverprice: item.percentualOverprice,
                  precoManual: item.precoManual
                }

                let percentualDesconto=0.0;
                
                if(item.percentualDesconto!=="" && parseFloat(item.percentualDesconto)>0){
                  percentualDesconto=parseFloat(item.percentualDesconto)
                }else if(desconto>0){
                  percentualDesconto=(desconto / (valor+desconto))*100.0
                }

                valor=calculaValor(tempDado,"valor");
              
                return (
                  <View style={styles.tableRowContainter} key={parseInt(item.idx)+1}>
                    <Text style={{borderColor: borderColor,borderRightWidth: 1,width:"8%"}}>{parseInt(item.idx)+1}</Text>
                    
                    {
                      dadosProposta.imprimirdesconto?
                      <>
                      <Text style={{width:"48%", paddingLeft: "2.5px",paddingRight: "2.5px", fontSize: "9 "}}>{tipoProduto.text} {" "} {descricao}</Text>
                      <Text style={{borderColor: borderColor,borderLeftWidth: 1,width:"9%",paddingLeft: "2.5px"}}>{percentualDesconto>0 ?percentualDesconto.toLocaleString(undefined, {maximumFractionDigits:decimals})+"%" :" "}</Text>
                      </>
                    :
                      <Text style={{width:"57%", paddingLeft: "2.5px",paddingRight: "2.5px", fontSize: "9 "}}>{tipoProduto.text} {" "} {descricao}</Text>
                    }
                    <Text style={{borderColor: borderColor,borderLeftWidth: 1, borderRightWidth: 1,width:"9%", paddingLeft: "2.5px"}}>{valor.toLocaleString(undefined, {minimumFractionDigits:decimals,maximumFractionDigits:decimals})}</Text>
                    <Text style={{borderColor: borderColor,borderRightWidth: 1,width:"8%"}}>{dadosProposta.icms.text}</Text>
                    <Text style={{borderColor: borderColor,borderRightWidth: 1,width:"8%"}}>{item.quantidade.toLocaleString(undefined, {maximumFractionDigits:decimals})}</Text>
                    <Text style={{width:"10%"}}>{ceilValue(parseFloat(item.quantidade.replaceAll('.','').replaceAll(',','.'))*valor).toLocaleString(undefined, {minimumFractionDigits:decimals,maximumFractionDigits:decimals})}</Text>
                  </View>
                )
              })
            }
          </View>
          <View style={styles.tableFooterContainer}>
            <Text style={{width: '85%',textAlign: 'right'}}>Total das Mercadorias:</Text>
            <Text style={{width: '15%',textAlign: 'center'}}>{"R$ "+getTotal(itensProposta,textosProposta,dadosProposta,tipoProduto).toLocaleString(undefined, {minimumFractionDigits:decimals,maximumFractionDigits:decimals})}</Text>
          </View>







          {/*INFORMAÇÕES ADICIONAIS*/}
          <View style={{...styles.sectionTitle}}>
            <Text style={{fontWeight: "bold"}}>INFORMAÇÕES ADICIONAIS</Text>
          </View>
          <View style={{...styles.section, borderWidth: 1,borderColor: 'black'}}>
            <div style={{width: '20%',alignItems: "center"}}>
              <Text style={{fontWeight: "bold"}}>Prazo de entrega:</Text>
              <Text style={{fontWeight: "bold"}}>Impostos inclusos:</Text>
              <Text style={{fontWeight: "bold"}}>Frete:</Text>
              <Text style={{fontWeight: "bold"}}>Pagamento:</Text>
              <Text style={{fontWeight: "bold"}}>Industrialização:</Text>
              <Text style={{fontWeight: "bold"}}>Validade da proposta:</Text>
              <Image style={{height: 50}} src={gota} />
            </div>
            <div style={{width: '80%'}}>
                <Text>{getTexto(textosProposta.infoads_prazoentrega)}</Text>
                <Text>{getTexto(textosProposta.infoads_impostos)}</Text>
                <Text>{getTexto(textosProposta.infoads_frete)}</Text>
                <Text>{getTexto(textosProposta.infoads_pagamento)}</Text>
                <Text>{getTexto(textosProposta.infoads_industrializacao)}</Text>
                <Text>{getTexto(textosProposta.infoads_validade)}</Text>
                <Text style={{fontWeight: "bold"}}>{getTexto(textosProposta.infoads_lavagem)}</Text>
            </div>
          </View>
          <View style={{...styles.section, borderWidth: 1,borderColor: 'black',flexDirection: 'column'}}>
            <Text style={{fontWeight: "bold"}}>{getTexto(textosProposta.infoads_agradecimento)}</Text>
          </View>







          {/*RODAPÉ*/}
          <View style={{...styles.section, flexDirection:"column",alignItems: "center"}}>
            <Text style={{fontWeight: "bold"}}>{getTexto(textosProposta.rodape_razaosocial)}</Text>
            <Text >{getTexto(textosProposta.rodape_endereco)}</Text>
            <Text >{getTexto(textosProposta.rodape_contato)}</Text>
          </View>


          
        </Page>
      </Document>
  );

export {Proposta,PropostaViewer};
