import crypto from 'crypto-browserify'

function decrypt(text) {
    let iv = Buffer.from(text.iv, 'hex');
    const key=crypto.createHash('sha256').update(String(process.env.REACT_APP_CRYPTO_KEY)).digest();
    let encryptedText = Buffer.from(text.encryptedData, 'hex');
    let decipher = crypto.createDecipheriv('aes-256-cbc', key, iv);
    let decrypted = decipher.update(encryptedText);
    decrypted = Buffer.concat([decrypted, decipher.final()]);
    return decrypted.toString();
}

function tokenDecrypt(token) {
    if(token!==undefined && token!==""){
        const tokenDecode = JSON.parse(atob(token.split('.')[1]));
        const tokenDecrypt=decrypt(tokenDecode);
        return JSON.parse(tokenDecrypt);
    }
}

function encrypt(text) {
    const iv = crypto.randomBytes(16);
    const key=crypto.createHash('sha256').update(String(process.env.REACT_APP_CRYPTO_KEY)).digest();
    let cipher = crypto.createCipheriv('aes-256-cbc', key, iv);
    let encrypted = cipher.update(text);
    encrypted = Buffer.concat([encrypted, cipher.final()]);
    return { iv: iv.toString('hex'), encryptedData: encrypted.toString('hex') };
 }

export {decrypt,tokenDecrypt,encrypt}
