import React,{useState,useEffect,useRef,forwardRef, useImperativeHandle} from 'react';
import api from '../api';
import useAuth from '../Context/hooks/UseAuth';
import Enumerable from 'linq'
import { Button, Modal, Form, Row, Col,FloatingLabel, ToggleButton } from 'react-bootstrap';
import {confirmAlert} from 'react-confirm-alert'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

const initialFiltersListaPreco={codigo:"",nome:""}

const FiltroListaPreco = forwardRef((props, ref) => {
//export default function FiltroListaPreco({disabled,callback}){

    let callback = props.callback;
    let disabled = props.disabled;

    const { online,setOnline } = useAuth();
    const { loadOfflineData, sincronizando } = useAuth();

    const [filtersListaPreco, setFiltersListaPreco] = useState(initialFiltersListaPreco);
    const [showModalListaPrecos, setShowModalListaPrecos] = useState(false);
    const [clientes, setListaPrecos] = useState([]);


    const refShowModalListaPrecos = useRef(showModalListaPrecos);

    useImperativeHandle(ref, () => {
        return {
            getDadosLista(cod) {
                return buscaListaPreco(undefined,cod);
            }
        };
    }, []);

    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.key === 'Escape') {
                event.preventDefault();
                if(refShowModalListaPrecos.current===true){
                    handleCloseModalListaPrecos();
                }
            }
        }
        document.addEventListener('keydown', keyDownHandler);

        return () => {
          document.removeEventListener('keydown', keyDownHandler);
        };
    },[]);

    useEffect(() => {
        refShowModalListaPrecos.current = showModalListaPrecos;
      }, [showModalListaPrecos]);


    function onChangedCli(e){
        const {value,name} = e.target;

        const re = /^[0-9/\b]+$/;
        if (value === '' || re.test(value)) {
            setFiltersListaPreco({
                ...filtersListaPreco,
                [name]: value
              })
        }
    }

    function onChangedListCli(e){
        const {value,name} = e.target;
        setFiltersListaPreco({
            ...filtersListaPreco,
            [name]: value
        })
    }

    const handleCloseModalListaPrecos = () => {
        setShowModalListaPrecos(false);
    }

    const BtnActionListaPreco = (props) => {
        const lista=props;
        return (
            <div className="btn-action d-flex" onClick={()=>{clickedRowListaPreco(lista)}} style={{height: "40px", alignItems: "center"}}>
                <div>
                    <Form.Label style={{fontWeight: "bold"}}>{lista.codigo+" - "+lista.nome}</Form.Label>
                </div>
            </div>
        );
    };
    


    const buscaListaPreco = async (e,cod) => {
        //SE TIVER O e VEIO DOS FILTROS, CASO CONTRÁRIO PELOS BOTÕES DOS CÓDIGOS ESPECIAIS
        if(e!==undefined){
            e.preventDefault();
        }

        let fil={
            ...filtersListaPreco,
            nome: filtersListaPreco.nome==="" ?undefined :filtersListaPreco.nome.split(' ')
        }
        if(cod!==undefined)
            fil={codigo:cod};
            
        let existeFiltro=false;

        for(const [key, value] of Object.entries(fil)){
            if(value!==undefined && (value.length>0 || value===true)){
                existeFiltro=true;
                break;
            }
        }

        if(existeFiltro){

            let data;
            try{
                const response = await api.post("/consultapreco/listas",
                JSON.stringify(fil),
                {
                    headers: { 'Content-Type': 'application/json' }
                }
                );
                data=response.data;
                setOnline(true);
            }catch(err){
                if(!err.response || err.response.status===0){
                    setOnline(false);
                }else{
                    confirmAlert({
                        title: "Erro:",
                        message: err.response.data ?err.response.data.error :err.message,
                        buttons: [
                            {
                                label: 'Ok',
                            }
                            ]
                    })
                }
            }

            if(data===undefined){
                let cacheInfos=await loadOfflineData();
                if(cacheInfos!==false){
                        /* ESSE CÓDIGO É BASEADO NO CÓDIGO IMPLEMENTADO NO BACKEND infos-controlles.js getFormacaoPreco, IMPORTANTE OS 2 MANTEREM A MESMA LÓGICA, ENTÃO SE ALTERAR UM REVISAR O OUTRO */
                        let query=Enumerable.from(cacheInfos.listaspreco);
                        
                        if(fil.codigo!==undefined && fil.codigo>0){
                            query=query.where(i=> i.codigo==fil.codigo);
                        }

                        if(fil.nome!==undefined && fil.nome.length>0){
                            fil.nome.forEach(desc =>{
                                if(desc.length>0){
                                    query=query.where(i => i.nome.toUpperCase().indexOf(desc.toUpperCase())>=0);
                                }
                            });
                        }

                        let result=query.toArray();

                        data={listasPreco: result}
                }
            }

            if(data.listasPreco.length > 1){
                setListaPrecos(data.listasPreco);
                setShowModalListaPrecos(true)
            }else if(data.listasPreco.length===1){
                if(cod!==undefined)
                    return data.listasPreco[0];
                clickedRowListaPreco(data.listasPreco[0])
            }
        }
    }

    var clickedRowListaPreco = async (lista) => {
        setFiltersListaPreco(initialFiltersListaPreco);
        setShowModalListaPrecos(false)
        callback(lista);
    }

    return (
        <>
            {
                showModalListaPrecos?
                <React.Fragment>
                <Modal as={Modal.Dialog} show scrollable={true}>
                    <Modal.Header>
                    <Modal.Title className="h6">Lista de preços</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            clientes.map(d => <BtnActionListaPreco key={`${d.codigo}`} {...d}/>)
                        }
                    </Modal.Body>
                    <Modal.Footer>
                    <Button onClick={handleCloseModalListaPrecos}>Sair</Button>
                    </Modal.Footer>
                </Modal>
                </React.Fragment>
                :""
            }

            <Form onSubmit={buscaListaPreco} className="mb-3">
                <Row className="mb-2">
                    <Col>
                        <Row>
                            <Col md={3} className="col-4">
                                    <FloatingLabel label="Cod. Lista">
                                        <Form.Control type="text" autoComplete="off" inputMode="numeric" placeholder="" name="codigo" onChange={onChangedCli} value={filtersListaPreco.codigo}/>
                                    </FloatingLabel>
                            </Col>
                            <Col md={9} className="col-8">
                                <FloatingLabel label="Nome lista">
                                    <Form.Control type="text" autoComplete="off" placeholder="" name="nome" onChange={onChangedListCli} value={filtersListaPreco.nome}/>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Col>
                    {
                        disabled?""
                        :<Button type="submit" style={{width:"10px",  justifyContent: "center", display: "flex", alignItems: "center"}}><FontAwesomeIcon icon={faMagnifyingGlass} /></Button>
                    }
                </Row>
            </Form>
        </>
    )

});

export default FiltroListaPreco;
