import React,{useState,useEffect,useRef} from 'react';
import SideBar from '../Components/SideBar';
import FiltroCliente from '../Components/FiltroCliente'; 
import { Button, Modal, Form, Row, Col,FloatingLabel, ToggleButton, ButtonGroup, Card } from 'react-bootstrap';
import api from '../api';
import useAuth from '../Context/hooks/UseAuth';
import {confirmAlert} from 'react-confirm-alert'
import Enumerable from 'linq'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass,faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import Swal from 'sweetalert2'
import moment from 'moment/min/moment-with-locales';
import {pdf} from '@react-pdf/renderer';
import { v4 as uuidv4 } from 'uuid';
import { PedidoAmostra, PedidoAmostraViewer } from './PedidoAmostra';
import ReactDOM from 'react-dom';




const initialFilters={cod_produto:"",descricao:"", tamanhoAmostra:"", quantidade: "", observacao: "", tamanhoEditado: "" }
const initialPedido={cliente:{},lista:{},transporte:{tipo:"0"},tubete:{id:0},faturamento:"0",imprimirdesconto:false}
const initialButtonText="Cancelar Pedido";

const { REACT_APP_APP_ENV } = process.env;

moment.locale('pt-br');


export default function CadastroAmostra(){
    const uuid = uuidv4();
    const { online,setOnline, loadOfflineData, sincronizando, ceilValue,decimals } = useAuth();
    const [itensPedido, setItensPedido] = useState([]);
    const [pedido, setPedido] = useState(initialPedido);
    const [itemSelected, setItemSelected] = useState(0);
    const [filters, setFilters] = useState(initialFilters);
    const [prods, setProds] = useState([]);
    const [showModalProds, setShowModalProds] = useState(false);
    const [carregou, setCarregou] = useState(false);
    const [curPage, setCurPage] = useState(0);
    const [showModalCliente, setShowModalCliente] = useState(false);
    const [showModalBeneficiamento,setShowModalBeneficiamento] = useState(false);
    const [showModalFinalizacao,setShowModalFinalizacao] = useState(false);
    const [showModalObservacao,setShowModalObservacao] = useState(false);
    const [valorEdicao, setValorEdicao] = useState('');
    const [itemEdicao, setItemEdicao] = useState({});
    const [beneficiamentos, setBeneficiamentos] = useState([]);
    const [finalizou, setFinalizou] = useState(false);

    const refItensPedido = useRef(itensPedido);
    const refShowModalProds = useRef(showModalProds);
    const refShowModalCliente = useRef(showModalCliente);
    const refShowModalBeneficiamento = useRef(showModalBeneficiamento);
    const refShowModalFinalizacao = useRef(showModalFinalizacao);
    const refShowModalObservacao = useRef(showModalObservacao);

    const btnQuantidade = React.useRef();
    const filtroListaPreco = React.useRef();

    useEffect(() => {
        if(carregou===true && showModalProds===false){
            localStorage.setItem('pedidoAmostra',JSON.stringify(
                {
                    itensPedido: itensPedido,
                    pedido: pedido,
                    curPage:curPage
                }
            ));
        }
    },[itensPedido,pedido,showModalProds,curPage]);


    useEffect(() => {

        let cache=localStorage.getItem("pedidoAmostra");
        if(cache!==undefined && cache!=="" && cache!==null){
            cache=JSON.parse(cache);
            setPedido(cache.pedido);
            setItensPedido(cache.itensPedido);
            setCurPage(cache.curPage);
        }
        setCarregou(true)

        const keyDownHandler = (event) => {
            if (event.key === 'Escape') {
                event.preventDefault();

                if(refShowModalProds.current===true){
                    handleCloseModalProds();
                }else if(refShowModalCliente.current===true){
                    handleCloseModalCliente();
                }else if(refShowModalBeneficiamento.current===true){
                    handleCloseModalBeneficiamento();
                }else if(refShowModalFinalizacao.current===true){
                    handleCloseModalFinalizacao();
                }else if(refShowModalObservacao.current===true){
                    handleCloseModalObservacao();
                }
            }
          };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
          document.removeEventListener('keydown', keyDownHandler);
        };
    },[]);

    useEffect(() => {
        refItensPedido.current = itensPedido;
    }, [itensPedido]);

    useEffect(() => {
        refShowModalProds.current = showModalProds;
    }, [showModalProds]);

    useEffect(() => {
        refShowModalCliente.current = showModalCliente;
    }, [showModalCliente]);

    useEffect(() => {
        refShowModalBeneficiamento.current = showModalBeneficiamento;
    }, [showModalBeneficiamento]);

    useEffect(() => {
        refShowModalFinalizacao.current = showModalFinalizacao;
    }, [showModalFinalizacao]);

    useEffect(() => {
        refShowModalObservacao.current = showModalObservacao;
    }, [showModalObservacao]);

    async function clienteSelected(cliente){
        let pedidoAux={...pedido,cliente:cliente}
        if(cliente.inscricaoestadual===""){
            pedidoAux={...pedidoAux,lista: await filtroListaPreco.current.getDadosLista("5022")};
        }
        setPedido(pedidoAux);
        if(cliente.observacao!==""){
            setShowModalCliente(true);
        }
    }

    const CabecalhoCliente = () => {
        return (
            <div style={{position: "-webkit-sticky" /* Safari & IE */,position: "sticky",top:"0",zIndex: "1"}}>
                <Card className="mb-1" >
                    <Card.Body>
                        <Row className="mb-2">
                            <Col>
                                <small>{pedido.cliente.cpfcnpj+" - "}</small>
                                <Form.Label style={{paddingRight: "5px"}} >{pedido.cliente.codigo+" - "+pedido.cliente.razao_social}</Form.Label>
                            </Col>
                        </Row>
                        { pedido.cliente.observacao!=""
                        ?<Row className="mb-2">
                            <Col>
                                <Form.Label className="d-flex form-inline col-md-12 col-12">Observações do cliente: 
                                    <Button style={{width:"10px",  justifyContent: "center", display: "flex", alignItems: "center", marginLeft: "10px"}} onClick={()=>{setShowModalCliente(true)}} ><FontAwesomeIcon icon={faMagnifyingGlass} /></Button>
                                </Form.Label>
                            </Col>
                        </Row>
                        :""
                        }
                    </Card.Body>
                </Card>
            </div>
        )
    }

    function onChangedList(e){
        const {value,name} = e.target;
        setFilters({
            ...filters,
            [name]: value
        })
    }

    function voltar(){
        setCurPage(curPage-1);
    }

    function resetInfos(){
        setItensPedido([]);
        setFilters(initialFilters);
        setPedido(initialPedido);
        setCurPage(0);
        setFinalizou(false);
    }

    const BtnActionProd = (props) => {
        const prod=props;
        return (
            <div className="btn-action d-flex" onClick={()=>{clickedRowProd(prod)}} style={{height: "40px", alignItems: "center"}}>
                <div>
                    <Form.Label style={{fontWeight: "bold"}}>{prod.produto.cod_produto+" - "+prod.produto.descricao+" "+prod.produto.descricao_complementar}</Form.Label>
                </div>
            </div>
        );
    };

    var btnNotasBeneficiar = async (dado,criando) => {
        setItemEdicao(dado);
        let data;
        try{
            const response = await api.post("/pedido/beneficiamentos",
            JSON.stringify({cod_cliente: pedido.cliente.codigo}),
            {
                headers: { 'Content-Type': 'application/json' }
            }
            );
            data=response.data;

            setOnline(true);
        }catch(err){
            if(!err.response || err.response.status===0){
                setOnline(false);
            }else{
                confirmAlert({
                    title: "Erro:",
                    message: err.response.data ?err.response.data.error :err.message,
                    buttons: [
                        {
                        label: 'Ok',
                        }
                    ]
                })
            }
        }

        if(data===undefined){
            let cacheInfos=await loadOfflineData();
            if(cacheInfos!==false){
                /* ESSE CÓDIGO É BASEADO NO CÓDIGO IMPLEMENTADO NO BACKEND infos-controlles.js getPreco, IMPORTANTE OS 2 MANTEREM A MESMA LÓGICA, ENTÃO SE ALTERAR UM REVISAR O OUTRO */
                let queryBeneficiamentos=Enumerable.from(cacheInfos.beneficiamentos);

                queryBeneficiamentos=queryBeneficiamentos.where(i=> i.cod_cliente===pedido.cliente.codigo);

                let benefs=queryBeneficiamentos.toArray();

                data={beneficiamentos: benefs}
            }else{
                confirmAlert({
                    title: "Erro:",
                    message: "Não foi possível processar as informações Offline.",
                    buttons: [
                        {
                        label: 'Ok',
                        }
                    ]
                })
            }
        }

        if(data.beneficiamentos.length){
            setBeneficiamentos({beneficiamentos: data.beneficiamentos, criando:criando});
            setShowModalBeneficiamento(true);
        }else{
            if(criando){
                setTimeout(function() {
                    btnQuantidade.current.click();
                }, 0);
            }
        }
    }

    const handleAddItemOrder = async () => {
        let dataItem  = filters
        dataItem.cod_produto =  uuid
        const itensPedidoCopy=[...itensPedido, {...itensPedido.produto,produto: dataItem, idx:itensPedido.length,}];

        setItensPedido(itensPedidoCopy)
        setFilters(initialFilters);
    }


    var clickedRowBeneficiamento = async (benef,criando) => {
        let itensPedidoCopy=[...refItensPedido.current];
        let itemPed=itensPedidoCopy.find(function(item, i){
            return item.idx === itemEdicao.idx;
        })

        itemPed.beneficiamento=benef;

        setItensPedido(itensPedidoCopy)

        if(showModalBeneficiamento===true){
            setShowModalBeneficiamento(false);
        }

        if(criando){
            setTimeout(function() {
                btnQuantidade.current.click();
            }, 0);
        }else
            setItemEdicao({});
    }

    const BtnActionBeneficiamento = (props) => {
        const benef=props;
        return (
            <Row className="btn-action d-flex" onClick={()=>{clickedRowBeneficiamento(benef,beneficiamentos.criando)}} style={{height: "50px", alignItems: "center"}}>
                <Col>
                    <Row>
                        <Col>
                            <Form.Label style={{fontWeight: "bold"}}>{benef.cod_produto+" - "+benef.descricao}</Form.Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label style={{fontWeight: "bold"}}>{"NF: "+benef.notafiscal}</Form.Label>
                        </Col>
                        <Col>
                            <Form.Label style={{fontWeight: "bold"}}>{"Saldo: "+benef.saldo}</Form.Label>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    };

    var clickedRowProd = async (prod) => {
        setFilters(initialFilters);
        let itensPedidoAux=[...itensPedido];
        let dado={idx:itensPedidoAux.length, ...prod, quantidade:'', percentualDesconto:'', percentualOverprice:'', precoManual:''};
        itensPedidoAux.push(dado);
        setItensPedido(itensPedidoAux);

        if(showModalProds===true){
            setShowModalProds(false);
        }

        if(pedido.beneficiar){
            btnNotasBeneficiar(dado,true);
            return;
        }

        setTimeout(function() {
            btnQuantidade.current.click();
        }, 0);
    }

    const handleCloseModalProds = () => {
        setShowModalProds(false);
    }

    const handleCloseModalCliente = () => {
        setShowModalCliente(false);
    }

    const handleCloseModalBeneficiamento = () => {
        setItemEdicao({})
        setShowModalBeneficiamento(false);
    }

    const handleCloseModalFinalizacao = () => {
        setShowModalFinalizacao(false);
    }

    const handleCloseModalObservacao = () => {
        setItemEdicao({});
        setValorEdicao('');
        setShowModalObservacao(false);
    }


    async function imprimePedido(){
        const textosPedido = {
            comp_titulo: "Prezado(a) cliente,",
            comp_texto:"Agradecemos o seu interesse pelos produtos e serviços da Palagi Design. Seguem abaixo os nossos valores, para sua análise e aprovação."
          }
          


        //CONSEGUIR VISUALIZAR EM TELA PARA RECARREGAR APÓS CADA ALTERAÇÃO, VERSÃO DESENVOLVIMENTO
        // const root=ReactDOM.createRoot(document.getElementById('root'));
        // root.render(<PedidoAmostraViewer itensPedido={itensPedido} textosPedido={textoPed} dadosPedido={pedido}/>)

        //GERAR O PDF SEPARADO DA APLICAÇÃO, VERSÃO DE PRODUÇÃO
        const file = await pdf(<PedidoAmostra itensPedido={itensPedido} textosPedido={textosPedido} dadosPedido={pedido}/>).toBlob();

        var a = document.createElement("a");
        document.body.appendChild(a);

        const fileURL = URL.createObjectURL(file);
        a.href = fileURL;
        a.download = moment().format('YYYYMMD')+"_"+pedido.cliente.razao_social;
        a.click();
        a.remove()


        resetInfos()
        handleCloseModalFinalizacao();


        setFinalizou(true);
    }

    const handleConfirmarModalObservacao = () => {
        let itensPedidoCopy=[...refItensPedido.current];
        let itemPed=itensPedidoCopy.find(function(item, i){
            return item.idx === itemEdicao.idx;
        })

        itemPed.observacao=valorEdicao;

        setItensPedido(itensPedidoCopy)

        setValorEdicao('')
        setItemEdicao({})
        
        setShowModalObservacao(false);
    }

    const DadosProdutos = (dado) => {

        var removeItemClicked = (dado) => {
            let itensPedidoCopy=[];
            let pos=0;
            itensPedido.forEach(it =>{
                if(it.idx!==dado.idx){
                    it.idx=pos;
                    pos=pos+1;
                    itensPedidoCopy.push(it);
                }
            });
            setItensPedido(itensPedidoCopy)
        }

        return (
            <div className="btn-action" style={itemSelected===dado.idx?{backgroundColor: "lightGray"}:{}} onClick={()=>{setItemSelected(dado.idx)}}>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <Form.Label style={{fontSize:"11px",paddingRight: "5px",}}>Descrição Produto:</Form.Label>
                                <Form.Label style={{fontWeight: "bold"}}>{dado.produto.descricao}</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label style={{fontSize:"11px",paddingRight: "5px",}}>Quantidade:</Form.Label>
                                <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{dado.produto.quantidade}</Form.Label>
                            </Col>

                            <Col>
                                <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Tamanho Amostra:</Form.Label>

                                { 
                                    dado.produto.tamanhoAmostra !== "Digitar um tamanho" 
                                    ?

                                        <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{dado.produto.tamanhoAmostra}</Form.Label>
                                    :

                                        <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{dado.produto.tamanhoEditado}</Form.Label>
                                }
                                
                            </Col>   
                        </Row>

                        <Row>
                            <Col>
                                <Form.Label style={{fontSize:"11px",paddingRight: "5px",}}>Observação:</Form.Label>
                                <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{dado.produto.observacao}</Form.Label>
                            </Col>
                        </Row>
                    </Col>
                    <Button onClick={()=>{removeItemClicked(dado)}} style={{width:"10px", heigth:"100%", marginRight: "11px", justifyContent: "center", display: "flex", alignItems: "center"}}><FontAwesomeIcon icon={faTrash} /></Button>
                </Row>
            </div>
        );
    };

    function finalizarPedido(){
        let msgErro="";
        if(itensPedido.length===0){
            msgErro="Favor adicionar ao menos 1 item no pedido";
        }

        if(msgErro===""){
            itensPedido.forEach(it =>{
                if(it.quantidade==="" || it.quantidade==="0"){
                    msgErro="Favor identificar a quantidade no item "+it.produto.cod_produto+" "+it.produto.descricao
                    return;   
                }
            });
        }
    

        if(msgErro.length>0){
            confirmAlert({
                title: "Aviso:",
                message: msgErro,
                buttons: [
                    {
                        label: 'Ok',
                    }
                ]
            })
            return;
        }

        imprimePedido()
    }

    return (
    <>
    <SideBar/>
    {
        showModalProds?
        <React.Fragment>
        <Modal as={Modal.Dialog} show scrollable={true}>
            <Modal.Header>
            <Modal.Title className="h6">Seleção de produtos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    prods.map(d => <BtnActionProd key={`${d.produto.id}`} {...d}/>)
                }
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={handleCloseModalProds}>Sair</Button>
            </Modal.Footer>
        </Modal>
        </React.Fragment>
        :""
    }
    {
        showModalCliente?
        <React.Fragment>
        <Modal as={Modal.Dialog} show scrollable={true}>
            <Modal.Header>
            <Modal.Title className="h6">Observações cliente</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Label style={{fontWeight: "bold",whiteSpace: "pre-wrap"}}>{pedido.cliente.observacao}</Form.Label>
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={handleCloseModalCliente}>Sair</Button>
            </Modal.Footer>
        </Modal>
        </React.Fragment>
        :""
    }
    {
        showModalObservacao?
        <React.Fragment>
        <Modal as={Modal.Dialog} show scrollable={true}>
            <Modal.Header>
            <Modal.Title className="h6">Observação</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FloatingLabel label="Observações">
                    <Form.Control autoFocus as="textarea" style={{ height: '150px' }} autoComplete="off" placeholder="" onChange={(e) => setValorEdicao(e.target.value)} value={valorEdicao}/>
                </FloatingLabel>
            </Modal.Body>
            <Modal.Footer style={{justifyContent:'space-between'}}>
                <Button onClick={handleCloseModalObservacao}>Sair</Button>
                <Button onClick={handleConfirmarModalObservacao}>Confirmar</Button>
            </Modal.Footer>
        </Modal>
        </React.Fragment>
        :""
    }
    <main className="content">
        {
            online ?null :<div className="alert alert-warning" role="alert">Você está offline</div>
        }
        {
            sincronizando ?<div className="alert alert-warning" role="alert">Sincronizando</div> :null
        }
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block">
                <h4>Pedidos de Amostra {pedido.filial!==undefined && pedido.filial!=="" ?(pedido.filial==="0"?" Matriz":" Filial"):""}</h4>
            </div>
            {
                curPage>0
                ?
                <Button onClick={voltar}>
                    Voltar
                </Button>
                :""
            }
        </div>
        {
            curPage===0
            ?
            <>
                <Row className="mb-2">
                    <Col >
                        {pedido.cliente.codigo===undefined
                        ?
                        <FiltroCliente callback={clienteSelected} />
                        :
                        <>
                        <Row className="mb-2">
                            <Col >
                                    <small>{pedido.cliente.cpfcnpj+" - "}</small>
                                    <Form.Label style={{fontWeight: "bold"}}>{pedido.cliente.codigo+" - "+pedido.cliente.razao_social}</Form.Label>
                            </Col>
                            <Button style={{width:"10px",  justifyContent: "center", display: "flex", alignItems: "center", marginRight: "10px"}} onClick={()=>{setPedido({...pedido,cliente:{},lista:{}}); setItensPedido([]);}} ><FontAwesomeIcon icon={faTrash} /></Button>
                        </Row>
                        { pedido.cliente.observacao!=""
                            ?<Row className="mb-2">
                                <Col>
                                    <Form.Label className="d-flex form-inline col-md-12 col-12">Observações do cliente: 
                                    <Button style={{width:"10px",  justifyContent: "center", display: "flex", alignItems: "center", marginLeft: "10px"}} onClick={()=>{setShowModalCliente(true)}} ><FontAwesomeIcon icon={faMagnifyingGlass} /></Button>
                                    </Form.Label>
                                </Col>
                            </Row>
                            :""
                        }
                        </>
                        }
                    </Col>
                </Row>

                <Row className="mb-2" style={{marginTop: "10px", justifyContent:'space-between'}} >
                    <Col className="d-flex justify-content-begin">
                    {finalizou
                        ?<Button onClick={() => {
                            resetInfos();
                        }}>
                            Sair
                        </Button>
                        :<Button onClick={() => {
                            Swal.fire({
                                title: 'Cancelar Pedido?',
                                text: "Você perderá as informações preenchidas",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Sim',
                                cancelButtonText: 'Não'
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    resetInfos();
                                }
                            })
                        }}>
                            Cancelar Pedido
                        </Button>
                    }
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <Button onClick={() => {
                            let msgErro="";
                            if(pedido.cliente.razao_social===undefined)
                                msgErro="Favor informar o cliente";

                            if(msgErro.length>0){
                                confirmAlert({
                                    title: "Aviso:",
                                    message: msgErro,
                                    buttons: [
                                        {
                                            label: 'Ok',
                                        }
                                        ]
                                })
                                return;
                            }
                            setCurPage(1);
                            }}>
                                Itens
                            </Button>
                    </Col>
                </Row>
            </>
            :
            <>
                <CabecalhoCliente key={pedido.cliente.codigo}/>
                <Card border="light" className="mb-2">
                    <Card.Body className="">
                        <Row>
                            <Col xs={12} md={6} className="mb-2 mb-md-0" >
                                <FloatingLabel  label="Descrição Produto">
                                    <Form.Control type="text" autoComplete="off" placeholder="" name="descricao" onChange={onChangedList} value={filters.descricao}/>
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} md={6}>
                                <FloatingLabel  label="Quantidade">
                                    <Form.Control type="text" autoComplete="off" placeholder="" name="quantidade" onChange={onChangedList} value={filters.quantidade}/>
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Row className='pt-2'>
                                <Col>
                                    {/* Select dropdown */}
                                    <FloatingLabel  label="Tamanho amostra">
                                    <Form.Select name='tamanhoAmostra' value={filters.tamanhoAmostra} onChange={onChangedList}>
                                        <option value="">Selecione um tamanho</option>
                                        <option value="Amostra 30x40cm">Amostra 30x40cm</option>
                                        <option value="Amostra A4">Amostra A4</option>
                                        <option value="Amostra Visual">Amostra Visual</option>
                                        <option value="Amostra 50x50">Amostra 50x50</option>
                                        <option value="Amostra formato Digital">Amostra formato Digital</option>
                                        <option value="Digitar um tamanho">Digitar um tamanho</option>
                                    </Form.Select>

                                    {/* Input field is shown only when "Digitar uma opção" is selected */}
                                    {filters.tamanhoAmostra === "Digitar um tamanho" && (
                                    <FloatingLabel label="Digite o tamanho desejado">
                                    <Form.Control
                                        type="text"
                                        lab
                                        name="tamanhoEditado"
                                        className='mt-2'
                                        value={filters.tamanhoEditado}
                                        onChange={onChangedList}
                                    />
                                    </FloatingLabel>
                                    )}
                                    </FloatingLabel>
                                </Col>
                            </Row>

                        <Row className='pt-2'>
                            <Col>
                                <FloatingLabel  label="Observação">
                                    <Form.Control as="textarea" style={{ height: '150px' }} autoComplete="off" placeholder="" name="observacao" onChange={onChangedList} value={filters.observacao}/>
                                </FloatingLabel>
                            </Col>
                        </Row>



                        <Row>
                            <Col className="d-flex justify-content-center p-4">
                                <Button disabled={!filters.descricao || !filters.quantidade || (!filters.tamanhoAmostra || (filters.tamanhoAmostra === "Digitar um tamanho" && !filters.tamanhoEditado)  ) } onClick={() => handleAddItemOrder(filters)}>Adicionar Item <FontAwesomeIcon icon={faPlus} /></Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card border="light" className="mb-2">
                    <Card.Body className="">
                        {itensPedido.map(d => <DadosProdutos key={`${d.idx}`} {...d}/>)}
                    </Card.Body>
                </Card>
                <Row className="mb-2">
                    <Col className="d-flex justify-content-begin">
                    {finalizou
                        ?<Button onClick={() => {
                            resetInfos();
                        }}>
                            Sair
                        </Button>
                        :<Button onClick={() => {
                            Swal.fire({
                                title: 'Cancelar Pedido?',
                                text: "Você perderá as informações preenchidas",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Sim',
                                cancelButtonText: 'Não'
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    resetInfos();
                                }
                            })
                        }}>
                            Cancelar Pedido
                        </Button>
                        }
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <Button onClick={finalizarPedido}>
                            Finalizar Pedido
                        </Button>
                    </Col>
                </Row>
            </>
        }
    </main>
    </>
    )
}
