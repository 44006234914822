import React,{useState,useEffect,useRef} from 'react';
import SideBar from '../Components/SideBar';
import api from '../api';
import {confirmAlert} from 'react-confirm-alert'
import { Button, Card,  Modal, Form, Row, Col,FloatingLabel, ToggleButton } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass,faCalculator } from "@fortawesome/free-solid-svg-icons";
import Enumerable from 'linq'
import useAuth from '../Context/hooks/UseAuth';
import moment from "moment"; 


const initialFilters={cod_produto:"",descricao:"",cod_lista:"",nome_lista:"",cod_orcamento:"",pdp:"",descricao_orcamento:"",promo:false,valorini:"",valorfin:""}

export default function Home(){
    const { online,setOnline } = useAuth();
    const { loadOfflineData, sincronizando, ceilValue, decimals } = useAuth();

    const [showFilter,setShowFilter]=useState(true);
    const [showModal, setShowModal] = useState(false);
    const [filters, setFilters] = useState(initialFilters);
    const [dados, setDados] = useState({});
    const [prods, setProds] = useState([]);
    const [itemSelected, setItemSelected] = useState(0);
    const [valorDesconto, setValorDesconto] = useState('');
    const [showModalCalculator,setShowModalCalculator] = useState(false);
    const [showModalEstoque,setShowModalEstoque] = useState(false);
    const [lotesEstoque,setLotesEstoque] = useState([]);
    const [reservas, setReservas] = useState([]);
    const [showModalReservas,setShowModalReservas] = useState(false);

    const refShowFilter = useRef(showFilter);
    const refShowModal = useRef(showModal);
    const refShowModalCalculator = useRef(showModalCalculator);
    const refShowModalEstoque = useRef(showModalEstoque);
    const refShowModalReservas = useRef(showModalReservas);


    var zoomItemClicked = (dado) => {
        setDados({"produto":dados.produto,
                    "precos":[dado]});
    }

    var btnCalculaPrecoDesconto = (dado) => {
        setShowModalCalculator(true);
    }

    function zoomEstoqueClicked(e){
        setLotesEstoque(e.lotes);
        setShowModalEstoque(true)
    }

    async function btnModalReservaClicked(e){
        /*let data;
        try{
            const response = await api.post("/estoque/reservas",
            JSON.stringify({cod_produto: dados.produto.cod_produto,cod_local:e.cod_local,nome_local:e.nome_local}),
            {
                headers: { 'Content-Type': 'application/json' }
            }
            );
            data=response.data;
            setOnline(true);
        }catch(err){
            if(!err.response || err.response.status===0){
                setOnline(false);
            }else{
                confirmAlert({
                    title: "Erro:",
                    message: err.response.data ?err.response.data.error :err.message,
                    buttons: [
                        {
                            label: 'Ok',
                        }
                        ]
                })
            }
        }
        if(data===undefined){
            let cacheInfos=await loadOfflineData();
            if(cacheInfos!==false){
                let queryReservas=Enumerable.from(cacheInfos.reservas);
                
                queryReservas=queryReservas.where(i=> i.cod_produto==dados.produto.cod_produto && i.cod_local==e.cod_local && i.nome_local==e.nome_local);

                data={reservas: queryReservas.toArray()}
            }
        }*/

        let reservas=[];
        dados.produto.reservas.forEach(res =>{
            if(res.cod_local===e.cod_local && res.nome_local===e.nome_local){
                let resCliente=reservas.find(l=> l.cod_cliente===e.cod_cliente);
                if(resCliente===undefined){
                    reservas.push(res);
                }else{
                    resCliente.reserva=resCliente.reserva+res.reserva
                }
            }
        });

        setReservas(reservas);
        setShowModalReservas(true)
    }


    const DadosEstoque = (e) => {
        console.log("ccccc", e)
        return (
            <>
            <Row>
                <Col>
                    {e.cod_local>0?
                    <Row>
                        <Col>
                            <Form.Label style={{paddingRight: "5px"}} >{e.cod_local}</Form.Label>
                            <Form.Label >
                                {
                                    moment(e.nome_local, "YYYY-MM-DD HH:mm:ss.SSS", true).isValid()
                                    ? moment(e.nome_local, "YYYY-MM-DD HH:mm:ss.SSS").format("DD/MM/YY")
                                    : e.nome_local
                                }
                            </Form.Label>
                        </Col>
                    </Row>
                    :""}
                    <Row>
                        <Col md={4} className="col-4">
                            <Form.Group >
                                <Form.Label style={{paddingRight: "5px"}}>Saldo: </Form.Label>
                                <Form.Label >{e.saldo.toLocaleString()}</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col md={4} className="col-4">
                            <Form.Group>
                                <Form.Label style={{paddingRight: "5px"}}>Reserva: </Form.Label>
                                <Form.Label >{e.reserva.toLocaleString()}
                                {
                                    e.reserva!="" && e.reserva>0
                                    ?
                                    <Button variant="link" onClick={()=>{btnModalReservaClicked(e)}} style={{padding:"0px",paddingLeft:"5px", color:"var(--cinzaPalagi)"}}><FontAwesomeIcon icon={faMagnifyingGlass}/></Button>
                                    :""
                                }
                                </Form.Label>
                            </Form.Group>
                        </Col>
                        <Col md={4} className="col-4">
                            <Form.Group >
                                <Form.Label style={{paddingRight: "5px"}}>Livre: </Form.Label>
                                <Form.Label >{e.livre.toLocaleString()}</Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
                {
                e.lotes.length
                ?<Button onClick={()=>{zoomEstoqueClicked(e)}} style={{width:"10px", heigth:"100%", marginRight: "11px", justifyContent: "center", display: "flex", alignItems: "center"}}><FontAwesomeIcon icon={faMagnifyingGlass} /></Button>
                :""
                }
            </Row>
            <hr/>
            </>
        )
    };

    const DadosPrecos = (dado) => {
        if((dado.cod_orcamento!=="" && dado.cod_orcamento>0) || (dado.pdp!=="" && dado.pdp!==undefined && dado.pdp.length>0) || (dado.descricao_orcamento!==undefined && dado.descricao_orcamento.length>0)) {
            return (
                <div className="btn-action" style={itemSelected===dado.id?{backgroundColor: "lightGray"}:{}} onClick={()=>{listaClicked(dado.id)}}>
                    <Row>
                        <Col>
                            <Form.Label style={{fontWeight: "bold"}}>{dado.descricao}</Form.Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Orçamento:</Form.Label>
                            <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{dado.cod_orcamento}</Form.Label>
                        </Col>

                        <Col>
                            <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>PDP:</Form.Label>
                            <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{dado.pdp}</Form.Label>
                        </Col>

                        <Col>
                            <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Preço: R$</Form.Label>
                            <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{ceilValue(dado.preco).toLocaleString(undefined, {maximumFractionDigits:decimals})}</Form.Label>
                        </Col>

                        <Col>
                            <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>ICMS:</Form.Label>
                            {dado.comissao>0
                            ?<Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>R$
                                <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{ceilValue(dado.icms).toLocaleString(undefined, {maximumFractionDigits:decimals})}</Form.Label>
                            </Form.Label>
                            :""}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>12%: R$</Form.Label>
                            <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{ceilValue(dado.icmsdoze).toLocaleString(undefined, {maximumFractionDigits:decimals})}</Form.Label>
                        </Col>

                        <Col>
                            <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>7%: R$</Form.Label>
                            <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{ceilValue(dado.icmssete).toLocaleString(undefined, {maximumFractionDigits:decimals})}</Form.Label>
                        </Col>

                        <Col>
                            <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>0%: R$</Form.Label>
                            <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{ceilValue(dado.icmszero).toLocaleString(undefined, {maximumFractionDigits:decimals})}</Form.Label>
                        </Col>

                        <Col>
                            <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>22%: R$</Form.Label>
                            <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{ceilValue(dado.icmsvintedois).toLocaleString(undefined, {maximumFractionDigits:decimals})}</Form.Label>
                        </Col>
                    </Row>
                </div>
            );
        }else{
            return (
                <div className="btn-action" style={itemSelected===dado.id?{backgroundColor: "lightGray"}:{}} onClick={()=>{listaClicked(dado.id)}}>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <Form.Label style={{fontWeight: "bold"}}>{dado.cod_lista+" - "+dado.nome_lista}</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Preço: R$</Form.Label>
                                    <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{ceilValue(dado.preco).toLocaleString(undefined, {maximumFractionDigits:decimals})}</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Comissão:</Form.Label>
                                    {dado.comissao>0
                                        ?
                                        <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{dado.comissao.toLocaleString(undefined, {maximumFractionDigits:decimals})}
                                            <Form.Label style={{fontSize:"11px"}}>%</Form.Label>
                                        </Form.Label>
                                    :""
                                    }
                                </Col>
                                <Col>
                                    <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Desc. Máx.:</Form.Label>
                                    
                                    <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{dado.desconto_maximo.toLocaleString(undefined, {maximumFractionDigits:decimals})}
                                        <Form.Label style={{fontSize:"11px"}}>%</Form.Label>
                                    </Form.Label>
                                    {
                                        dados.precos.length===1 && dado.desconto_maximo>0.01
                                        ?<Button variant="link" onClick={()=>{btnCalculaPrecoDesconto(dado)}} style={{padding:"0px", color:"var(--cinzaPalagi)"}}><FontAwesomeIcon icon={faCalculator}/></Button>
                                        :""
                                    }
                                    
                                </Col>
                                <Col style={{padding:"0px"}}>
                                    <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>UN:</Form.Label>
                                    <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{dado.unidade}</Form.Label>
                                </Col>
                            </Row>
                        </Col>
                        {
                        dados.precos.length>1?
                        <Button onClick={()=>{zoomItemClicked(dado)}} style={{width:"10px", heigth:"100%", marginRight: "11px", justifyContent: "center", display: "flex", alignItems: "center"}}><FontAwesomeIcon icon={faMagnifyingGlass} /></Button>
                        :""
                        }
                    </Row>
                </div>
            );
        }
    };

    const listaClicked = (id) => {
        setItemSelected(id)
    }

    const BtnActionProd = (props) => {
    const prod=props;
    return (
        <div className="btn-action d-flex" onClick={()=>{clickedRowProd(prod)}} style={{height: "40px", alignItems: "center"}}>
            <div>
                <Form.Label style={{fontWeight: "bold"}}>{prod.produto.cod_produto+" - "+prod.produto.descricao}</Form.Label>
            </div>
        </div>
    );
    };

    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.key === 'Escape') {
              event.preventDefault();
              if(refShowFilter.current===false){
                    if(refShowModalCalculator.current===true){
                        handleCloseModalCalculator();
                    }else if(refShowModalEstoque.current===true){
                        handleCloseModalEstoque();
                    }else if(refShowModalReservas.current===true){
                        handleCloseModalReservas();
                    }else{
                        voltar();
                    }
              }else{
                if(refShowModal.current===true){
                    handleCloseModal();
                }else{
                    limparFiltros()
                }
              }
            }
          };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
          document.removeEventListener('keydown', keyDownHandler);
        };
    },[]);

    useEffect(() => {
        refShowFilter.current = showFilter;
        refShowModal.current = showModal;
        refShowModalCalculator.current = showModalCalculator;
        refShowModalEstoque.current = showModalEstoque;
        refShowModalReservas.current = showModalReservas;
      }, [showFilter,showModal,showModalCalculator,showModalEstoque,showModalReservas]);

    const buscaProdutos = async (e) => {
        e.preventDefault();

        let fil={
            ...filters,
            descricao: filters.descricao==="" ?undefined :filters.descricao.split(' '),
            nome_lista: filters.nome_lista==="" ?undefined :filters.nome_lista.split(' '),
            descricao_orcamento: filters.descricao_orcamento==="" ?undefined :filters.descricao_orcamento.split(' ')
        }

        let existeFiltro=false;

        for(const [key, value] of Object.entries(fil)){
            if(value!==undefined && (value.length>0 || value===true)){
                existeFiltro=true;
                break;
            }
        }

        if(existeFiltro){

            if((fil.cod_orcamento!=="" && fil.cod_orcamento>0) || (fil.pdp!=="" && fil.pdp.length>0) || (fil.descricao_orcamento!==undefined && fil.descricao_orcamento.length>0)){
                let data;
                try{
                    const response = await api.post("/consultapreco/orcamento",
                    JSON.stringify(fil),
                    {
                        headers: { 'Content-Type': 'application/json' }
                    }
                    );
                    data=response.data;
                    setOnline(true);
                }catch(err){
                    if(!err.response || err.response.status===0){
                        setOnline(false);
                    }else{
                        confirmAlert({
                            title: "Erro:",
                            message: err.response.data ?err.response.data.error :err.message,
                            buttons: [
                                {
                                    label: 'Ok',
                                }
                                ]
                        })
                    }
                }

                //SIGNIFICA QUE ESTÁ OFFLINE
                if(data===undefined){
                    let cacheInfos=await loadOfflineData();
                    if(cacheInfos!==false){
                        /* ESSE CÓDIGO É BASEADO NO CÓDIGO IMPLEMENTADO NO BACKEND infos-controlles.js getOrcamento, IMPORTANTE OS 2 MANTEREM A MESMA LÓGICA, ENTÃO SE ALTERAR UM REVISAR O OUTRO */
                        let queryOrcamento=Enumerable.from(cacheInfos.orcamento);
                        
                        if(fil.cod_orcamento!==undefined && fil.cod_orcamento.length>0){
                            queryOrcamento=queryOrcamento.where(i=> i.cod_orcamento==fil.cod_orcamento);
                        }

                        if(fil.pdp!==undefined && fil.pdp.length>0){
                            queryOrcamento=queryOrcamento.where(i=> i.pdp==fil.pdp);
                        }

                        if(fil.descricao_orcamento!==undefined && fil.descricao_orcamento.length>0){
                            fil.descricao_orcamento.forEach(desc =>{
                                if(desc.length>0){
                                    queryOrcamento=queryOrcamento.where(i => i.descricao.toUpperCase().indexOf(desc.toUpperCase())>=0);
                                }
                            });
                        }

                        let result=queryOrcamento.toArray();

                        let orcs=[]
                        result.forEach(res => {
                            orcs.push(res)
                        })
                        data={orcamentos: orcs}
                    }
                }

                if(data.orcamentos.length>0){
                    setDados(data);
                    setShowFilter(false)
                }else{
                    confirmAlert({
                        title: "Erro:",
                        message: "Nada encontrado.",
                        buttons: [
                            {
                                label: 'Ok',
                            }
                        ]
                    })
                }
            }else{
                let data;
                try{
                    const response = await api.post("/consultapreco/preco",
                    JSON.stringify(fil),
                    {
                        headers: { 'Content-Type': 'application/json' }
                    }
                    );
                    data=response.data;

                    setOnline(true);
                }catch(err){
                    if(!err.response || err.response.status===0){
                        setOnline(false);
                    }else{
                        confirmAlert({
                            title: "Erro:",
                            message: err.response.data ?err.response.data.error :err.message,
                            buttons: [
                                {
                                label: 'Ok',
                                }
                            ]
                        })
                    }
                }

                //SIGNIFICA QUE ESTÁ OFFLINE
                if(data===undefined){
                    let cacheInfos=await loadOfflineData();
                    if(cacheInfos!==false){
                        /* ESSE CÓDIGO É BASEADO NO CÓDIGO IMPLEMENTADO NO BACKEND infos-controlles.js getPreco, IMPORTANTE OS 2 MANTEREM A MESMA LÓGICA, ENTÃO SE ALTERAR UM REVISAR O OUTRO */
                        let arrayRetorno=[];
                        {
                            let queryPrecos=Enumerable.from(cacheInfos.precos);
                            
                            if(fil.cod_produto!==undefined && fil.cod_produto.length>0){
                                queryPrecos=queryPrecos.where(i=> i.cod_produto==fil.cod_produto);
                            }
                            if(fil.descricao!==undefined && fil.descricao.length>0){
                                fil.descricao.forEach(desc =>{
                                    if(desc.length>0){
                                        queryPrecos=queryPrecos.where(i => (i.descricao+' '+i.descricao_complementar).toUpperCase().indexOf(desc.toUpperCase())>=0);
                                    }
                                });
                            }
                            if(fil.cod_lista!==undefined && fil.cod_lista.length>0){
                                queryPrecos=queryPrecos.where(i=> i.cod_lista==fil.cod_lista)
                            }
                            if(fil.nome_lista!==undefined && fil.nome_lista.length>0){
                                fil.nome_lista.forEach(desc =>{
                                    if(desc.length>0){
                                        queryPrecos=queryPrecos.where(i => i.nome_lista.toUpperCase().indexOf(desc.toUpperCase())>=0);
                                    }
                                });
                            }
                            if(fil.valorini!==undefined && fil.valorini.length>0){
                                queryPrecos=queryPrecos.where(i=> i.preco>=parseFloat(fil.valorini))
                            }
                            if(fil.valorfin!==undefined && fil.valorfin.length>0){
                                queryPrecos=queryPrecos.where(i=> i.preco<=parseFloat(fil.valorfin))
                            }
                            if(fil.promo!==undefined && fil.promo){
                                queryPrecos=queryPrecos.where(i=> i.promo==1);
                            }

                            queryPrecos=queryPrecos.orderBy(i => i.cod_produto).thenBy(i => i.cod_lista);

                            let precos=queryPrecos.toArray();

                            let cod_produto=-1;
                            let objItem={}
                            precos.forEach(res => {
                                if(cod_produto!==res.cod_produto){
                                    cod_produto=res.cod_produto;
                                    objItem={
                                        produto: {
                                            id: res.id, //PASSADO O ID DO PREÇO
                                            cod_produto: res.cod_produto,
                                            descricao: res.descricao,
                                            descricao_complementar: res.descricao_complementar,
                                            estoques: [],
                                            reservas: [],
                                        },
                                        precos: []
                                    }
                                    arrayRetorno.push(objItem);
                                }
                                objItem.precos.push({
                                    id: res.id,
                                    cod_lista: res.cod_lista,
                                    nome_lista: res.nome_lista,
                                    unidade: res.unidade,
                                    preco: res.preco,
                                    comissao: res.comissao,
                                    desconto_maximo: res.desconto_maximo,
                                    promo: res.promo
                                })
                            })
                        }

                        {
                            let estoque=[];

                            let queryProds=Enumerable.from(cacheInfos.estoque);

                            let arrayProd=[];

                            if(arrayRetorno.length>0){
                                arrayRetorno.forEach(objItem =>{
                                    for(const  prod of queryProds.where(p => p.cod_produto===objItem.produto.cod_produto).toArray()){
                                        arrayProd.push(prod);
                                    }
                                })
                            }

                            let filtrou=false;

                            if(fil.cod_produto!==undefined && fil.cod_produto.length>0){
                                queryProds=queryProds.where(i=> i.cod_produto==fil.cod_produto);
                                filtrou=true;
                            }
                            if(fil.descricao!==undefined && fil.descricao.length>0){
                                fil.descricao.forEach(desc =>{
                                    if(desc.length>0){
                                        queryProds=queryProds.where(i => i.descricao.toUpperCase().indexOf(desc.toUpperCase())>=0);
                                    }
                                });
                                filtrou=true;
                            }

                            if(filtrou){
                                for(const  prod of queryProds.toArray()){
                                    arrayProd.push(prod);
                                }
                            }

                            estoque = arrayProd.filter(function(item, pos) {
                                return arrayProd.indexOf(item) == pos;
                            })

                            let cod_produto=-1;
                            let objItem=undefined;
                            estoque.forEach(e=>{
                                if(cod_produto!=e.cod_produto){
                                    cod_produto=e.cod_produto;
                                    objItem=arrayRetorno.find(p => p.produto.cod_produto===cod_produto);
                                    if(objItem===undefined){
                                        objItem={
                                            produto: {
                                                id: e.id, //PASSADO O ID DO PREÇO
                                                cod_produto: e.cod_produto,
                                                descricao: e.descricao,
                                                descricao_complementar: e.descricao_complementar,
                                                estoques: [],
                                                reservas:[],
                                            },
                                            precos: []
                                        }
                                        arrayRetorno.push(objItem);
                                    }
                                }
                                if(objItem!==undefined){
                                    objItem.produto.estoques.push({
                                        id: e.id,
                                        cod_local: e.cod_local,
                                        nome_local: e.nome_local,
                                        saldo: e.saldo,
                                        reserva: e.reserva,
                                        livre: e.livre,
                                        lote: e.lote
                                    });
                                }
                            });
                        }

                        {
                            let reservas=[];

                            let queryProds=Enumerable.from(cacheInfos.reservas);

                            let arrayProd=[];

                            if(arrayRetorno.length>0){
                                arrayRetorno.forEach(objItem =>{
                                    for(const  prod of queryProds.where(p => p.cod_produto===objItem.produto.cod_produto).toArray()){
                                        arrayProd.push(prod);
                                    }
                                })
                            }

                            let filtrou=false;

                            if(fil.cod_produto!==undefined && fil.cod_produto.length>0){
                                queryProds=queryProds.where(i=> i.cod_produto==fil.cod_produto);
                                filtrou=true;
                            }
                            if(fil.descricao!==undefined && fil.descricao.length>0){
                                fil.descricao.forEach(desc =>{
                                    if(desc.length>0){
                                        queryProds=queryProds.where(i => i.descricao.toUpperCase().indexOf(desc.toUpperCase())>=0);
                                    }
                                });
                                filtrou=true;
                            }

                            if(filtrou){
                                for(const  prod of queryProds.toArray()){
                                    arrayProd.push(prod);
                                }
                            }

                            reservas = arrayProd.filter(function(item, pos) {
                                return arrayProd.indexOf(item) == pos;
                            })

                            let cod_produto=-1;
                            let objItem=undefined;
                            reservas.forEach(e=>{
                                if(cod_produto!=e.cod_produto){
                                    cod_produto=e.cod_produto;
                                    objItem=arrayRetorno.find(p => p.produto.cod_produto===cod_produto);
                                    if(objItem===undefined){
                                        objItem={
                                            produto: {
                                                id: e.id, //PASSADO O ID DO PREÇO
                                                cod_produto: e.cod_produto,
                                                descricao: e.descricao,
                                                descricao_complementar: e.descricao_complementar,
                                                estoques: [],
                                                reservas:[],
                                            },
                                            precos: []
                                        }
                                        arrayRetorno.push(objItem);
                                    }
                                }
                                if(objItem!==undefined){
                                    objItem.produto.reservas.push({
                                        id: e.id,
                                        cod_cliente: e.cod_cliente,
                                        razao_social: e.razao_social,
                                        cod_local: e.cod_local,
                                        nome_local: e.nome_local,
                                        reserva: e.reserva,
                                        lote: e.lote
                                    });
                                }
                            });
                        }

                        data={produtos: arrayRetorno}
                    }else{
                        confirmAlert({
                            title: "Erro:",
                            message: "Não foi possível processar as informações Offline.",
                            buttons: [
                                {
                                label: 'Ok',
                                }
                            ]
                        })
                    }
                }
                if(data!==undefined){
                    if(data.produtos.length>1) {
                        //SIGNIFICA QUE ENCONTROU MAIS DE UM PRODUTO, É NECESSÁRIO ABRIR O MODAL DE SELEÇÃO PARA DEPOIS BUSCAR O PREÇO
                        setProds(data.produtos);
                        setShowModal(true)
                    }else if(data.produtos.length===1){
                        //ENCONTROU SOMENTE 1 PRODUTO VÁLIDO, ENTÃO PODE JÁ MOSTRAR A TELA COM AS INFORMAÇÕES
                        setDados(data.produtos[0]);
                        setShowFilter(false)
                    }else{
                        confirmAlert({
                            title: "Erro:",
                            message: "Nada encontrado.",
                            buttons: [
                                {
                                label: 'Ok',
                                }
                            ]
                        })
                    }
                }
            }
        }else{
            confirmAlert({
                title: "Erro:",
                message: "Não foram enviados dados de filtro",
                buttons: [
                    {
                        label: 'Ok',
                    }
                    ]
            })
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
    }

    const handleCloseModalCalculator = () => {
        setValorDesconto('')
        setShowModalCalculator(false);
    }

    const handleCloseModalEstoque = () => {
        setShowModalEstoque(false);
    }

    const handleCloseModalReservas = () => {
        setShowModalReservas(false);
    }

    function onChanged(e){
        const {value,name} = e.target;

        const re = /^[0-9/\b]+$/;
        if (value === '' || re.test(value)) {
            setFilters({
                ...filters,
                [name]: value
              })
        }
    }

    function onChangedCalculator(e){
        const {value} = e.target;
        const re = /^[0-9,/\b]+$/;
        if(value === ''){
            setValorDesconto('');
        }else if (re.test(value)) {
            if(parseFloat(value.replaceAll('.','').replaceAll(',','.'))<=dados.precos[0].desconto_maximo){
                setValorDesconto(value);
            }
        }
    }

    function onChangedList(e){
        const {value,name} = e.target;
        setFilters({
            ...filters,
            [name]: value
        })
    }

    function onChangedCheck(e){
        const {checked,name} = e.target;
        
        setFilters({
            ...filters,
            [name]: checked
        })
    }

    function voltar(){
        setDados({})
        setShowFilter(true)
    }

    function limparFiltros(){
        setFilters(initialFilters)
    }

    var clickedRowProd = async (prod) => {
        setDados(prod);
        
        setShowFilter(false)
        setShowModal(false);
    }

    function getEstoqueAgrupado(){
        let estoquesLocal=[];
        dados.produto.estoques.forEach(e => {
            let local=estoquesLocal.find(l=> l.cod_local===e.cod_local && l.nome_local===e.nome_local);
            if(local===undefined){
                local={
                    id: e.id,
                    cod_local: e.cod_local,
                    nome_local: e.nome_local,
                    saldo: e.saldo,
                    reserva: e.reserva,
                    livre: e.livre,
                    lotes:[],
                    //QUANDO EXISTE REGISTRO DE ESTOQUE É CONSIDERADO AS INFORMAÇÕES BASEADO O ESTOQUE, QUANDO NÃO EXISTE É CONSIDERADO A RESERVA
                    criadoReserva: false
                }
                estoquesLocal.push(local)
            }else{
                local.saldo=local.saldo+e.saldo;
                local.reserva=local.reserva+e.reserva;
                local.livre=local.livre+e.livre;
            }
            local.lotes.push(
                {
                    lote: e.lote,
                    saldo: e.saldo,
                    reserva: e.reserva,
                    livre: e.livre,
                }
            );
        });

        dados.produto.reservas.forEach(e => {
            let local=estoquesLocal.find(l=> l.cod_local===e.cod_local && l.nome_local===e.nome_local);
            if(local===undefined){
                local={
                    id: e.id,
                    cod_local: e.cod_local,
                    nome_local: e.nome_local,
                    saldo: 0.0,
                    reserva: e.reserva,
                    livre: 0.0,
                    lotes:[],
                    //QUANDO EXISTE REGISTRO DE ESTOQUE É CONSIDERADO AS INFORMAÇÕES BASEADO O ESTOQUE, QUANDO NÃO EXISTE É CONSIDERADO A RESERVA
                    criadoReserva: true
                }
                estoquesLocal.push(local)
            }else{
                if(local.criadoReserva)
                    local.reserva=local.reserva+e.reserva;
            }
            if(local.criadoReserva){
                local.lotes.push(
                    {
                        lote: e.lote,
                        saldo: 0.0,
                        reserva: e.reserva,
                        livre: 0.0,
                    }
                );
            }
        });
        return estoquesLocal;
    }

    return (
        <>
        <SideBar/>

        {
            showModal?
            <React.Fragment>
            <Modal as={Modal.Dialog} centered show scrollable={true}>
                <Modal.Header>
                <Modal.Title className="h6">Seleção de produtos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        prods.map(d => <BtnActionProd key={`${d.produto.id}`} {...d}/>)
                    }
                </Modal.Body>
                <Modal.Footer>
                <Button onClick={handleCloseModal}>Sair</Button>
                </Modal.Footer>
            </Modal>
            </React.Fragment>
            :""
        }
        {
            showModalCalculator?
            <React.Fragment>
            <Modal as={Modal.Dialog} centered show>
                <Modal.Header>
                <Modal.Title className="h6">Calcular desconto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{paddingBottom: "10px"}}>
                        <Col>
                            <Form.Label style={{paddingRight: "5px"}}>Preço Atual: R$ </Form.Label>
                            <Form.Label style={{paddingRight: "5px"}}>{ceilValue(dados.precos[0].preco).toLocaleString(undefined, {maximumFractionDigits:decimals})}</Form.Label>
                        </Col>
                    </Row>
                    <Row style={{paddingBottom: "10px"}}>
                        <Col>
                            <Form.Label style={{paddingRight: "5px"}}>Preço Desconto: R$ </Form.Label>
                            <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{valorDesconto!=='' ?ceilValue(dados.precos[0].preco-(dados.precos[0].preco*(parseFloat(valorDesconto.replaceAll('.','').replaceAll(',','.'))/100.0))).toLocaleString(undefined, {maximumFractionDigits:decimals}):ceilValue(dados.precos[0].preco).toLocaleString(undefined, {maximumFractionDigits:decimals})}</Form.Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FloatingLabel label="Desconto (%)">
                                <Form.Control autoFocus type="text" autoComplete="off" inputMode="numeric" placeholder="" onChange={onChangedCalculator} value={valorDesconto}/>
                            </FloatingLabel>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseModalCalculator}>Sair</Button>
                </Modal.Footer>
            </Modal>
            </React.Fragment>
            :""
        }
        {
            showModalEstoque?
            <React.Fragment>
            <Modal as={Modal.Dialog} centered show>
                <Modal.Header>
                <Modal.Title className="h6">Lotes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        lotesEstoque.map(l=>{
                            return (
                            <>
                                <Row>
                                    <Col>
                                        <Form.Group >
                                            <Form.Label  style={{paddingRight: "5px"}}>Lote: </Form.Label>
                                            <Form.Label className="col-md-6 col-12">{l.lote.toLocaleString()}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4} className="col-4">
                                        <Form.Group >
                                            <Form.Label  style={{paddingRight: "5px"}}>Saldo: </Form.Label>
                                            <Form.Label className="col-md-6 col-12">{l.saldo.toLocaleString()}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} className="col-4">
                                        <Form.Group >
                                            <Form.Label style={{paddingRight: "5px"}}>Reserva: </Form.Label>
                                            <Form.Label className="col-md-6 col-12">{l.reserva.toLocaleString()}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} className="col-4">
                                        <Form.Group >
                                            <Form.Label style={{paddingRight: "5px"}}>Livre: </Form.Label>
                                            <Form.Label className="col-md-6 col-12">{l.livre.toLocaleString()}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <hr/>
                            </>
                            )
                        })
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseModalEstoque}>Sair</Button>
                </Modal.Footer>
            </Modal>
            </React.Fragment>
            :""
        }
        {
            showModalReservas?
            <React.Fragment>
            <Modal as={Modal.Dialog} centered show>
                <Modal.Header>
                <Modal.Title className="h6">Reservas</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        reservas.map(r=>{
                            return (
                            <>
                                <Row>
                                    <Col>
                                        <Form.Label  style={{paddingRight: "5px"}}>Cliente: </Form.Label>
                                        <Form.Label >{r.cod_cliente+" - "+r.razao_social}</Form.Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4} className="col-4">
                                        <Form.Label  style={{paddingRight: "5px"}}>Reserva: </Form.Label>
                                        <Form.Label >{r.reserva.toLocaleString()}</Form.Label>
                                    </Col>
                                </Row>
                                <hr/>
                            </>
                            )
                        })
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseModalReservas}>Sair</Button>
                </Modal.Footer>
            </Modal>
            </React.Fragment>
            :""
        }

        <main className="content">
            {
                online ?null :<div className="alert alert-warning" role="alert">Você está offline</div>
            }
            {
                sincronizando ?<div className="alert alert-warning" role="alert">Sincronizando</div> :null
            }
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block">
                    <h4>Consulta de Preços</h4>
                </div>
                {
                    showFilter?
                    <Button className={showFilter?"":"d-none"} onClick={() => limparFiltros()}>
                        Limpar Filtros
                    </Button>
                    :
                    <Button className={showFilter?"d-none":""} onClick={() => voltar()}>
                        Voltar
                    </Button>
                }
            </div>
            <Form onSubmit={buscaProdutos} className={showFilter?"":"d-none"}>
                <Row>
                    <Col md={12} className="mb-3 d-flex justify-content-center">
                        <Button type="submit" className="col-md-3 col-12" >Buscar</Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} className="mb-3">
                        <FloatingLabel label="Código produto">
                            <Form.Control type="text" autoComplete="off" inputMode="numeric" placeholder="" name="cod_produto" onChange={onChanged} value={filters.cod_produto}/>
                        </FloatingLabel>
                    </Col>
                    <Col md={9} className="mb-3">
                        <FloatingLabel label="Descrição Produto">
                            <Form.Control type="text" autoComplete="off" placeholder="" name="descricao" onChange={onChangedList} value={filters.descricao}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} className="mb-3">
                        <FloatingLabel label="Código Lista">
                            <Form.Control type="text" autoComplete="off" inputMode="numeric" placeholder="" name="cod_lista" onChange={onChanged} value={filters.cod_lista}/>
                        </FloatingLabel>
                    </Col>
                    <Col className="mb-3">
                        <FloatingLabel label="Nome Lista">
                            <Form.Control type="text" autoComplete="off" placeholder="" name="nome_lista" onChange={onChangedList} value={filters.nome_lista}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FloatingLabel className="mb-3" label="Valor Inicial">
                            <Form.Control type="text" autoComplete="off" inputMode="numeric" placeholder="" name="valorini" onChange={onChanged} value={filters.valorini}/>
                        </FloatingLabel>
                    </Col>
                    <Col>
                        <FloatingLabel className="mb-3" label="Valor Final">
                            <Form.Control type="text" autoComplete="off" inputMode="numeric" placeholder="" name="valorfin" onChange={onChanged} value={filters.valorfin}/>
                        </FloatingLabel>
                    </Col>
                    <Col md={2} className="mb-3">
                        <ToggleButton
                            id="btnPromocionais"
                            type="checkbox"
                            variant=""
                            name="promo"
                            checked={filters.promo}
                            onChange={onChangedCheck}
                        >
                            Promocionais
                        </ToggleButton>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} className="mb-3">
                        <FloatingLabel label="Código Orçamento">
                            <Form.Control type="text" autoComplete="off" inputMode="numeric" placeholder="" name="cod_orcamento" onChange={onChanged} value={filters.cod_orcamento}/>
                        </FloatingLabel>
                    </Col>
                    <Col md={3} className="mb-3">
                        <FloatingLabel label="PDP">
                            <Form.Control type="text" autoComplete="off" placeholder="" name="pdp" onChange={onChanged} value={filters.pdp}/>
                        </FloatingLabel>
                    </Col>
                    <Col md={6} className="mb-3">
                        <FloatingLabel label="Descrição Orçamento">
                            <Form.Control type="text" autoComplete="off" placeholder="" name="descricao_orcamento" onChange={onChangedList} value={filters.descricao_orcamento}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="mb-3 d-flex justify-content-center">
                        <Button type="submit" className="col-md-3 col-12" >Buscar</Button>
                    </Col>
                </Row>
            </Form>

            <div className={showFilter?"d-none":""}>
                {dados.produto===undefined ?"":
                    <>
                    <Card className="mb-1" style={{position: "-webkit-sticky" /* Safari & IE */,position: "sticky",top:"0",zIndex: "1"}}>
                        <Card.Body>
                            <Row >
                                <Col>
                                    <Form.Label style={{paddingRight: "5px"}}>Produto: </Form.Label>
                                    <Form.Label style={{paddingRight: "5px"}} >{dados.produto.cod_produto}</Form.Label>
                                    <Form.Label >{dados.produto.descricao+(dados.produto.descricao_complementar!==undefined ?" "+dados.produto.descricao_complementar :"")}</Form.Label>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card className="mb-1" >
                        <Card.Body>
                        {
                            getEstoqueAgrupado().map(e => <DadosEstoque key={`${e.id}`} {...e}/>)
                        }
                        </Card.Body>
                    </Card>
                    </>
                }
                <Card border="light" className="">
                    <Card.Body className="">
                        {
                            (dados.precos!==undefined
                            ?dados.precos.map(d => <DadosPrecos key={`${d.id}`} {...d}/>)
                            :(dados.orcamentos!==undefined
                                ?dados.orcamentos.map(d => <DadosPrecos key={`${d.id}`} {...d}/>)
                                :""))
                        }
                        <Card.Footer className="d-flex justify-content-end">
                            <Button className={showFilter?"d-none":""} onClick={() => voltar()}>
                                Voltar
                            </Button>
                        </Card.Footer>
                    </Card.Body>
                </Card>
            </div>

        </main>
        </>
    )
}
