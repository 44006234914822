import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AppRoutes from './routes';

import { AuthProvider } from './Context/AuthProvider.js'

function App() {
  return (
    <BrowserRouter basename={process.env.REACT_APP_PUBLIC_ADDR}>
      <AuthProvider>
        <Routes>
          <Route path="/*" element={<AppRoutes />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
