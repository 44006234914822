import {IndexedDB} from "./Components/Indexeddb"

const dbversion=6;
const dataBaseName='palagidb'

function addObjectStore(objectName,db){
    const store=db.createObjectStore(objectName,{KeyPath: "id",unique:true});
    store.createIndex("id",["id"],{unique: false});
}

//const palagidb = {};
const palagidb = new IndexedDB(
dataBaseName,
dbversion,
(db, oldVersion, newVersion) => {

    console.log(`upgrading database from ${ oldVersion } to ${ newVersion }`);

    if(oldVersion===0){
        addObjectStore("precos",db);
        addObjectStore("estoque",db);
        addObjectStore("transito", db);
        addObjectStore("orcamento",db);
        addObjectStore("tecido",db);
        addObjectStore("vacum",db);
        addObjectStore("mestico",db);
        addObjectStore("clientes",db);
        addObjectStore("textosproposta",db);
        addObjectStore("bases",db);
        addObjectStore("vendas",db);
        
        addObjectStore("reservas",db);
        addObjectStore("beneficiamentos",db);
        addObjectStore("textospedido",db);
        addObjectStore("listaspreco",db);
    }else {
        if(oldVersion<4){
            addObjectStore("reservas",db);
            addObjectStore("beneficiamentos",db);
            addObjectStore("textospedido",db);
        }
        if(oldVersion<5){
            addObjectStore("listaspreco",db);
        }
        if(oldVersion < 6){
            addObjectStore("transito", db);
        }
    }

    /*const storePrecos=db.createObjectStore("precos",{KeyPath: "id",unique:true});
    storePrecos.createIndex("cod_lista_cod_produto",["cod_lista","cod_produto"],{unique: false});
    storePrecos.createIndex("cod_produto",["cod_produto"],{unique: false});

    const storeEstoque=db.createObjectStore("estoque",{KeyPath: "id",unique:true});
    storeEstoque.createIndex("cod_produto",["cod_produto"],{unique: true});

    const storeOrcamento=db.createObjectStore("orcamento",{KeyPath: "id",unique:true});
    storeOrcamento.createIndex("cod_orcamento",["cod_orcamento"],{unique: false});
    storePrecos.createIndex("cod_orcamento_cod_produto",["cod_orcamento","cod_produto"],{unique: false});*/

});

export default palagidb;