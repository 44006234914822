//export function IndexedDB() {
export class IndexedDB {
  // connect to IndexedDB database

  constructor(dbName, dbVersion, dbUpgrade) {
        new Promise((resolve, reject) => {
            this.db = null;
            if (!('indexedDB' in window)) reject('not supported');

            const dbOpen = indexedDB.open(dbName, dbVersion);

            if (dbUpgrade) {

                dbOpen.onupgradeneeded = e => {

                    dbUpgrade(dbOpen.result, e.oldVersion, e.newVersion);

                };

            }

            dbOpen.onsuccess = () => {
                this.db = dbOpen.result;

                resolve( this.db );

            };

            dbOpen.onerror = e => {
                console.log('onerror')
                reject(`IndexedDB error: ${ e.target.errorCode }`);

            };

        });
    }
}
