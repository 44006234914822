import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import LogoPalagi from "../assets/img/brand/palagi_design_que_transforma_login.png";
import LogoPrismma from "../assets/img/brand/prismma_textil.png";
import gota from "../assets/img/gota.jpg";
import moment from 'moment/min/moment-with-locales';


// Registrar a função de hifenização
Font.registerHyphenationCallback(word => {
  const middle = Math.floor(word.length / 2);
  const parts = word.length === 1 ? [word] : [word.substr(0, middle), word.substr(middle)];
  // Verifique no console as partes da palavra
  console.log(word, parts);

  return parts;
});


const { REACT_APP_APP_ENV } = process.env;
moment.locale('pt-br');
var decimals = 2;

const borderColor = 'black'

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    fontFamily: 'Helvetica',
    fontSize: 10,
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    lineHeight: 1.5,
  },
  section: {
    //flexGrow: 1
    flexDirection: 'row',
  },
  ViewTextContainerInfoPedido: {

  },
  textContainerInfoPedido: {
    textAlign: "center",
    flexWrap: "wrap",
    overflowWrap: "break-word",

  }, 
  sectionTitle: {
    padding: 2,
    //flexGrow: 1
    flexDirection: 'row',
    fontSize: 12,
    borderWidth: 1,
    borderColor: 'black',
    backgroundColor: '#c0c0c0',
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderColor: 'black',
  },
  tableTitleContainer: {
    flexDirection: 'row',
    borderBottomColor: 'black',
    backgroundColor: '#c0c0c0',
    color: '#800000',
    borderBottomWidth: 1,
    alignItems: 'center',
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1
  },
  tableGroupContainter:{
    flexDirection: 'column',
    borderBottomColor: 'black',
    borderBottomWidth: 1,
  },
  tableRowContainter:{
    flexDirection: 'row',
    alignItems: 'center',
    fontStyle: 'bold',
    textAlign: 'center',
    marginTop: "2px"
  },
  tableRowObsContainter:{
    paddingLeft: "5px",
    paddingTop: "2px",
  },
  tableFooterContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 12,
    fontStyle: 'bold',
  }
});

function ceilValue(val){
  return (Math.ceil(val*Math.pow(10,decimals))/Math.pow(10,decimals))
}

function getTexto(val){
  return val!==undefined && val.length?val:" ";
}

function getValorItem(item) {
  let valor;
  // Verifica se preço manual existe e faz a conversão correta
  if (parseFloat(item.precoManual.replace(',', '.')) > 0) {
    valor = parseFloat(item.precoManual.replace('.', '').replace(',', '.'));
  } else if (parseFloat(item.percentualDesconto.replace(',', '.')) > 0) {
    // Calcula desconto
    const desconto = parseFloat(item.percentualDesconto.replace(',', '.')) / 100;
    valor = item.precos[0].preco - (item.precos[0].preco * desconto);
  } else if (parseFloat(item.percentualOverprice.replace(',', '.')) > 0) {
    // Calcula overprice
    const overprice = parseFloat(item.percentualOverprice.replace(',', '.')) / 100;
    valor = item.precos[0].preco + (item.precos[0].preco * overprice);
  } else {
    valor = item.precos[0].preco; // Se nada for aplicável, usa o preço base
  }

  return parseFloat(valor.toFixed(2)); // Converte o valor para número com duas casas decimais
}



function getTotalitem(item, valor) {
  // Se valor não for passado, obtemos o valor usando a função getValorItem
  if (valor === undefined) {
    valor = parseFloat(getValorItem(item)); // Removido replaceAll, já que getValorItem retorna um número
  }

  // Converte quantidade em número, se for string, e multiplica pelo valor
  const quantidade = parseFloat(item.quantidade.replace(',', '.')); // Converte a quantidade de string para número, caso tenha vírgulas

  // Multiplicando a quantidade pelo valor
  const total = quantidade * valor;
  // Arredondando o resultado para 2 casas decimais e retornando como número
  return parseFloat(total.toFixed(2));
}



function getTotalIPIitem(item, total) {
  if (total === undefined) {
    total = getTotalitem(item);
  }

  if (item.precos[0].ipi > 0) {
    // Calculando o total de IPI e arredondando para 2 casas decimais
    const ipiTotal = total * item.precos[0].ipi / 100.0;
    return parseFloat(ipiTotal.toFixed(2));
  }

  return 0.0;
}

function getSubTotal(itensPedido) {
  let tot = 0.0;
  itensPedido.forEach(item => {
    tot += getTotalitem(item); // Supondo que getTotalitem já retorne o valor correto
  });

  return parseFloat(tot.toFixed(2));
}

function getTotal(itensPedido){
  let tot=0.0;
  itensPedido.forEach(item =>{
    let totalItem=getTotalitem(item);
    tot=tot+totalItem+getTotalIPIitem(item,totalItem);
  });
  return tot;
}

  const PedidoViewer = ({itensPedido,textosPedido,dadosPedido}) => (
    <PDFViewer style={styles.viewer}>
      <Pedido itensPedido={itensPedido} textosPedido={textosPedido} dadosPedido={dadosPedido}/>
    </PDFViewer>
  )

  // Create Document Component
  const Pedido = ({itensPedido,textosPedido,dadosPedido}) => (
      <Document>
        <Page size="A4" style={styles.page}>



          {/*CABEÇALHO*/}
          <View style={{...styles.section,justifyContent:'space-between'}}>
            <div style={{wordWrap: "break-word",width: "52%",display:"flex",alignItems: "left"}}>
                <Text style={{fontWeight: "bold", fontSize: "15"}} >{textosPedido.titulo+(dadosPedido.filial!==undefined && dadosPedido.filial!=="" ?(dadosPedido.filial==="0"?" Matriz":" Filial"):"")}</Text>
                <Text style={{fontWeight: "bold"}}>{"Data de Emissão: "+moment().format('dddd, D MMMM YYYY')}</Text>
              <Text style={{fontWeight: "bold"}}>{"Data de Faturamento: "+moment(dadosPedido.datafaturamento).format('dddd, D MMMM YYYY')}</Text>
            </div>
            <Image style={{height: 50,width: "20%"}} src={REACT_APP_APP_ENV==="palagi"?LogoPalagi:LogoPrismma} />
          </View>
          
          {/*CLIENTE*/}
          <View style={{...styles.sectionTitle}}>
            <Text style={{fontWeight: "bold"}}>CLIENTE</Text>
          </View>
          <View style={{...styles.section, borderWidth: 1,borderColor: 'black', paddingRight: '0px'}}>
            <div style={{width: '11%',alignItems: "left"}}>
              <Text style={{fontWeight: "bold"}}>Cód/Cliente:</Text>
              <Text style={{fontWeight: "bold"}}>CNPJ:</Text>
              <Text style={{fontWeight: "bold"}}>Transp.:</Text>
              <Text style={{fontWeight: "bold"}}>OC Cliente:</Text>
              <Text style={{fontWeight: "bold"}}>Cod. lista:</Text>
              <Text style={{fontWeight: "bold"}}>Tubete:</Text>
              <Text style={{fontWeight: "bold"}}>Observação:</Text>
            </div>
            <div style={{width: '55%'}}>
                <Text>{dadosPedido.cliente.codigo+" - "+getTexto(dadosPedido.cliente.razao_social)}</Text>
                <Text>{getTexto(dadosPedido.cliente.cpfcnpj)+"       I.E:"+getTexto(dadosPedido.cliente.inscricaoestadual)}</Text>
                <Text>{dadosPedido.transporte.tipo==="1"?"Próprio":getTexto(dadosPedido.transporte.transportadora)}</Text>
                <Text>{getTexto(dadosPedido.occliente)}</Text>
                <Text>{dadosPedido.lista.codigo}</Text>
                <Text>{getTexto(dadosPedido.tubete.codigo)+" "+getTexto(dadosPedido.tubete.descricao)}</Text>
                <Text>{getTexto(dadosPedido.observacao)}</Text>
            </div>

            <div style={{width: '12%',alignItems: "left"}}>
              <Text style={{fontWeight: "bold"}}>Vendedor:</Text>
              <Text style={{fontWeight: "bold"}}>Beneficiar:</Text>
              <Text style={{fontWeight: "bold"}}>Cond. Pag.:</Text>
              <Text style={{fontWeight: "bold"}}>Cód. Espec.:</Text>
            </div>
            <div style={{width: '22%'}}>
                <Text>{getTexto(dadosPedido.cliente.representante)}</Text>
                <Text>{dadosPedido.beneficiar?"Sim":"Não"}</Text>
                <Text>{getTexto(dadosPedido.condicaopagamento)}</Text>
                <Text>{getTexto(dadosPedido.codespecsaida)}</Text>
                <Text>{"( "+(dadosPedido.faturamento==="1"?"X":"")+" ) Junto ( "+(dadosPedido.faturamento==="2"?"X":"")+" ) Separado"}</Text>
            </div>
          </View>

          {/* COMPLEMENTO */}
          <View style={{...styles.section, flexDirection:"column"}}>
            <Text style={{fontWeight: "bold", fontSize: 12}}>{getTexto(textosPedido.comp_titulo)}</Text>
            <Text >{getTexto(textosPedido.comp_texto)}</Text>
          </View>


          {/*ITENS*/}
          <View style={styles.tableContainer}>
            <View style={styles.tableTitleContainer}>
              <Text style={{borderColor: borderColor,borderRightWidth: 1,width:"5%"}}>Item</Text>
              {
                dadosPedido.imprimirdesconto
                ?<Text style={{borderColor: borderColor,borderRightWidth: 1,width:"40%"}}>Descrição</Text>
                :<Text style={{borderColor: borderColor,borderRightWidth: 1,width:"49%"}}>Descrição</Text>
              }
              <Text style={{borderColor: borderColor,borderRightWidth: 1,width:"8%"}}>Unid.</Text>
              <Text style={{borderColor: borderColor,borderRightWidth: 1,width:"8%"}}>Quant</Text>
              <Text style={{borderColor: borderColor,borderRightWidth: 1,width:"9%"}}>R$ Unit.</Text>
              {
                dadosPedido.imprimirdesconto
                ?<Text style={{borderColor: borderColor,borderRightWidth: 1,width:"9%"}}>Desconto</Text>
                :""
              }
              <Text style={{borderColor: borderColor,borderRightWidth: 1,width:"10%"}}>Sub Total</Text>
              <Text style={{borderColor: borderColor,borderRightWidth: 1,width:"8%"}}>IPI</Text>
              <Text style={{width:"8%"}}>Total</Text>
            </View>
            {
              itensPedido.map(item =>{    
                let valor=getValorItem(item);
                let total=getTotalitem(item,valor);
                let ipi=getTotalIPIitem(item,total);
                return (
                  <View style={{...styles.tableGroupContainter}}>
                    <View style={styles.section} key={parseInt(item.idx)+1}>
                      <View style={{ borderColor: borderColor,borderRightWidth: 1,width:"5%", paddingLeft: "2.5px",paddingRight: "2.5px", justifyContent: "center"}}>
                        <Text style={{...styles.textContainerInfoPedido,}}>{parseInt(item.idx)+1}</Text>
                      </View>
                      <View style={{ borderColor: borderColor,borderRightWidth: 1,width:"49%", paddingLeft: "2.5px",paddingRight: "2.5px", justifyContent: "center"}}>
                        {
                          dadosPedido.imprimirdesconto
                        ?<Text style={{...styles.textContainerInfoPedido, borderColor: borderColor,borderRightWidth: 1,width:"40%", paddingLeft: "2.5px",paddingRight: "2.5px", fontSize: "9 "}}>{getTexto(item.produto.cod_produto+" - "+item.produto.descricao)}</Text>
                          :<Text style={{...styles.textContainerInfoPedido, fontSize: "9", width: "95%" }}>{getTexto(item.produto.cod_produto+" - "+item.produto.descricao)}</Text>
                        }
                      </View>
                      <View style={{ borderColor: borderColor, borderRightWidth: 1, width: "8%", paddingLeft: "2.5px", paddingRight: "2.5px", justifyContent: "center" }}>
                        <Text style={{ ...styles.textContainerInfoPedido }}>{getTexto(item.precos[0].unidade)}</Text>
                      </View>

                      <View style={{ borderColor: borderColor, borderRightWidth: 1, width: "8%", paddingLeft: "2.5px", paddingRight: "2.5px", justifyContent: "center" }}>
                        <Text style={{ ...styles.textContainerInfoPedido }}>
                          {getTexto(item.quantidade.toLocaleString(undefined, { maximumFractionDigits: decimals }))}
                        </Text>
                      </View>

                      <View style={{ borderColor: borderColor, borderRightWidth: 1, width: "9%", paddingLeft: "2.5px", paddingRight: "2.5px", justifyContent: "center" }}>
                        <Text style={{ ...styles.textContainerInfoPedido }}>
                          {getTexto(
                            !isNaN(parseFloat(getValorItem(item))) 
                              ? parseFloat(getValorItem(item)).toLocaleString('pt-BR', { minimumFractionDigits: decimals, maximumFractionDigits: decimals })
                              : getValorItem(item)
                          ).toLocaleString(undefined, { minimumFractionDigits: decimals, maximumFractionDigits: decimals })}
                        </Text>
                      </View>

                      {dadosPedido.imprimirdesconto ? (
                        <View style={{ borderColor: borderColor, borderRightWidth: 1, width: "9%", paddingLeft: "2.5px", paddingRight: "2.5px", justifyContent: "center" }}>
                          <Text style={{ ...styles.textContainerInfoPedido }}>
                            {getTexto(
                              item.percentualDesconto !== "" 
                                ? item.percentualDesconto.toLocaleString(undefined, { maximumFractionDigits: decimals }) + "%" 
                                : " "
                            )}
                          </Text>
                        </View>
                      ) : null}

                      <View style={{ borderColor: borderColor, borderRightWidth: 1, width: "10%", paddingLeft: "2.5px", paddingRight: "2.5px", justifyContent: "center" }}>
                        <Text style={{ ...styles.textContainerInfoPedido }}>
                          {getTexto(total.toLocaleString(undefined, { minimumFractionDigits: decimals, maximumFractionDigits: decimals }))}
                        </Text>
                      </View>

                      <View style={{ borderColor: borderColor, borderRightWidth: 1, width: "8%", paddingLeft: "2.5px", paddingRight: "2.5px", justifyContent: "center"}}>
                        <Text style={{ ...styles.textContainerInfoPedido }}>
                          {item.precos[0].ipi > 0 
                            ? getTexto(item.precos[0].ipi.toLocaleString(undefined, { maximumFractionDigits: decimals })) + "%" 
                            : " "}
                        </Text>
                      </View>

                      <View style={{ width: "8%", paddingLeft: "2.5px", paddingRight: "2.5px", justifyContent: "center" }}>
                        <Text style={{ ...styles.textContainerInfoPedido }}>
                          {getTexto((total + ipi).toLocaleString(undefined, { minimumFractionDigits: decimals, maximumFractionDigits: decimals }))}
                        </Text>
                      </View>
                    </View>
                    {
                      item.beneficiamento!==undefined || (item.observacao!==undefined && item.observacao!=="")
                      ?
                      <View style={styles.tableRowObsContainter}>
                        {item.beneficiamento!==undefined
                          ?<Text>{"Beneficiar: "+"NF: "+item.beneficiamento.notafiscal+" / "+item.beneficiamento.cod_produto+" - "+item.beneficiamento.descricao}</Text>
                          :""
                        }
                        {item.observacao!==undefined && item.observacao!==""
                          ?<Text>{"Observação: "+item.observacao}</Text>
                          :""
                        }
                      </View>
                      :""
                    }
                  </View>
                )
              })
            }
          </View>
          <View style={styles.tableFooterContainer}>
            <Text style={{width: '85%',textAlign: 'right'}}>Sub Total:</Text>
            <Text style={{width: '15%',textAlign: 'center'}}>{"R$ "+getSubTotal(itensPedido).toLocaleString(undefined, {minimumFractionDigits:decimals,maximumFractionDigits:decimals})}</Text>
          </View>
          <View style={styles.tableFooterContainer}>
            <Text style={{width: '85%',textAlign: 'right'}}>Total:</Text>
            <Text style={{width: '15%',textAlign: 'center'}}>{"R$ "+getTotal(itensPedido).toLocaleString(undefined, {minimumFractionDigits:decimals,maximumFractionDigits:decimals})}</Text>
          </View>



          {/*INFORMAÇÕES ADICIONAIS*/}
          <View style={{...styles.sectionTitle}}>
            <Text style={{fontWeight: "bold"}}>INFORMAÇÕES ADICIONAIS</Text>
          </View>
          <View style={{...styles.section, borderWidth: 1,borderColor: 'black'}}>
            <div style={{width: '20%',alignItems: "center"}}>
              <Text style={{fontWeight: "bold"}}>Prazo de entrega:</Text>
              <Text style={{fontWeight: "bold"}}>Impostos inclusos:</Text>
              <Text style={{fontWeight: "bold"}}>Frete:</Text>
              <Text style={{fontWeight: "bold"}}>Industrialização:</Text>
              <Image style={{height: 50}} src={gota} />
            </div>
            <div style={{width: '80%'}}>
                <Text>{getTexto(textosPedido.infoads_prazoentrega)}</Text>
                <Text>{getTexto(textosPedido.infoads_impostos)}</Text>
                <Text>{getTexto(textosPedido.infoads_frete)}</Text>
                <Text>{getTexto(textosPedido.infoads_industrializacao)}</Text>
                <Text style={{fontWeight: "bold"}}>{getTexto(textosPedido.infoads_lavagem)}</Text>
            </div>
          </View>
          <View style={{...styles.section, borderWidth: 1,borderColor: 'black',flexDirection: 'column'}}>
            <Text style={{fontWeight: "bold"}}>{getTexto(textosPedido.infoads_agradecimento)}</Text>
          </View>







          {/*RODAPÉ*/}
          <View style={{...styles.section, flexDirection:"column",alignItems: "center"}}>
            <Text style={{fontWeight: "bold"}}>{getTexto(textosPedido.rodape_razaosocial)}</Text>
            <Text >{getTexto(textosPedido.rodape_endereco)}</Text>
            <Text >{getTexto(textosPedido.rodape_contato)}</Text>
          </View>

        </Page>
      </Document>
  );

export {Pedido,PedidoViewer};
