import React from 'react';
import { Routes, Route, useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from "./Context/hooks/UseAuth";


import Login from './Pages/Login';
import Home from './Pages/Home';
import User from './Pages/User';
import Upload from './Pages/Upload';
import Missing from './Pages/Missing';
import Unauthorized from './Pages/Unauthorized';
import FormacaoPreco from './Pages/FormacaoPreco';
import TextoProposta from './Pages/TextoProposta';
import ConsultaVendas from './Pages/ConsultaVendas';
import ConsultaBeneficiamentos from './Pages/ConsultaBeneficiamentos';
import CadastroPedido from './Pages/CadastroPedido';
import TextoPedido from './Pages/TextoPedido';
import CadastroAmostra from './Pages/CadastroAmostra';

const NIVEIS = {
  'User': 0,
  'Admin': 1
}

const RequireAuth = ({ NivelPermitido }) => {
  const { auth,loading } = useAuth();
  const location = useLocation();

  if(loading){
    return <h1>Carregando...</h1>
  }

  return (
      /*auth?.roles?.find(role => allowedRoles?.includes(role))
          ? <Outlet />
          : auth?.user
              ? <Navigate to="/unauthorized" state={{ from: location }} replace />
              : <Navigate to="/login" state={{ from: location }} replace />*/
        auth.nivel>=NivelPermitido
        ? <Outlet />
        : auth.id
            ? <Navigate to="/unauthorized" state={{ from: location }} replace />
            : <Navigate to="/login" state={{ from: location }} replace />
  );
}

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/">
          <Route path="login" element={<Login />} />
          <Route path="unauthorized" element={<Unauthorized />} />
          
          <Route element={<RequireAuth NivelPermitido={[NIVEIS.User]} />}>
            <Route path="/" element={<Home />} />
            <Route path="/formacaopreco" element={<FormacaoPreco />} />
            <Route path="/consultavendas" element={<ConsultaVendas />} />
            <Route path="/consultabeneficiamentos" element={<ConsultaBeneficiamentos />} />
            <Route path="/cadastropedido" element={<CadastroPedido />} />
            <Route path="/cadastroamostra" element={<CadastroAmostra />} />
          </Route>

          <Route element={<RequireAuth NivelPermitido={[NIVEIS.Admin]} />}>
            <Route path="user" element={<User />} />
            <Route path="upload" element={<Upload />} />
            <Route path="textoproposta" element={<TextoProposta />} />
            <Route path="textopedido" element={<TextoPedido />} />
          </Route>


          {/* DEIXAR SEMPRE POR ÚLTIMO */}
          <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
}
