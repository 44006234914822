import React,{useState,useEffect,useRef} from 'react';
import ReactDOM from 'react-dom';
import SideBar from '../Components/SideBar';
import FiltroCliente from '../Components/FiltroCliente'; 
import { Button, Modal, Form, Row, Col,FloatingLabel, ToggleButton, ButtonGroup, Card } from 'react-bootstrap';
import api from '../api';
import useAuth from '../Context/hooks/UseAuth';
import {confirmAlert} from 'react-confirm-alert'
import Enumerable from 'linq'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass,faTrash,faCalculator,faPencil } from "@fortawesome/free-solid-svg-icons";
import Swal from 'sweetalert2'
import moment from 'moment/min/moment-with-locales';
import {pdf} from '@react-pdf/renderer';
import {Pedido,PedidoViewer} from './Pedido';
import BtnModalCalculatorPedido from '../Components/BtnModalCalculatorPedido'; 
import FiltroListaPreco from '../Components/FiltroListaPreco'; 
import UseStateCallback from '../Components/StateCallback'; 


const initialFilters={cod_produto:"",descricao:""}
const initialPedido={cliente:{},lista:{},transporte:{tipo:"0"},tubete:{id:0},faturamento:"0",imprimirdesconto:false}
const initialButtonText="Cancelar Pedido";

const { REACT_APP_APP_ENV } = process.env;

moment.locale('pt-br');


export default function CadastroPedido(){
    const { online,setOnline, loadOfflineData, sincronizando, ceilValue,decimals } = useAuth();
    const [itensPedido, setItensPedido] = useState([]);
    const [pedido, setPedido] = useState(initialPedido);
    const [itemSelected, setItemSelected] = useState(0);
    const [filters, setFilters] = useState(initialFilters);
    const [prods, setProds] = useState([]);
    const [showModalProds, setShowModalProds] = useState(false);
    const [carregou, setCarregou] = useState(false);
    const [curPage, setCurPage] = useState(0);
    const [showModalCliente, setShowModalCliente] = useState(false);
    const [showModalBeneficiamento,setShowModalBeneficiamento] = useState(false);
    const [showModalFinalizacao,setShowModalFinalizacao] = useState(false);
    const [showModalObservacao,setShowModalObservacao] = useState(false);
    const [valorEdicao, setValorEdicao] = useState('');
    const [itemEdicao, setItemEdicao] = useState({});
    const [beneficiamentos, setBeneficiamentos] = useState([]);
    const [tubetes] = useState([{id:0,codigo:"Não",descricao:"informado"},{id:1,codigo:"Tubete N1",descricao:"Papelão 16 x 2,5 x 1650mm"},{id:2,codigo:"Tubete N2",descricao:"Papelão 40 x 1,8 x 1700mm"},{id:3,codigo:"Tubete N3",descricao:"Papelão 77 x 2,5 x 1650mm"}]);
    const [textosPedido, setTextosPedido] = useState(false);
    const [finalizou, setFinalizou] = useState(false);

    const refItensPedido = useRef(itensPedido);
    const refShowModalProds = useRef(showModalProds);
    const refShowModalCliente = useRef(showModalCliente);
    const refShowModalBeneficiamento = useRef(showModalBeneficiamento);
    const refShowModalFinalizacao = useRef(showModalFinalizacao);
    const refShowModalObservacao = useRef(showModalObservacao);

    const btnQuantidade = React.useRef();
    const filtroListaPreco = React.useRef();

    useEffect(() => {
        if(carregou===true && showModalProds===false){
            localStorage.setItem('pedido',JSON.stringify(
                {
                    itensPedido: itensPedido,
                    pedido: pedido,
                    curPage:curPage
                }
            ));
        }
    },[itensPedido,pedido,showModalProds,curPage]);


    useEffect(() => {

        let cache=localStorage.getItem("pedido");
        if(cache!==undefined && cache!=="" && cache!==null){
            cache=JSON.parse(cache);
            setPedido(cache.pedido);
            setItensPedido(cache.itensPedido);
            setCurPage(cache.curPage);
        }
        setCarregou(true)

        const keyDownHandler = (event) => {
            if (event.key === 'Escape') {
                event.preventDefault();

                if(refShowModalProds.current===true){
                    handleCloseModalProds();
                }else if(refShowModalCliente.current===true){
                    handleCloseModalCliente();
                }else if(refShowModalBeneficiamento.current===true){
                    handleCloseModalBeneficiamento();
                }else if(refShowModalFinalizacao.current===true){
                    handleCloseModalFinalizacao();
                }else if(refShowModalObservacao.current===true){
                    handleCloseModalObservacao();
                }
            }
          };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
          document.removeEventListener('keydown', keyDownHandler);
        };
    },[]);

    useEffect(() => {
        refItensPedido.current = itensPedido;
    }, [itensPedido]);

    useEffect(() => {
        refShowModalProds.current = showModalProds;
    }, [showModalProds]);

    useEffect(() => {
        refShowModalCliente.current = showModalCliente;
    }, [showModalCliente]);

    useEffect(() => {
        refShowModalBeneficiamento.current = showModalBeneficiamento;
    }, [showModalBeneficiamento]);

    useEffect(() => {
        refShowModalFinalizacao.current = showModalFinalizacao;
    }, [showModalFinalizacao]);

    useEffect(() => {
        refShowModalObservacao.current = showModalObservacao;
    }, [showModalObservacao]);

    async function clienteSelected(cliente){
        let pedidoAux={...pedido,cliente:cliente}
        if(cliente.inscricaoestadual===""){
            pedidoAux={...pedidoAux,lista: await filtroListaPreco.current.getDadosLista("5022")};
        }
        setPedido(pedidoAux);
        if(cliente.observacao!==""){
            setShowModalCliente(true);
        }
    }

    async function listaPrecoSelected(lista){
        setPedido({...pedido,lista:lista});
    }

    const CabecalhoCliente = () => {
        return (
            <div style={{position: "-webkit-sticky" /* Safari & IE */,position: "sticky",top:"0",zIndex: "1"}}>
                <Card className="mb-1" >
                    <Card.Body>
                        <Row className="mb-2">
                            <Col>
                                <small>{pedido.cliente.cpfcnpj+" - "}</small>
                                <Form.Label style={{paddingRight: "5px"}} >{pedido.cliente.codigo+" - "+pedido.cliente.razao_social}</Form.Label>
                            </Col>
                        </Row>
                        { pedido.cliente.observacao!=""
                        ?<Row className="mb-2">
                            <Col>
                                <Form.Label className="d-flex form-inline col-md-12 col-12">Observações do cliente: 
                                    <Button style={{width:"10px",  justifyContent: "center", display: "flex", alignItems: "center", marginLeft: "10px"}} onClick={()=>{setShowModalCliente(true)}} ><FontAwesomeIcon icon={faMagnifyingGlass} /></Button>
                                </Form.Label>
                            </Col>
                        </Row>
                        :""
                        }
                    </Card.Body>
                </Card>
            </div>
        )
    }

    const buscaProdutos = async (cod_prooduto) => {
        let fil={
            ...filters,
            descricao: filters.descricao==="" ?undefined :filters.descricao.split(' ')
        }

        if(cod_prooduto!==undefined){
            fil.cod_produto=cod_prooduto;
        }
        
        fil.cod_lista=pedido.lista.codigo;

        fil.exigepreco=true;

        let existeFiltro=false;

        for(const [value] of Object.entries(fil)){
            if(value!==undefined && (value.length>0 || value===true)){
                existeFiltro=true;
                break;
            }
        }

        if(existeFiltro){

            let data;
            try{
                const response = await api.post("/consultapreco/preco",
                JSON.stringify(fil),
                {
                    headers: { 'Content-Type': 'application/json' }
                }
                );
                data=response.data;

                setOnline(true);
            }catch(err){
                if(!err.response || err.response.status===0){
                    setOnline(false);
                }else{
                    confirmAlert({
                        title: "Erro:",
                        message: err.response.data ?err.response.data.error :err.message,
                        buttons: [
                            {
                            label: 'Ok',
                            }
                        ]
                    })
                }
            }

            if(data===undefined){
                let cacheInfos=await loadOfflineData();
                if(cacheInfos!==false){
                    /* ESSE CÓDIGO É BASEADO NO CÓDIGO IMPLEMENTADO NO BACKEND infos-controlles.js getPreco, IMPORTANTE OS 2 MANTEREM A MESMA LÓGICA, ENTÃO SE ALTERAR UM REVISAR O OUTRO */
                    let arrayRetorno=[];
                    {
                        let queryPrecos=Enumerable.from(cacheInfos.precos);
                        
                        if(fil.cod_produto!==undefined && fil.cod_produto.length>0){
                            queryPrecos=queryPrecos.where(i=> i.cod_produto===fil.cod_produto);
                        }
                        if(fil.descricao!==undefined && fil.descricao.length>0){
                            fil.descricao.forEach(desc =>{
                                if(desc.length>0){
                                    queryPrecos=queryPrecos.where(i => (i.descricao+' '+i.descricao_complementar).toUpperCase().indexOf(desc.toUpperCase())>=0);
                                }
                            });
                        }

                        if(fil.cod_lista!==undefined && fil.cod_lista>0){
                            queryPrecos=queryPrecos.where(i=> i.cod_lista===fil.cod_lista)
                        }

                        queryPrecos=queryPrecos.orderBy(i => i.cod_produto).thenBy(i => i.cod_lista);

                        let precos=queryPrecos.toArray();

                        let cod_produto=-1;
                        let objItem={}
                        precos.forEach(res => {
                            if(cod_produto!==res.cod_produto){
                                cod_produto=res.cod_produto;
                                objItem={
                                    produto: {
                                        id: res.id, //PASSADO O ID DO PREÇO
                                        cod_produto: res.cod_produto,
                                        descricao: res.descricao,
                                        descricao_complementar: res.descricao_complementar,
                                        estoques: []
                                    },
                                    precos: []
                                }
                                arrayRetorno.push(objItem);
                            }
                            objItem.precos.push({
                                id: res.id,
                                cod_lista: res.cod_lista,
                                nome_lista: res.nome_lista,
                                unidade: res.unidade,
                                preco: res.preco,
                                comissao: res.comissao,
                                desconto_maximo: res.desconto_maximo,
                                ipi: res.ipi
                            })
                        })
                    }

                    //EXIGE PREÇO, OU SEJA SÓ DEVE BUSCAR OS PRODUTOS QUE LOCALIZOU PREÇO ANTERIORMENTE
                    if(arrayRetorno.length){
                        let estoque=[];

                        let queryProds=Enumerable.from(cacheInfos.estoque);

                        let arrayProd=[];

                        //ESSA PARTE É DIFERENTE DA CONSULTA PREÇO, POIS AQUI É REMOVIDO QUEM NÃO TEM ESTOQUE
                        let arrayRetornoAux=[];
                        arrayRetorno.forEach(objItem =>{
                            let temEstoque=false;
                            for(const  prod of queryProds.where(p => p.cod_produto===objItem.produto.cod_produto).toArray()){
                                temEstoque=true;
                                arrayProd.push(prod);
                            }
                            if(temEstoque===false){
                                arrayRetornoAux.push(objItem);
                            }
                        })

                        arrayRetorno=arrayRetornoAux;

                        /*let filtrou=false;

                        if(fil.cod_produto!==undefined && fil.cod_produto.length>0){
                            queryProds=queryProds.where(i=> i.cod_produto==fil.cod_produto);
                            filtrou=true;
                        }
                        if(fil.descricao!==undefined && fil.descricao.length>0){
                            fil.descricao.forEach(desc =>{
                                if(desc.length>0){
                                    queryProds=queryProds.where(i => i.descricao.toUpperCase().indexOf(desc.toUpperCase())>=0);
                                }
                            });
                            filtrou=true;
                        }

                        if(filtrou){
                            for(const  prod of queryProds.toArray()){
                                arrayProd.push(prod);
                            }
                        }*/

                        estoque = arrayProd.filter(function(item, pos) {
                            return arrayProd.indexOf(item) === pos;
                        })

                        let cod_produto=-1;
                        let objItem=undefined;
                        estoque.forEach(e=>{
                            if(cod_produto!==e.cod_produto){
                                cod_produto=e.cod_produto;
                                objItem=arrayRetorno.find(p => p.produto.cod_produto===cod_produto);
                                if(objItem===undefined){
                                    objItem={
                                        produto: {
                                            id: e.id, //PASSADO O ID DO PREÇO
                                            cod_produto: e.cod_produto,
                                            descricao: e.descricao,
                                            descricao_complementar: e.descricao_complementar,
                                            estoques: []
                                        },
                                        precos: []
                                    }
                                    arrayRetorno.push(objItem);
                                }else{
                                    /*objItem.produto.descricao_complementar= e.descricao_complementar;
                                    objItem.produto.subgrupo = e.subgrupo;*/
                                }
                            }
                            if(objItem!==undefined){
                                objItem.produto.estoques.push({
                                    id: e.id,
                                    cod_local: e.cod_local,
                                    nome_local: e.nome_local,
                                    saldo: e.saldo,
                                    reserva: e.reserva,
                                    livre: e.livre,
                                    lote: e.lote
                                });
                            }
                        });
                    }

                    data={produtos: arrayRetorno}
                }else{
                    confirmAlert({
                        title: "Erro:",
                        message: "Não foi possível processar as informações Offline.",
                        buttons: [
                            {
                            label: 'Ok',
                            }
                        ]
                    })
                }
            }

            if(data.produtos.length > 1){
                setProds(data.produtos);
                setShowModalProds(true)
            }else if(data.produtos.length===1){
                clickedRowProd(data.produtos[0])
            }else{
                confirmAlert({
                    title: "Erro:",
                    message: "Nada encontrado.",
                    buttons: [
                        {
                            label: 'Ok',
                        }
                    ]
                })
            }
        }

        /* ESSE CÓDIGO UTILIZAR A CONSULTA BASEADO A TABELA DE ESTOQUE
        let fil={
            ...filters,
            descricao: filters.descricao==="" ?undefined :filters.descricao.split(' ')
        }

        if(cod_prooduto!==undefined){
            fil.cod_produto=cod_prooduto;
        }

        let existeFiltro=false;

        for(const [key, value] of Object.entries(fil)){
            if(value!==undefined && (value.length>0 || value===true)){
                existeFiltro=true;
                break;
            }
        }

        if(existeFiltro){

            let data;
            try{
                const response = await api.post("/estoque/produtos",
                JSON.stringify(fil),
                {
                    headers: { 'Content-Type': 'application/json' }
                }
                );
                data=response.data;
                setOnline(true);
            }catch(err){
                if(!err.response || err.response.status===0){
                    setOnline(false);
                }else{
                    confirmAlert({
                        title: "Erro:",
                        message: err.response.data ?err.response.data.error :err.message,
                        buttons: [
                            {
                                label: 'Ok',
                            }
                            ]
                    })
                }
            }

            if(data===undefined){
                let cacheInfos=await loadOfflineData();
                if(cacheInfos!==false){
                        let query=Enumerable.from(cacheInfos.estoque);
                        if(fil.cod_produto!==undefined && fil.cod_produto.length>0){
                            query=query.where(i=> i.cod_produto==fil.cod_produto);
                        }

                        if(fil.descricao!==undefined && fil.descricao.length>0){
                            fil.descricao.forEach(desc =>{
                                if(desc.length>0){
                                    query=query.where(i => i.descricao.toUpperCase().indexOf(desc.toUpperCase())>=0);
                                }
                            });
                        }
                        query=query.select(i => [i.cod_produto,i.descricao,i.descricao_complementar]).distinct();

                        let prods=query.toArray();

                        data={produtos: prods}
                }
            }

            if(data.produtos.length > 1){
                setProds(data.produtos);
                setShowModalProds(true)
            }else if(data.produtos.length===1){
                clickedRowProd(data.produtos[0])
            }
        }*/
    };

    function onChanged(e){
        const {value,name} = e.target;

        const re = /^[0-9/\b]+$/;
        if (value === '' || re.test(value)) {
            setFilters({
                ...filters,
                [name]: value
              })
        }
    }

    function onChangedList(e){
        const {value,name} = e.target;
        setFilters({
            ...filters,
            [name]: value
        })
    }

    function voltar(){
        setCurPage(curPage-1);
    }

    function resetInfos(){
        setItensPedido([]);
        setFilters(initialFilters);
        setPedido(initialPedido);
        setCurPage(0);
        setFinalizou(false);
    }

    const BtnActionProd = (props) => {
        const prod=props;
        return (
            <div className="btn-action d-flex" onClick={()=>{clickedRowProd(prod)}} style={{height: "40px", alignItems: "center"}}>
                <div>
                    <Form.Label style={{fontWeight: "bold"}}>{prod.produto.cod_produto+" - "+prod.produto.descricao+" "+prod.produto.descricao_complementar}</Form.Label>
                </div>
            </div>
        );
    };

    var btnNotasBeneficiar = async (dado,criando) => {
        setItemEdicao(dado);
        let data;
        try{
            const response = await api.post("/pedido/beneficiamentos",
            JSON.stringify({cod_cliente: pedido.cliente.codigo}),
            {
                headers: { 'Content-Type': 'application/json' }
            }
            );
            data=response.data;

            setOnline(true);
        }catch(err){
            if(!err.response || err.response.status===0){
                setOnline(false);
            }else{
                confirmAlert({
                    title: "Erro:",
                    message: err.response.data ?err.response.data.error :err.message,
                    buttons: [
                        {
                        label: 'Ok',
                        }
                    ]
                })
            }
        }

        if(data===undefined){
            let cacheInfos=await loadOfflineData();
            if(cacheInfos!==false){
                /* ESSE CÓDIGO É BASEADO NO CÓDIGO IMPLEMENTADO NO BACKEND infos-controlles.js getPreco, IMPORTANTE OS 2 MANTEREM A MESMA LÓGICA, ENTÃO SE ALTERAR UM REVISAR O OUTRO */
                let queryBeneficiamentos=Enumerable.from(cacheInfos.beneficiamentos);

                queryBeneficiamentos=queryBeneficiamentos.where(i=> i.cod_cliente===pedido.cliente.codigo);

                let benefs=queryBeneficiamentos.toArray();

                data={beneficiamentos: benefs}
            }else{
                confirmAlert({
                    title: "Erro:",
                    message: "Não foi possível processar as informações Offline.",
                    buttons: [
                        {
                        label: 'Ok',
                        }
                    ]
                })
            }
        }

        if(data.beneficiamentos.length){
            setBeneficiamentos({beneficiamentos: data.beneficiamentos, criando:criando});
            setShowModalBeneficiamento(true);
        }else{
            if(criando){
                setTimeout(function() {
                    btnQuantidade.current.click();
                }, 0);
            }
        }

        /*if(data.beneficiamentos.length > 1){
            setBeneficiamentos({beneficiamentos: data.beneficiamentos, criando:criando});
            setShowModalBeneficiamento(true);
        }else if(data.beneficiamentos.length===1){
            clickedRowBeneficiamento(data.beneficiamentos[0],criando)
        }else{
            confirmAlert({
                title: "Erro:",
                message: "Não existem beneficiamentos disponíveis.",
                buttons: [
                    {
                    label: 'Ok',
                    }
                ]
            })
        }*/
    }

    var btnRemoveBeneficiamento = async (dado) => {
        let itensPedidoCopy=[...refItensPedido.current];
        let itemPed=itensPedidoCopy.find(function(item, i){
            return item.idx === dado.idx;
        })

        delete itemPed["beneficiamento"];

        setItensPedido(itensPedidoCopy)
    }

    var clickedRowBeneficiamento = async (benef,criando) => {
        let itensPedidoCopy=[...refItensPedido.current];
        let itemPed=itensPedidoCopy.find(function(item, i){
            return item.idx === itemEdicao.idx;
        })

        itemPed.beneficiamento=benef;

        setItensPedido(itensPedidoCopy)

        if(showModalBeneficiamento===true){
            setShowModalBeneficiamento(false);
        }

        if(criando){
            setTimeout(function() {
                btnQuantidade.current.click();
            }, 0);
        }else
            setItemEdicao({});
    }

    const BtnActionBeneficiamento = (props) => {
        const benef=props;
        return (
            <Row className="btn-action d-flex" onClick={()=>{clickedRowBeneficiamento(benef,beneficiamentos.criando)}} style={{height: "50px", alignItems: "center"}}>
                <Col>
                    <Row>
                        <Col>
                            <Form.Label style={{fontWeight: "bold"}}>{benef.cod_produto+" - "+benef.descricao}</Form.Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label style={{fontWeight: "bold"}}>{"NF: "+benef.notafiscal}</Form.Label>
                        </Col>
                        <Col>
                            <Form.Label style={{fontWeight: "bold"}}>{"Saldo: "+benef.saldo}</Form.Label>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    };

    var clickedRowProd = async (prod) => {
        setFilters(initialFilters);
        let itensPedidoAux=[...itensPedido];
        let dado={idx:itensPedidoAux.length, ...prod, quantidade:'', percentualDesconto:'', percentualOverprice:'', precoManual:''};
        itensPedidoAux.push(dado);
        setItensPedido(itensPedidoAux);

        if(showModalProds===true){
            setShowModalProds(false);
        }

        if(pedido.beneficiar){
            btnNotasBeneficiar(dado,true);
            return;
        }

        setTimeout(function() {
            btnQuantidade.current.click();
        }, 0);
    }

    const handleCloseModalProds = () => {
        setShowModalProds(false);
    }

    const handleCloseModalCliente = () => {
        setShowModalCliente(false);
    }

    const handleCloseModalBeneficiamento = () => {
        setItemEdicao({})
        setShowModalBeneficiamento(false);
    }

    const handleCloseModalFinalizacao = () => {
        setShowModalFinalizacao(false);
    }

    const handleCloseModalObservacao = () => {
        setItemEdicao({});
        setValorEdicao('');
        setShowModalObservacao(false);
    }

    async function loadTextosPedido(){
        if(textosPedido===false){
            let data;
            try{
                const response = await api.get("/pedido/textospedido",
                {
                    headers: { 'Content-Type': 'application/json' }
                }
                );
                data=response.data;
                setTextosPedido(response.data);
                setOnline(true);
                return response.data;
            }catch(err){
                if(!err.response || err.response.status===0){
                    setOnline(false);
                }else{
                    confirmAlert({
                        title: "Erro:",
                        message: err.response.data ?err.response.data.error :err.message,
                        buttons: [
                            {
                                label: 'Ok',
                            }
                            ]
                    })
                }
            }
            if(data===undefined){
                let cacheInfos=await loadOfflineData();
                if(cacheInfos!==false){
                    data=cacheInfos.textosPedido;
                }
            }
            setTextosPedido(data);
            return data;
        }else
            return textosPedido;
    }

    async function imprimePedido(){
        let textoPed=await loadTextosPedido();

        //CONSEGUIR VISUALIZAR EM TELA PARA RECARREGAR APÓS CADA ALTERAÇÃO, VERSÃO DESENVOLVIMENTO
        // const root=ReactDOM.createRoot(document.getElementById('root'));
        // root.render(<PedidoViewer itensPedido={itensPedido} textosPedido={textoPed} dadosPedido={pedido}/>)

        //GERAR O PDF SEPARADO DA APLICAÇÃO, VERSÃO DE PRODUÇÃO
        const file = await pdf(<Pedido itensPedido={itensPedido} textosPedido={textoPed} dadosPedido={pedido}/>).toBlob();

        var a = document.createElement("a");
        document.body.appendChild(a);

        const fileURL = URL.createObjectURL(file);
        a.href = fileURL;
        a.download = moment().format('YYYYMMD')+"_"+pedido.cliente.razao_social;
        a.click();
        a.remove()

        handleCloseModalFinalizacao();

        setFinalizou(true);
    }

    const handleConfirmarModalFinalizacao = () => {
        var today = new Date();
        let msgErro="";
        if(pedido.datafaturamento===undefined || !moment(pedido.datafaturamento).isValid() || moment(pedido.datafaturamento).diff(moment(today.getFullYear()+"-"+String(today.getMonth() + 1).padStart(2, '0')+"-"+String(today.getDate()).padStart(2, '0')), "days")<0)
            msgErro="Favor informar a data válida de faturamento";
        else if(pedido.transporte.tipo==="0")
            msgErro="Favor informar o transporte";
        else if(pedido.transporte.tipo==="2" && (pedido.transporte.transportadora===undefined || pedido.transporte.transportadora===""))
            msgErro="Favor informar a transportadora";
        else if(pedido.faturamento==="0")
            msgErro="Favor selecionar o faturamento";
        else if(pedido.condicaopagamento===undefined || pedido.condicaopagamento==="")
            msgErro="Favor informar a condição de pagamento";
        else if(pedido.tubete.id===0)
            msgErro="Favor informar o tubete";

        if(msgErro.length>0){
            confirmAlert({
                title: "Aviso:",
                message: msgErro,
                buttons: [
                    {
                        label: 'Ok',
                    }
                ]
            })
            return;
        }
        setShowModalFinalizacao(false);

        imprimePedido();
    }

    const handleConfirmarModalObservacao = () => {
        let itensPedidoCopy=[...refItensPedido.current];
        let itemPed=itensPedidoCopy.find(function(item, i){
            return item.idx === itemEdicao.idx;
        })

        itemPed.observacao=valorEdicao;

        setItensPedido(itensPedidoCopy)

        setValorEdicao('')
        setItemEdicao({})
        
        setShowModalObservacao(false);
    }

    const DadosProdutos = (dado) => {

        var removeItemClicked = (dado) => {
            let itensPedidoCopy=[];
            let pos=0;
            itensPedido.forEach(it =>{
                if(it.idx!==dado.idx){
                    it.idx=pos;
                    pos=pos+1;
                    itensPedidoCopy.push(it);
                }
            });
            setItensPedido(itensPedidoCopy)
        }

        function getTotalEstoque(dado){
            let livre=0.0;
            dado.produto.estoques.forEach(est =>{
                livre=livre+est.livre;
            });
            return livre;
        }
        
        function calculaValor(dado, considerarQuantidade) {
            let valor = 0.0;
            if (parseFloat(dado.precoManual) > 0) {
                valor = parseFloat(dado.precoManual.replaceAll('.', '').replaceAll(',', '.'));
            } else if (parseFloat(dado.percentualDesconto) > 0) {
                valor = dado.precos[0].preco - (dado.precos[0].preco * (parseFloat(dado.percentualDesconto.replaceAll('.', '').replaceAll(',', '.')) / 100.0));
            } else if (parseFloat(dado.percentualOverprice) > 0) {
                valor = dado.precos[0].preco + (dado.precos[0].preco * (parseFloat(dado.percentualOverprice.replaceAll('.', '').replaceAll(',', '.')) / 100.0));
            } else {
                valor = dado.precos[0].preco;
            }
            if (considerarQuantidade === true) {
                if (parseFloat(dado.quantidade.replaceAll('.', '').replaceAll(',', '.')) > 0) {
                    return (valor * parseFloat(dado.quantidade.replaceAll('.', '').replaceAll(',', '.'))).toFixed(2);
                } else {
                    return 0.0.toFixed(2);
                }
            }
            return valor.toFixed(2);
        }
        

        function getItemModalCalculatorDesconto(){
            return {
                preco:dado.precos[0].preco,
                valorAtual:dado.percentualDesconto,
                desconto_maximo: dado.precos[0].desconto_maximo,
                ...dado,
            };
        }

        const updateDescontoProduto = (dadoCalculator,valor) => {
            let itensPedidoCopy=[...refItensPedido.current];
            let itemPed=itensPedidoCopy.find(function(item, i){
                itensPedidoCopy[item.idx] = {
                    ...item,
                    percentualDesconto: valor
                };
                return item.idx === dadoCalculator.idx;
            })
    
            itemPed.percentualDesconto=valor;
    
            setItensPedido(itensPedidoCopy)
        }

        function updateQuantidadeProduto(dadoCalculator,valor){
            let itensPedidoCopy=[...refItensPedido.current];
            let itemPed=itensPedidoCopy.find(function(item, i){
                return item.idx === dadoCalculator.idx;
            })
    
            itemPed.quantidade=valor;
    
            setItensPedido(itensPedidoCopy)
        }
    
        function getItemModalCalculatorQuantidade(){
            return {
                valorAtual:dado.quantidade,
                preco:calculaValor(dado),
                ...dado
            };
        }

        function getItemModalCalculatorOverprice(){
            return {
                preco:dado.precos[0].preco,
                valorAtual:dado.percentualOverprice,
                ...dado,
            };
        }

        const updateOverpriceProduto = (dadoCalculator,valor) => {
            let itensPedidoCopy=[...refItensPedido.current];
            let itemPed=itensPedidoCopy.find(function(item, i){
                return item.idx === dadoCalculator.idx;
            })
    
            itemPed.percentualOverprice=valor;
    
            setItensPedido(itensPedidoCopy)
        }

        function getItemModalCalculatorPreco(){
            return {
                preco:dado.precos[0].preco,
                valorAtual:dado.precoManual,
                desconto_maximo: dado.precos[0].desconto_maximo,
                ...dado,
            };
        }

        const updatePrecoProduto = (dadoCalculator,valor) => {
            let itensPedidoCopy=[...refItensPedido.current];
            let itemPed=itensPedidoCopy.find(function(item, i){
                return item.idx === dadoCalculator.idx;
            })
    
            itemPed.precoManual=valor;
    
            setItensPedido(itensPedidoCopy)
        }
        
        return (
            <div className="btn-action" style={itemSelected===dado.idx?{backgroundColor: "lightGray"}:{}} onClick={()=>{setItemSelected(dado.idx)}}>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <Form.Label style={{fontWeight: "bold"}}>{dado.produto.cod_produto+" - "+dado.produto.descricao+" "+dado.produto.descricao_complementar}</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label style={{fontSize:"11px",paddingRight: "5px",}}>Quantidade:</Form.Label>
                                <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{dado.quantidade.toLocaleString(undefined, {maximumFractionDigits:decimals})}</Form.Label>
                                <BtnModalCalculatorPedido ref={btnQuantidade} callback={updateQuantidadeProduto} getItem={getItemModalCalculatorQuantidade} tipo="Quantidade"/>
                                {
                                //<Button variant="link" onClick={()=>{btnCalculaQuantidade(dado)}} style={{padding:"0px", color:"var(--cinzaPalagi)"}}><FontAwesomeIcon icon={faCalculator}/></Button>
                                }
                            </Col>

                            <Col>
                                <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Preço: R$</Form.Label>
                                <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{calculaValor(dado).toLocaleString(undefined, {minimumFractionDigits:decimals,maximumFractionDigits:decimals})}</Form.Label>
                                {
                                    (dado.percentualDesconto===undefined || dado.percentualDesconto==="" || parseFloat(dado.percentualDesconto)===0) && (dado.percentualOverprice===undefined || dado.percentualOverprice==="" || parseFloat(dado.percentualOverprice)===0)
                                    ?<BtnModalCalculatorPedido callback={updatePrecoProduto} getItem={getItemModalCalculatorPreco} tipo="Manual"/>
                                    :""
                                }
                                    {
                                        /*dado.percentualDesconto!==undefined && dado.percentualDesconto!=="" && parseFloat(dado.percentualDesconto)>0
                                        ?<>
                                            <Form.Label >(
                                            <Form.Label style={{fontWeight: "bold"}}>{ceilValue(dado.precos[0].preco-(dado.precos[0].preco*(parseFloat(dado.percentualDesconto)/100.0))).toLocaleString(undefined, {maximumFractionDigits:decimals})}</Form.Label>
                                            )</Form.Label>
                                        </>
                                        :""*/
                                    }
                            </Col>

                            <Col>
                                <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Desc. Máx.:</Form.Label>
                                
                                <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{dado.precos[0].desconto_maximo.toLocaleString(undefined, {maximumFractionDigits:decimals})}
                                <Form.Label style={{fontSize:"11px"}}>%</Form.Label>
                                    {
                                        dado.percentualDesconto!==undefined && dado.percentualDesconto!=="" && parseFloat(dado.percentualDesconto)>0
                                        ?<>
                                            <Form.Label style={{paddingLeft: "5px"}}>(</Form.Label>
                                            <Form.Label style={{fontWeight: "bold"}}>{dado.percentualDesconto.toLocaleString(undefined, {maximumFractionDigits:decimals})}</Form.Label>
                                            <Form.Label style={{fontSize:"11px"}}>%</Form.Label>
                                            <Form.Label >)</Form.Label>
                                        </>
                                        :""
                                    }
                                </Form.Label>
                                {
                                    dado.precos[0].desconto_maximo>0.01 && dado.precoManual==="" && (dado.percentualOverprice===undefined || dado.percentualOverprice==="" || parseFloat(dado.percentualOverprice)===0)
                                    ?<BtnModalCalculatorPedido callback={updateDescontoProduto} getItem={getItemModalCalculatorDesconto} tipo="Desconto"/>
                                    //<Button variant="link" onClick={()=>{btnCalculaPrecoDesconto(dado)}} style={{padding:"0px", color:"var(--cinzaPalagi)"}}><FontAwesomeIcon icon={faCalculator}/></Button>
                                    :""
                                }
                            </Col>

                            <Col>
                                <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Overprice:</Form.Label>
                                {
                                    dado.percentualOverprice!==undefined && dado.percentualOverprice!=="" && parseFloat(dado.percentualOverprice)>0
                                    ?<>
                                        <Form.Label style={{fontWeight: "bold"}}>{dado.percentualOverprice.toLocaleString(undefined, {maximumFractionDigits:decimals})}</Form.Label>
                                        <Form.Label style={{fontSize:"11px"}}>%</Form.Label>
                                    </>
                                    :""
                                }
                                {
                                    dado.precoManual==="" && (dado.percentualDesconto===undefined || dado.percentualDesconto==="" || parseFloat(dado.percentualDesconto)===0)
                                    ?<BtnModalCalculatorPedido callback={updateOverpriceProduto} getItem={getItemModalCalculatorOverprice} tipo="Overprice"/>
                                    :""
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>UN:</Form.Label>
                                <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{dado.precos[0].unidade}</Form.Label>
                            </Col>

                            <Col>
                                <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Livre:</Form.Label>
                                <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{getTotalEstoque(dado).toLocaleString()}</Form.Label>
                            </Col>
                            <Col>
                                <Form.Label style={{fontSize:"11px",paddingRight: "5px"}}>Total: R$</Form.Label>
                                <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{calculaValor(dado,true).toLocaleString(undefined, {minimumFractionDigits:decimals,maximumFractionDigits:decimals})}</Form.Label>
                            </Col>
                        </Row>
                        {
                            pedido.beneficiar?
                            <Row>
                                <Col>
                                    <Form.Label style={{fontSize:"11px",paddingRight: "5px",}}>Beneficiamento:</Form.Label>
                                    {
                                        dado.beneficiamento===undefined
                                        ?
                                        <Button variant="link" onClick={()=>{btnNotasBeneficiar(dado,false)}} style={{padding:"0px", color:"var(--cinzaPalagi)"}}><FontAwesomeIcon icon={faMagnifyingGlass}/></Button>
                                        :
                                        <>
                                            <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{"NF: "+dado.beneficiamento.notafiscal+" / "+dado.beneficiamento.cod_produto+" - "+dado.beneficiamento.descricao}</Form.Label>
                                            <Button variant="link" onClick={()=>{btnRemoveBeneficiamento(dado)}} style={{padding:"0px", color:"var(--cinzaPalagi)"}}><FontAwesomeIcon icon={faTrash}/></Button>
                                        </>
                                    }
                                </Col>
                            </Row>
                            :""
                        }
                        <Row>
                            <Col>
                                <Form.Label style={{fontSize:"11px",paddingRight: "5px",}}>Observação:</Form.Label>
                                <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{dado.observacao}</Form.Label>
                                <Button variant="link" onClick={()=>{setValorEdicao(dado.observacao); setItemEdicao(dado); setShowModalObservacao(true);}} style={{padding:"0px", color:"var(--cinzaPalagi)"}}><FontAwesomeIcon icon={faPencil}/></Button>
                            </Col>
                        </Row>
                    </Col>
                    <Button onClick={()=>{removeItemClicked(dado)}} style={{width:"10px", heigth:"100%", marginRight: "11px", justifyContent: "center", display: "flex", alignItems: "center"}}><FontAwesomeIcon icon={faTrash} /></Button>
                </Row>
            </div>
        );
    };

    function finalizarPedido(){
        let msgErro="";
        if(itensPedido.length===0){
            msgErro="Favor adicionar ao menos 1 item no pedido";
        }

        if(msgErro===""){
            itensPedido.forEach(it =>{
                if(it.quantidade==="" || it.quantidade==="0"){
                    msgErro="Favor identificar a quantidade no item "+it.produto.cod_produto+" "+it.produto.descricao+" "+it.produto.descricao_complementar;
                    return;   
                }
            });
        }
        
        if(msgErro==="" && pedido.beneficiar){
            itensPedido.forEach(it =>{
                if(it.beneficiamento===undefined && it.observacao===""){
                    msgErro="Favor identificar o beneficiamento ou a observação no item "+it.produto.cod_produto+" "+it.produto.descricao+" "+it.produto.descricao_complementar;
                    return;
                }
            });
        }

        if(msgErro.length>0){
            confirmAlert({
                title: "Aviso:",
                message: msgErro,
                buttons: [
                    {
                        label: 'Ok',
                    }
                ]
            })
            return;
        }

        setShowModalFinalizacao(true)
    }

    return (
    <>
    <SideBar/>
    {
        showModalProds?
        <React.Fragment>
        <Modal as={Modal.Dialog} show scrollable={true}>
            <Modal.Header>
            <Modal.Title className="h6">Seleção de produtos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    prods.map(d => <BtnActionProd key={`${d.produto.id}`} {...d}/>)
                }
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={handleCloseModalProds}>Sair</Button>
            </Modal.Footer>
        </Modal>
        </React.Fragment>
        :""
    }
    {
        showModalCliente?
        <React.Fragment>
        <Modal as={Modal.Dialog} show scrollable={true}>
            <Modal.Header>
            <Modal.Title className="h6">Observações cliente</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Label style={{fontWeight: "bold",whiteSpace: "pre-wrap"}}>{pedido.cliente.observacao}</Form.Label>
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={handleCloseModalCliente}>Sair</Button>
            </Modal.Footer>
        </Modal>
        </React.Fragment>
        :""
    }
    {
        showModalBeneficiamento?
        <React.Fragment>
        <Modal as={Modal.Dialog} show scrollable={true}>
            <Modal.Header>
            <Modal.Title className="h6">Beneficiamento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    beneficiamentos.beneficiamentos.map(d => <BtnActionBeneficiamento key={`${d.id}`} {...d}/>)
                }
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={handleCloseModalBeneficiamento}>Sair</Button>
            </Modal.Footer>
        </Modal>
        </React.Fragment>
        :""
    }
    {
        showModalFinalizacao?
        <React.Fragment>
        <Modal as={Modal.Dialog} show scrollable={true}>
            <Modal.Header>
            <Modal.Title className="h6">Finalização</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="mb-2">
                    <Col>
                        <FloatingLabel label="Data Faturamento">
                            <Form.Control type="date" autoComplete="off" placeholder="" onChange={(e) => setPedido({...pedido,datafaturamento:e.target.value})} value={pedido.datafaturamento}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <FloatingLabel label="Transporte">
                            <Form.Select name="transporte" onChange={(e) => setPedido({...pedido,transporte:{...pedido.transporte,tipo:e.target.value}})} value={pedido.transporte.tipo}>
                            <option value="0">Não informado</option>
                                <option value="1">Próprio</option>
                                <option value="2">Terceiro</option>
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                </Row>
                {
                    pedido.transporte.tipo==="2"
                    ?
                    <Row className="mb-2">
                        <Col>
                            <FloatingLabel label="Transportadora">
                                <Form.Control type="text" autoComplete="off" placeholder="" onChange={(e) => setPedido({...pedido,transporte:{...pedido.transporte,transportadora:e.target.value}})} value={pedido.transporte.transportadora}/>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    :""
                }
                <Row className="mb-2">
                    <Col>
                        <FloatingLabel label="Faturamento">
                            <Form.Select value={pedido.faturamento} onChange={(e)=>{setPedido({...pedido,faturamento:e.target.value})}}>
                                <option value="0">Não informado</option>
                                <option value="1">Junto</option>
                                <option value="2">Separado</option>
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <FloatingLabel label="OC Cliente">
                            <Form.Control type="text" autoComplete="off" placeholder="" onChange={(e) => setPedido({...pedido,occliente:e.target.value})} value={pedido.occliente}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <FloatingLabel label="Cond. Pagamento">
                            <Form.Control type="text" autoComplete="off" placeholder="" onChange={(e) => setPedido({...pedido,condicaopagamento:e.target.value})} value={pedido.condicaopagamento}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <FloatingLabel label="Cod. Espec. Saída">
                            <Form.Control type="text" autoComplete="off" placeholder="" onChange={(e) => setPedido({...pedido,codespecsaida:e.target.value})} value={pedido.codespecsaida}/>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <FloatingLabel label="Tubete">
                            <Form.Select value={pedido.tubete.id} onChange={(e)=>{setPedido({...pedido,tubete:tubetes[e.target.value]})}}>
                                {
                                    tubetes.map(t =>{
                                        return (
                                            <option key={t.id} value={t.id}>{t.codigo+" "+t.descricao}</option>
                                        )
                                    })
                                }
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <ToggleButton
                            id="btnImpDesconto"
                            type="checkbox"
                            variant=""
                            name="impdesconto"
                            checked={pedido.imprimirdesconto}
                            onChange={(e)=>{setPedido({...pedido,imprimirdesconto:e.target.checked})}}
                        >
                            Imprimir Desconto
                        </ToggleButton>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <FloatingLabel label="Observações">
                            <Form.Control as="textarea" style={{ height: '100px' }} autoComplete="off" placeholder="" onChange={(e) => setPedido({...pedido,observacao:e.target.value})} value={pedido.observacao}/>
                        </FloatingLabel>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer style={{justifyContent:'space-between'}}>
                <Button onClick={handleCloseModalFinalizacao}>Sair</Button>
                <Button onClick={handleConfirmarModalFinalizacao}>Confirmar</Button>
            </Modal.Footer>
        </Modal>
        </React.Fragment>
        :""
    }
    {
        showModalObservacao?
        <React.Fragment>
        <Modal as={Modal.Dialog} show scrollable={true}>
            <Modal.Header>
            <Modal.Title className="h6">Observação</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FloatingLabel label="Observações">
                    <Form.Control autoFocus as="textarea" style={{ height: '150px' }} autoComplete="off" placeholder="" onChange={(e) => setValorEdicao(e.target.value)} value={valorEdicao}/>
                </FloatingLabel>
            </Modal.Body>
            <Modal.Footer style={{justifyContent:'space-between'}}>
                <Button onClick={handleCloseModalObservacao}>Sair</Button>
                <Button onClick={handleConfirmarModalObservacao}>Confirmar</Button>
            </Modal.Footer>
        </Modal>
        </React.Fragment>
        :""
    }
    <main className="content">
        {
            online ?null :<div className="alert alert-warning" role="alert">Você está offline</div>
        }
        {
            sincronizando ?<div className="alert alert-warning" role="alert">Sincronizando</div> :null
        }
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block">
                <h4>Pedido{pedido.filial!==undefined && pedido.filial!=="" ?(pedido.filial==="0"?" Matriz":" Filial"):""}</h4>
            </div>
            {
                curPage>0
                ?
                <Button onClick={voltar}>
                    Voltar
                </Button>
                :""
            }
        </div>
        {
            curPage===0
            ?
            <>
                {
                    REACT_APP_APP_ENV==="palagi"
                    ?
                    <Row className="mb-2">
                        <Col className="d-flex justify-content-center">
                            <ButtonGroup>
                                <ToggleButton
                                        key={0}
                                        id={`filial-0`}
                                        type="radio"
                                        variant=""
                                        name="filial"
                                        value="0"
                                        checked={pedido.filial === "0"}
                                        onChange={(e) => setPedido({...pedido,filial:"0"})}
                                    >
                                        Matriz
                                    </ToggleButton>
                                    <ToggleButton
                                        key={1}
                                        id={`filial-1`}
                                        type="radio"
                                        variant=""
                                        name="filial"
                                        value="1"
                                        checked={pedido.filial === "1"}
                                        onChange={(e) => setPedido({...pedido,filial:"1"})}
                                    >
                                        Filial
                                    </ToggleButton>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    :""
                }
                <Row className="mb-2">
                    <Col >
                        {pedido.cliente.codigo===undefined
                        ?
                        <FiltroCliente callback={clienteSelected} />
                        :
                        <>
                        <Row className="mb-2">
                            <Col >
                                    <small>{pedido.cliente.cpfcnpj+" - "}</small>
                                    <Form.Label style={{fontWeight: "bold"}}>{pedido.cliente.codigo+" - "+pedido.cliente.razao_social}</Form.Label>
                            </Col>
                            <Button style={{width:"10px",  justifyContent: "center", display: "flex", alignItems: "center", marginRight: "10px"}} onClick={()=>{setPedido({...pedido,cliente:{},lista:{}}); setItensPedido([]);}} ><FontAwesomeIcon icon={faTrash} /></Button>
                        </Row>
                        { pedido.cliente.observacao!=""
                            ?<Row className="mb-2">
                                <Col>
                                    <Form.Label className="d-flex form-inline col-md-12 col-12">Observações do cliente: 
                                    <Button style={{width:"10px",  justifyContent: "center", display: "flex", alignItems: "center", marginLeft: "10px"}} onClick={()=>{setShowModalCliente(true)}} ><FontAwesomeIcon icon={faMagnifyingGlass} /></Button>
                                    </Form.Label>
                                </Col>
                            </Row>
                            :""
                        }
                        </>
                        }
                    </Col>
                </Row>
                <Row >
                    <Col className="mb-2">
                        {pedido.lista.codigo===undefined
                        ?
                        <FiltroListaPreco disabled={pedido.cliente.inscricaoestadual===""?true:false} ref={filtroListaPreco} callback={listaPrecoSelected} />
                        :
                        <>
                        <Row className="mb-2">
                            <Col >
                                <Form.Label style={{fontWeight: "bold"}}>{pedido.lista.codigo+" - "+pedido.lista.nome}</Form.Label>
                            </Col>
                            {
                                pedido.cliente.inscricaoestadual!==""
                                ?<Button style={{width:"10px",  justifyContent: "center", display: "flex", alignItems: "center", marginRight: "10px"}} onClick={()=>{setPedido({...pedido,lista:{}}); setItensPedido([]);}} ><FontAwesomeIcon icon={faTrash} /></Button>
                                :""
                            }
                        </Row>
                        </>
                        }
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                    <Col md={3} className="mb-2 d-flex justify-content-center">
                        <ButtonGroup>
                                <ToggleButton
                                    key={3}
                                    id={`beneficiar-0`}
                                    type="radio"
                                    variant=""
                                    name="beneficiar"
                                    value="0"
                                    checked={pedido.beneficiar===false}
                                    onChange={(e) => setPedido({...pedido,beneficiar:false})}
                                >
                                    Venda
                                </ToggleButton>
                                <ToggleButton
                                    key={2}
                                    id={`beneficiar-1`}
                                    type="radio"
                                    variant=""
                                    name="beneficiar"
                                    value="1"
                                    checked={pedido.beneficiar}
                                    onChange={(e) => setPedido({...pedido,beneficiar:true})}
                                >
                                    Beneficiamento
                                </ToggleButton>
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row className="mb-2" style={{marginTop: "10px", justifyContent:'space-between'}} >
                    <Col className="d-flex justify-content-begin">
                    {finalizou
                        ?<Button onClick={() => {
                            resetInfos();
                        }}>
                            Sair
                        </Button>
                        :<Button onClick={() => {
                            Swal.fire({
                                title: 'Cancelar Pedido?',
                                text: "Você perderá as informações preenchidas",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Sim',
                                cancelButtonText: 'Não'
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    resetInfos();
                                }
                            })
                        }}>
                            Cancelar Pedido
                        </Button>
                    }
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <Button onClick={() => {
                            let msgErro="";
                            if(REACT_APP_APP_ENV==="palagi" && pedido.filial===undefined)
                                msgErro="Favor selecionar Matriz ou Filial";
                            else if(pedido.cliente.razao_social===undefined)
                                msgErro="Favor informar o cliente";
                            else if(pedido.lista.codigo===undefined)
                                msgErro="Favor informar o Código da Lista";
                            else if(pedido.beneficiar===undefined)
                                msgErro="Favor selecionar se é Venda ou Beneficiamento";

                            if(msgErro.length>0){
                                confirmAlert({
                                    title: "Aviso:",
                                    message: msgErro,
                                    buttons: [
                                        {
                                            label: 'Ok',
                                        }
                                        ]
                                })
                                return;
                            }
                            setCurPage(1);
                            }}>
                                Itens
                            </Button>
                    </Col>
                </Row>
            </>
            :
            <>
                <CabecalhoCliente key={pedido.cliente.codigo}/>
                <Card border="light" className="mb-2">
                    <Card.Body className="">
                        <Row>
                            <Col >
                                <FloatingLabel label="Código produto">
                                    <Form.Control type="text" autoComplete="off" inputMode="numeric" placeholder="" name="cod_produto" onChange={onChanged} value={filters.cod_produto} onKeyUp={(e)=>{if(e.key==="Enter") buscaProdutos()}}/>
                                </FloatingLabel>
                            </Col>
                            <Col >
                                <FloatingLabel  label="Descrição Produto">
                                    <Form.Control type="text" autoComplete="off" placeholder="" name="descricao" onChange={onChangedList} value={filters.descricao} onKeyUp={(e)=>{if(e.key==="Enter") buscaProdutos()}}/>
                                </FloatingLabel>
                            </Col>
                            <Button style={{width:"10px",  justifyContent: "center", display: "flex", alignItems: "center"}} onClick={(e)=>{buscaProdutos()}}><FontAwesomeIcon icon={faMagnifyingGlass} /></Button>
                        </Row>
                    </Card.Body>
                </Card>
                <Card border="light" className="mb-2">
                    <Card.Body className="">
                        {itensPedido.map(d => <DadosProdutos key={`${d.idx}`} {...d}/>)}
                    </Card.Body>
                </Card>
                <Row className="mb-2">
                    <Col className="d-flex justify-content-begin">
                    {finalizou
                        ?<Button onClick={() => {
                            resetInfos();
                        }}>
                            Sair
                        </Button>
                        :<Button onClick={() => {
                            Swal.fire({
                                title: 'Cancelar Pedido?',
                                text: "Você perderá as informações preenchidas",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Sim',
                                cancelButtonText: 'Não'
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    resetInfos();
                                }
                            })
                        }}>
                            Cancelar Pedido
                        </Button>
                        }
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <Button onClick={finalizarPedido}>
                            Finalizar Pedido
                        </Button>
                    </Col>
                </Row>
            </>
        }
    </main>
    </>
    )
}
