import React, { useState, useRef} from 'react';
import {Form,Card} from 'react-bootstrap';
import api from '../api.js';
import { ProgressBar,Button,Spinner } from "react-bootstrap";
import useAuth from '../Context/hooks/UseAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import {confirmAlert} from 'react-confirm-alert'


const { REACT_APP_APP_ENV } = process.env;

export function UploadProcess({...props}){

    const [uploadPercentage, setUploadPercentage] = useState(0);
    const { auth } = useAuth();
    const hiddenFileInput = useRef(null);
    const [processando, setProcessando] = useState(false);
    const [realizouUpload, setRealizouUpload] = useState(false);
    
    const handleClick = () => {
        hiddenFileInput.current.click();
    }

    function getDataUltimoUpload(tipo){
        if(realizouUpload===false){
            let data
            props.ultimosUploads.forEach(u =>{
                if(u.tipo.toString()===tipo.toString()){
                    data=u.ultimoenvio;
                    return;
                }
            });
            if(data!==undefined){
                let dt = new Date(data)
                return "Último envio: "+dt.toLocaleString();
            }
            return "Ainda não foi realizado envios";
        }else{
            return "Último envio: "+new Date().toLocaleString();
        }
    }

    const handleChange = async (e) => {
        let file=e.target.files[0];
        if(file.name.toString().toLowerCase()!==REACT_APP_APP_ENV+"_"+props.destino+".xlsx"){
            confirmAlert({
                title: "Erro:",
                message: "Nome do arquivo inválido, o mesmo de ser: "+REACT_APP_APP_ENV+"_"+props.destino+".xlsx",
                buttons: [
                    {
                        label: 'Ok',
                    }
                ]
            })
            return;
        }
        let data = new FormData();
        data.append("file", file);

        const options = {
            onUploadProgress: progressEvent => {
                const { loaded, total } = progressEvent;

                setProcessando(true)

                let percent = Math.floor((loaded * 100) / total);

                if (percent < 100) {
                    setUploadPercentage(percent);
                }
            },
            headers: { 'Content-Type': 'multipart/form-data' }
        };

        api.post("/files/"+props.destino,data,options).then(()=>{
            e.target.value = null;
            //SERVE PARA MOSTRAR 100% NA BARRA DE PROGRESSO E DEPOIS DE 1 SEGUNDO RESETAR
            setUploadPercentage(100)
            setRealizouUpload(true)
            setTimeout(() => {
                setUploadPercentage(0);
            }, 1000);
            setTimeout(() => {
                setProcessando(false)
            }, 1000);
        }).catch((err)=>{
            e.target.value = null;
            setUploadPercentage(0);
            confirmAlert({
                title: "Erro:",
                message: err.response.data ?err.response.data.error :err.message,
                buttons: [
                    {
                      label: 'Ok',
                    }
                  ]
            })
        })
    };

    return (
        <div style={{paddingBottom: "10px"}}>
        <Card border="light">
            <Card.Body >
                <Form.Group className="row">
                    <div className="col-md-2 col-6">
                        <Form.Label style={{paddingRight: "10px"}}>{props.name}</Form.Label>
                        <Form.Control type="file" ref={hiddenFileInput} onChange={handleChange} accept=".xlsx" hidden/>
                    </div>
                    {
                        !processando
                        ?
                            <>
                            <div className="col-md-1 col-4">
                                <Button onClick={handleClick}><FontAwesomeIcon icon={faUpload}/></Button>
                            </div>
                            <Form.Label className="col-md-4 col-6" style={{paddingRight: "10px"}}>{getDataUltimoUpload(props.tipo)}</Form.Label>
                            </>
                        :
                            <Spinner animation="border" style={{color: "var(--magentaPalagi)"}} />
                    }
                    {
                        uploadPercentage > 0
                        ?
                        <div className="row col-md-5 col-6">
                            <div className="col pt-1">
                                <ProgressBar animated
                                    now={uploadPercentage}
                                    striped={true}
                                    label={`${uploadPercentage}%`}
                                />
                            </div>
                        </div>
                        :""
                    }
                </Form.Group>
            </Card.Body>
        </Card>
    </div>
    )
}