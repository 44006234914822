import React,{useState,useEffect,useRef,forwardRef, useImperativeHandle} from 'react';
import useAuth from '../Context/hooks/UseAuth';
import { Button, Card,  Modal, Form, Row, Col,FloatingLabel, ToggleButton } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import SelecaoValoresFormacaoPreco from './SelecaoValoresFormacaoPreco';
import {confirmAlert} from 'react-confirm-alert'

var decimals = 2;

const BtnModalCalculatorFormacaoPreco = forwardRef((props, ref) => {
    let callback = props.callback;
    let getItem = props.getItem;
    let tipo = props.tipo;

    const [valor, setValor] = useState('');
    const [showModalCalculator,setShowModalCalculator] = useState(false);
    const [dadoCalculator, setDadoCalculator] = useState({});
    const { ceilValue,decimals } = useAuth();
    const [icmsBaseCalculoPreco, setIcmsBaseCalculoPreco] = useState({});


    const refShowModalCalculator = useRef(showModalCalculator);
    const refDadoCalculator = useRef(dadoCalculator);
    const refValor = useRef(valor);
    const refIcmsBaseCalculoPreco = useRef(icmsBaseCalculoPreco);

    const refModalPreco = React.useRef();


    useImperativeHandle(ref, () => {
        return {
            click() {
                btnClicked();
            }
        };
    }, []);

    useEffect(() => {
        refDadoCalculator.current = dadoCalculator;
    }, [dadoCalculator]);

    useEffect(() => {
        refIcmsBaseCalculoPreco.current = icmsBaseCalculoPreco;
    }, [icmsBaseCalculoPreco]);

    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.key === 'Escape') {
              event.preventDefault();
              if(refShowModalCalculator.current===true){
                handleCloseModalCalculator();
              }
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
          document.removeEventListener('keydown', keyDownHandler);
        };
    },[]);

    useEffect(() => {
        refShowModalCalculator.current = showModalCalculator;
      }, [showModalCalculator]);

    useEffect(() => {
        refValor.current = valor;
    }, [valor]);

    function onChangedCalculator(e){
        const {value} = e.target;
        const re = /^[0-9,/\b]+$/;
        if(value === ''){
            setValor('');
        }else if (re.test(value)) {
            if(tipo==="Desconto"){
                if(parseFloat(value.replaceAll('.','').replaceAll(',','.'))<=dadoCalculator.desconto_maximo){
                    setValor(value);
                }
            }else
                setValor(value);
        }
    }

    const handleCloseModalCalculator = () => {
        setValor('')
        setShowModalCalculator(false);
    }

    function getPercentualManual(){
        let valorBase=0.0;
        dadoCalculator.precos.forEach(p => {
            if(p.id===parseInt(icmsBaseCalculoPreco.value)){
                valorBase=p.valor;
                return;
            }
        });
        let valorDig=parseFloat(valor.replaceAll('.','').replaceAll(',','.'));
        return 1-(valorDig/valorBase);
    }

    function getValorCalculado(valorIcms){
        if(tipo==="Quantidade"){
            return valor!=='' ?ceilValue(valorIcms*parseFloat(valor.replaceAll('.','').replaceAll(',','.'))).toLocaleString(undefined, {minimunFractionDigits:decimals,maximumFractionDigits:decimals})
                                    :ceilValue(valorIcms).toLocaleString(undefined, {maximumFractionDigits:decimals});
        }else if(tipo==="Overprice"){
            return valor!==''   ?ceilValue(valorIcms+(valorIcms*(parseFloat(valor.replaceAll('.','').replaceAll(',','.'))/100.0))).toLocaleString(undefined, {minimunFractionDigits:decimals,maximumFractionDigits:decimals})
                                :ceilValue(valorIcms).toLocaleString(undefined, {maximumFractionDigits:decimals})
        }else if(tipo==="Desconto"){
            return valor!=='' ?ceilValue(valorIcms-(valorIcms*(parseFloat(valor.replaceAll('.','').replaceAll(',','.'))/100.0))).toLocaleString(undefined, {minimunFractionDigits:decimals,maximumFractionDigits:decimals})
                                    :ceilValue(valorIcms).toLocaleString(undefined, {maximumFractionDigits:decimals})
        }else if(tipo==="Manual"){
            if(valor!==''){
                let novoValor=valorIcms-(valorIcms*getPercentualManual());
                return ceilValue(novoValor).toLocaleString(undefined, {minimunFractionDigits:decimals,maximumFractionDigits:decimals})
            }else
                return ceilValue(valorIcms).toLocaleString(undefined, {minimunFractionDigits:decimals,maximumFractionDigits:decimals});
        }
    }

    function confirmar(){
        if(tipo==="Manual"){
            let perc=getPercentualManual();
            if(refValor.current==='' || perc===0)
                callback(refDadoCalculator.current,0,{});
            else{
                if(perc*100.0>refDadoCalculator.current.desconto_maximo){
                    confirmAlert({
                        title: "Aviso:",
                        message: "O Preço informado é inferior ao desconto permitido, favor ajustar",
                        buttons: [
                            {
                                label: 'Ok',
                            }
                        ]
                    })
                    return
                }
                callback(refDadoCalculator.current,perc,refIcmsBaseCalculoPreco.current)
            }
        }else{
            let v=refValor.current;
            if(v==="0")
                v='';
            callback(refDadoCalculator.current,v)
        }
        setShowModalCalculator(false);
    }

    function openModal(){
        setShowModalCalculator(true);
    }

    function icmsPrecoSelected (e) {
        setIcmsBaseCalculoPreco(e);
        openModal();
    }

    function btnClicked(){
        let item=getItem();
        setDadoCalculator(item);
        if(tipo==="Manual"){
            if(item.valorAtual.icmsBase!==undefined){
                setIcmsBaseCalculoPreco(item.valorAtual.icmsBase);
                let valorIcms=0.0;
                item.precos.forEach(p => {
                    if(p.id===parseInt(item.valorAtual.icmsBase.value)){
                        valorIcms=p.valor;
                        return;
                    }
                });
                let vlr=valorIcms-(valorIcms*item.valorAtual.percentual);
                setValor(vlr.toLocaleString(undefined, {minimunFractionDigits:decimals,maximumFractionDigits:decimals}));
                openModal();
            }else
                refModalPreco.current.open()
        }else{
            setValor(item.valorAtual);
            openModal();
        }
    }

    const DadosPreco = (props) => {
        let preco=props.preco;
        let dado=props.dado;

        return (
            <Row>
                <Col>
                    <Form.Label style={{paddingRight: "5px"}}>{preco.descricao+": R$ "}</Form.Label>
                    <Form.Label style={{paddingRight: "5px"}}>{preco.valor.toLocaleString(undefined, {minimunFractionDigits:decimals,maximumFractionDigits:decimals})}</Form.Label>
                </Col>
                <Col>
                    <Form.Label style={{paddingRight: "5px"}}>{preco.descricao+": R$ "}</Form.Label>
                    <Form.Label style={{fontWeight: "bold",paddingRight: "5px"}}>{getValorCalculado(preco.valor)}</Form.Label>
                </Col>
            </Row>
        );
    };

    function onKeyUp(e){
        if(e.key==="Enter" && refShowModalCalculator.current===true)
            confirmar();
    }

    return (
        <>
        {
        showModalCalculator?
        <React.Fragment>
        <Modal as={Modal.Dialog} show>
            <Modal.Header>
            <Modal.Title className="h6">Calcular {tipo}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Row>
                <Col>
                    <Form.Label style={{paddingRight: "5px"}}>Preço Atual</Form.Label>
                </Col>
                <Col>
                    <Form.Label style={{paddingRight: "5px"}}>Preço {tipo}</Form.Label>
                </Col>
            </Row>
            {
                dadoCalculator.precos.map(p => <DadosPreco key={dadoCalculator.idx+"_"+p.id} preco={p} dado={dadoCalculator}/>)
            }
            <Row>
                <Col>
                    <FloatingLabel label={tipo}>
                        <Form.Control autoFocus type="text" autoComplete="off" inputMode="numeric" placeholder="" onChange={onChangedCalculator} value={valor} onKeyUp={onKeyUp}/>
                    </FloatingLabel>
                </Col>
            </Row>
            </Modal.Body>
            <Modal.Footer style={{justifyContent:'space+between'}}>
                <Button onClick={handleCloseModalCalculator}>Sair</Button>
                <Button onClick={confirmar}>Confirmar</Button>
            </Modal.Footer>
        </Modal>
        </React.Fragment>
        :""
        }
        <SelecaoValoresFormacaoPreco ref={refModalPreco} precos={dadoCalculator.precos} callback={icmsPrecoSelected}/>
        <Button variant="link" onClick={btnClicked} style={{padding:"0px",paddingLeft: "5px",color:"var(--cinzaPalagi)"}}><FontAwesomeIcon icon={faCalculator}/></Button>
        </>
    )
});

export default BtnModalCalculatorFormacaoPreco;